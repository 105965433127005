import { TableCell, TableRow } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { renderRole } from "./types";

const UserRow: React.FC<{
  user: {
    handleOpenEdinModal: any;
    id?: number;
    firstName?: String;
    lastName?: String;
    email?: String;
    role?: any;
    language?: String;
  };
  invite: any;
}> = ({ user, invite }, props) => {
  const { firstName, lastName, handleOpenEdinModal } = user;
  const email = user.email || invite.external_email;

  const handleEditUser = () => {
    handleOpenEdinModal(invite);
  };

  const renderEmail = () => {
    if (invite.status === "pending") {
      return (
        <span>
          {email}{" "}
          <span style={{ color: "grey" }}>
            <FormattedMessage
              id="user.invitation-pending"
              defaultMessage="(invitation pending)"
            />
          </span>
        </span>
      );
    } else if (invite.status === "rejected") {
      return (
        <span>
          {email}{" "}
          <span style={{ color: "red" }}>
            <FormattedMessage
              id="user.invitation-rejected"
              defaultMessage="(invitation rejected)"
            />
          </span>
        </span>
      );
    }

    return <span>{email}</span>;
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditUser();
      }}
    >
      <TableCell component="th" scope="row">
        {firstName} {lastName}
      </TableCell>
      <TableCell align="left">{renderEmail()}</TableCell>
      <TableCell align="left">{renderRole(invite.role)}</TableCell>
    </TableRow>
  );
};

export default UserRow;
