import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TableFooter, TablePagination } from "@mui/material";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormattedMessage, useIntl } from "react-intl";

import ReleaseModal from "../components/release/modal";
import { getProjectOptions } from "../redux/reducerSlices/project";
import { getReleases } from "../redux/reducerSlices/release";
import ReleaseRow from "../components/release/row";
import { Release } from "../components/release/types";
import TablePaginationActions from "../components/TablePaginationActions";

const ReleasesTable = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { releases, pagination } = useSelector(
    (state: RootStateOrAny) => state.releases
  );

  useEffect(() => {
    dispatch(getReleases());
  }, [dispatch]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getReleases(newPage + 1, pagination.pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getReleases(1, parseInt(event.target.value, 10)));
  };

  if (releases.length <= 0) {
    return (
      <div>
        <FormattedMessage
          id="general.No-releases-found"
          defaultMessage="No releases found"
        />
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 100,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Id" defaultMessage="Id" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Project" defaultMessage="Project" />
            </TableCell>
            <TableCell align="left" colSpan={1}>
              <FormattedMessage id="general.Date" defaultMessage="Date" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Status" defaultMessage="Status" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {releases &&
            releases.map((release) => (
              <ReleaseRow
                key={release.id}
                release={{
                  ...release,
                  handleOpenEdinModal: props.handleOpenEdinModal,
                }}
              />
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={pagination.total}
              rowsPerPage={pagination.pageSize}
              page={pagination.page - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": intl.formatMessage({
                    id: "general.Releases-per-page",
                    defaultMessage: "Releases per page",
                  }),
                },
                native: true,
              }}
              labelRowsPerPage={intl.formatMessage({
                id: "general.Releases-per-page",
                defaultMessage: "Releases per page",
              })}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${intl.formatMessage({
                  id: "pagination.of",
                  defaultMessage: "of",
                })} ${count}`;
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const Releases = () => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [modalRelease, setModalRelease] = useState<Release | null>(null);

  useEffect(() => {
    dispatch(getProjectOptions());
  }, [dispatch]);

  const openNewReleaseModal = () => {
    setModalRelease(null);
    setOpenModal(true);
  };

  const handleOpenEdinModal = (release: any) => {
    setModalRelease(release);
    setOpenModal(true);
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Releases" defaultMessage="Releases" />
          </Typography>
          <Button onClick={openNewReleaseModal}>
            <FormattedMessage
              id="general.New-Release"
              defaultMessage="New Release"
            />
          </Button>
        </Box>
        <ReleaseModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          extRelease={modalRelease}
        />
        <ReleasesTable handleOpenEdinModal={handleOpenEdinModal} />
      </Box>
    </Container>
  );
};

export default Releases;
