import React, { useLayoutEffect, useRef, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TASK_STATUS, TASK_LABEL_COLOR } from "../../constants/constants";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Box, CircularProgress, IconButton, useTheme } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, FormattedPlural } from "react-intl";

import {
  createQuoteItem,
  removeQuoteItems,
} from "../../redux/reducerSlices/quote";
import {
  createInvoiceItem,
  removeInvoiceItems,
} from "../../redux/reducerSlices/invoice";
import { TaskRow } from "./types";
import {
  addTaskToRelease,
  removeTaskFromRelease,
} from "../../redux/reducerSlices/release";
import { sawTask } from "../../redux/reducerSlices/task";
import {
  formatTaskTitle,
  getInvoiceTitle,
  localizeTaskStatus,
  localizeTaskLabel,
} from "./functions";

const trelloIcon = require("../../assets/img/trello_icon.png");

export const QuoteMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Task: React.FC<{
  task: TaskRow;
  forwardRef: any;
  isDragging: boolean;
  dragHandleProps: any;
  enableSortHandler: boolean;
}> = ({
  task,
  forwardRef,
  isDragging,
  dragHandleProps,
  enableSortHandler,
  ...rest
}) => {
  const {
    status,
    handleOpenEdinTaskModal,
    handleOpenQuoteModal,
    draftQuotes,
    addToQuoteTabCB,
    handleOpenInvoiceModal,
    draftInvoices,
    addToInvoiceCB,
    isInToInvoiceTab,
    handleOpenEstimateModal,
    draftReleases,
    handleOpenReleaseModal,
  } = task;
  const theme = useTheme();
  const dispatch = useDispatch();

  const sentQuotes =
    task.quote_items?.filter((qi) => qi.quote?.status !== "draft") || [];
  const sentInvoices =
    task.invoice_items?.filter((ii) => ii.invoice?.status !== "draft") || [];

  const user = useSelector((state: RootStateOrAny) => state.user);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRef = useRef(null);
  const [handleWidth, setHandleWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (handleRef.current) {
      setHandleWidth(handleRef.current.offsetWidth);
    }
  }, [])

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElInvoice, setAnchorElInvoice] = useState<null | HTMLElement>(
    null
  );
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);

  const openInvoice = Boolean(anchorElInvoice);
  const handleClickInvoice = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElInvoice(event.currentTarget);
  };
  const handleCloseInvoice = () => {
    setAnchorElInvoice(null);
  };

  const [anchorElRelease, setAnchorElRelease] = useState<null | HTMLElement>(
    null
  );
  const [loadingRelease, setLoadingRelease] = useState<boolean>(false);

  const openRelease = Boolean(anchorElRelease);
  const handleClickRelease = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRelease(event.currentTarget);
  };
  const handleCloseRelease = () => {
    setAnchorElRelease(null);
  };

  const handleAddNewQuote = () => {
    handleClose();
    handleOpenQuoteModal(task);
  };

  const handleOpenExtQuote = (quote: any) => {
    handleClose();
    handleOpenQuoteModal(null, quote);
  };

  const currentStatus = TASK_STATUS.filter(
    (taskStatus) => taskStatus.label === status
  );

  const chipColor: any = currentStatus[0] ? currentStatus[0] : TASK_STATUS[0];

  const handleEditTask = () => {
    handleOpenEdinTaskModal(task);
  };

  const handleAddToQuote = async (quote: any) => {
    setLoading(true);

    await dispatch(
      createQuoteItem({
        data: {
          task: task,
          quote: quote,
        },
      })
    );

    if (addToQuoteTabCB) {
      addToQuoteTabCB();
    }

    setLoading(false);
    handleClose();
  };

  const handleRemoveFromQuote = (quote: any) => {
    dispatch(
      removeQuoteItems({
        quote,
        task,
      })
    );

    handleClose();
  };

  const openEstimateModal = () => {
    handleOpenEstimateModal(task);
  };

  const renderQuoteButton = () => {
    if (loading) {
      return (
        <div
          style={{
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="22px" />
        </div>
      );
    }

    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton onClick={handleClick}>
          <RequestQuoteIcon
            style={{
              color: task.quote_items.length ? theme.color.primary : "#757575",
            }}
          />
        </IconButton>
        <QuoteMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {draftQuotes.map((dQuote) => {
            if (task.quoteItemsMap[dQuote.id]) {
              return (
                <MenuItem
                  key={dQuote.id}
                  onClick={() => {
                    handleRemoveFromQuote(dQuote);
                  }}
                  disableRipple
                >
                  <EditIcon />
                  <FormattedMessage
                    id="task-row.remove-from-quote"
                    defaultMessage="Remove from quote #{quote_number}"
                    values={{
                      quote_number: dQuote.number,
                    }}
                  />
                </MenuItem>
              );
            }

            return (
              <MenuItem
                key={dQuote.id}
                onClick={() => {
                  handleAddToQuote(dQuote);
                }}
                disableRipple
              >
                <EditIcon />
                <FormattedMessage
                  id="task-row.Add-to-quote"
                  defaultMessage="Add to quote #{quote_number}"
                  values={{
                    quote_number: dQuote.number,
                  }}
                />
              </MenuItem>
            );
          })}
          {sentQuotes.map((qi) => {
            if (!qi.quote?.number) return null;

            return (
              <MenuItem
                key={qi.id}
                onClick={() => {
                  handleOpenExtQuote(qi.quote);
                }}
                disableRipple
              >
                <MarkEmailReadIcon />
                <FormattedMessage
                  id="task-row.Show-quote"
                  defaultMessage="Show quote #{quote_number}"
                  values={{
                    quote_number: qi.quote.number,
                  }}
                />
              </MenuItem>
            );
          })}
          {Boolean(draftQuotes.length) && <Divider sx={{ my: 0.5 }} />}
          <MenuItem onClick={handleAddNewQuote} disableRipple>
            <AddBoxIcon />
            <FormattedMessage
              id="task-row.Add-to-new-quote"
              defaultMessage="Add to new quote"
            />
          </MenuItem>
        </QuoteMenu>
      </span>
    );
  };

  const handleAddToInvoice = async (invoice: any) => {
    setLoadingInvoice(true);

    let price;

    if (isInToInvoiceTab) {
      price = getRemingingToInvoice();
    }

    await dispatch(
      createInvoiceItem({
        data: {
          price,
          task: task,
          invoice: invoice,
        },
        title: getInvoiceTitle(task, invoice.company),
      })
    );

    if (addToInvoiceCB) {
      addToInvoiceCB();
    }

    setLoadingInvoice(false);
    handleCloseInvoice();
  };

  const handleRemoveFromInvoice = (invoice: any) => {
    dispatch(
      removeInvoiceItems({
        invoice,
        task,
      })
    );

    handleCloseInvoice();
  };

  const handleAddNewInvoice = () => {
    handleClose();
    handleOpenInvoiceModal(task);
  };

  const handleOpenExtInvoice = (invoice: any) => {
    handleClose();
    handleOpenInvoiceModal(null, invoice);
  };

  const renderInvoiceButton = () => {
    if (loadingInvoice) {
      return (
        <div
          style={{
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="22px" />
        </div>
      );
    }

    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton onClick={handleClickInvoice}>
          <ReceiptIcon
            style={{
              color: task.invoice_items.length
                ? theme.color.primary
                : "#757575",
            }}
          />
        </IconButton>
        <QuoteMenu
          anchorEl={anchorElInvoice}
          open={openInvoice}
          onClose={handleCloseInvoice}
        >
          {draftInvoices.map((dInvoice) => {
            if (task.invoiceItemsMap[dInvoice.id]) {
              return (
                <MenuItem
                  key={dInvoice.id}
                  onClick={() => {
                    handleRemoveFromInvoice(dInvoice);
                  }}
                  disableRipple
                >
                  <EditIcon />
                  <FormattedMessage
                    id="task-row.Remove-from-invoice"
                    defaultMessage="Remove from invoice #{invoice_number}"
                    values={{
                      invoice_number: dInvoice.number,
                    }}
                  />
                </MenuItem>
              );
            }

            return (
              <MenuItem
                key={dInvoice.id}
                onClick={() => {
                  handleAddToInvoice(dInvoice);
                }}
                disableRipple
              >
                <EditIcon />
                <FormattedMessage
                  id="task-row.Add-to-invoice"
                  defaultMessage="Add to invoice #{invoice_number}"
                  values={{
                    invoice_number: dInvoice.number,
                  }}
                />
              </MenuItem>
            );
          })}
          {sentInvoices.map((ii) => {
            if (!ii.invoice) return null;

            return (
              <MenuItem
                key={ii.id}
                onClick={() => {
                  handleOpenExtInvoice(ii.invoice);
                }}
                disableRipple
              >
                <MarkEmailReadIcon />
                <FormattedMessage
                  id="task-row.Show-invoice"
                  defaultMessage="Show invoice #{invoice_number}"
                  values={{
                    invoice_number: ii.invoice?.number,
                  }}
                />
              </MenuItem>
            );
          })}
          {Boolean(draftInvoices.length) && <Divider sx={{ my: 0.5 }} />}
          <MenuItem onClick={handleAddNewInvoice} disableRipple>
            <AddBoxIcon />
            <FormattedMessage
              id="task-row.Add-to-new-invoice"
              defaultMessage="Add to new invoice"
            />
          </MenuItem>
        </QuoteMenu>
      </span>
    );
  };

  const handleAddNewRelease = () => {
    handleClose();
    handleOpenReleaseModal(task);
  };

  const handleAddToRelease = async (release: any) => {
    setLoadingRelease(true);

    await dispatch(addTaskToRelease(task, release));

    handleCloseRelease();
    setLoadingRelease(false);
  };

  const handleRemoveFromRelease = async (release: any) => {
    setLoadingRelease(true);

    await dispatch(removeTaskFromRelease(task, release));

    handleCloseRelease();
    setLoadingRelease(false);
  };

  const renderReleaseButton = () => {
    if (loadingRelease) {
      return (
        <div
          style={{
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="22px" />
        </div>
      );
    }

    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton onClick={handleClickRelease}>
          <NewReleasesIcon
            style={{
              color: task.release ? theme.color.primary : "#757575",
            }}
          />
        </IconButton>
        <QuoteMenu
          anchorEl={anchorElRelease}
          open={openRelease}
          onClose={handleCloseRelease}
        >
          {draftReleases.map((dRelease) => {
            if (dRelease.id === task.release?.id) {
              return (
                <MenuItem
                  key={dRelease.id}
                  onClick={() => {
                    handleRemoveFromRelease(dRelease);
                  }}
                  disableRipple
                >
                  <EditIcon />
                  <FormattedMessage
                    id="task-row.Remove-from-release"
                    defaultMessage="Remove from release #{release_id}"
                    values={{
                      release_id: dRelease.id,
                    }}
                  />
                </MenuItem>
              );
            }

            return (
              <MenuItem
                key={dRelease.id}
                onClick={() => {
                  handleAddToRelease(dRelease);
                }}
                disableRipple
              >
                <EditIcon />
                <FormattedMessage
                  id="task-row.Add-to-release"
                  defaultMessage="Add to release #{release_id}"
                  values={{
                    release_id: dRelease.id,
                  }}
                />
              </MenuItem>
            );
          })}
          {Boolean(task.release && task.release?.status !== "draft") && (
            <MenuItem
              onClick={() => {
                handleOpenReleaseModal(task, task.release);
              }}
              disableRipple
            >
              <MarkEmailReadIcon />
              <FormattedMessage
                id="task-row.Show-release"
                defaultMessage="Show release #{release_id}"
                values={{
                  release_id: task.release.id,
                }}
              />
            </MenuItem>
          )}
          {Boolean(draftInvoices.length) && <Divider sx={{ my: 0.5 }} />}
          <MenuItem onClick={handleAddNewRelease} disableRipple>
            <AddBoxIcon />
            <FormattedMessage
              id="task-row.Add-to-new-release"
              defaultMessage="Add to new release"
            />
          </MenuItem>
        </QuoteMenu>
      </span>
    );
  };

  const getRemingingToInvoice = () => {
    let res = 0;

    if (task.status !== "Closed") {
      res =
        (task.quoted_total * task.partial_invoicing) / 100 -
        task.invoiced_total;
    }

    res = task.quoted_total - task.invoiced_total;

    return parseFloat(String(res)).toFixed(2);
  };

  return (
    <TableRow
      sx={{
        "& td, & th": {
          borderBottom: isDragging ? 0 : "1px solid rgba(224, 224, 224, 1)",
        },
        "&:last-child td, &:last-child th": {
          border: 0,
        },
        "& th": {
          paddingLeft: enableSortHandler ? 0 : "20px",
        },
        display: isDragging ? "flex" : "table-row",
        border: isDragging ? "1px solid rgba(224, 224, 224, 1)" : 0,
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        cursor: "pointer",
      }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditTask();
      }}
      {...rest}
      ref={forwardRef}
    >
      <TableCell
        component="th"
        scope="row"
        style={{
          width: 350,
          maxWidth: 350,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <span
            {...dragHandleProps}
            style={{
              display: enableSortHandler ? "block" : "none",
              paddingTop: 7,
              paddingRight: 10,
              paddingLeft: 10,
            }}
            ref={handleRef}
          >
            <DragHandleIcon />
          </span>
          <span
            style={{
              position: "relative",
              display: "inline-block",
              width: `calc(100% - ${handleWidth}px)`,
            }}
          >
            <span
              style={{
                position: "relative",
                display: "inline-flex",
                maxWidth: "100%",
              }}
            >
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  display: "inline-block",
                }}
              >
                {formatTaskTitle(task)}
              </span>
              {!task.seen_by.length && (
                <span
                  style={{
                    position: "absolute",
                    width: 7,
                    height: 7,
                    borderRadius: "50%",
                    background: "#DE350B",
                    marginTop: -1,
                    right: -8,
                  }}
                >
                  {/* empty */}
                </span>
              )}
            </span>
            <span
              style={{
                position: "absolute",
                bottom: -12,
                left: 0,
                fontSize: 10,
                color: "#999",
              }}
            >
              {task.due_date && (
                <FormattedMessage
                  id="task-row.Due"
                  defaultMessage="Due {date}"
                  values={{
                    date: task.due_date,
                  }}
                />
              )}
              {task.due_date && task.estimates.length > 0 && ", "}
              {task.estimates.length > 0 && (
                <span>
                  <FormattedPlural
                    value={task.estimates.length}
                    one={<FormattedMessage id="general.Estimate" />}
                    other={<FormattedMessage id="general.Estimates" />}
                  />
                  <span>: </span>
                  {task.estimates.map((est) => est.hours + "h").join(", ")}
                </span>
              )}
            </span>
          </span>
        </div>
      </TableCell>
      <TableCell align="left">
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ position: "relative" }}>
            <Box
              sx={{
                border: "1px solid",
                borderColor: chipColor.color.border,
                bgcolor: chipColor.color.border,
                borderRadius: 1,
                color: chipColor.color.text,
                fontWeight: "bold",
                paddingLeft: "4px",
                paddingRight: "4px",
                whiteSpace: "nowrap",
              }}
            >
              {localizeTaskStatus(status)}
            </Box>
            {Boolean(task.blocked_by_tasks.length) && (
              <small
                style={{
                  display: "block",
                  color: "#999",
                  position: "absolute",
                  marginTop: -1,
                }}
              >
                <FormattedMessage
                  id="general.Blocked"
                  defaultMessage="Blocked"
                />
              </small>
            )}
          </div>
        </Box>
      </TableCell>
      <TableCell align="left">
        <Box
          sx={{
            display: "inline-block",
            color: TASK_LABEL_COLOR[task.label?.color]?.text,
            bgcolor: TASK_LABEL_COLOR[task.label?.color]?.background,
            border: "1px solid transparent",
            borderRadius: 1,
            paddingLeft: "4px",
            paddingRight: "4px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          {localizeTaskLabel(task.label?.name)}
        </Box>
      </TableCell>
      <TableCell align="left">
        <span
          style={{
            whiteSpace: "nowrap",
            marginRight: 5,
            display: isDragging ? "none" : "table-cell",
          }}
        >
          {task.project?.name}
        </span>
      </TableCell>
      <TableCell align="left">
        <span
          style={{
            whiteSpace: "nowrap",
            marginRight: 5,
            display: isDragging ? "none" : "table-cell",
          }}
        >
          {task.developer?.email}
        </span>
      </TableCell>
      {isInToInvoiceTab && (
        <TableCell align="right">{getRemingingToInvoice()}</TableCell>
      )}
      <TableCell
        align="right"
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IconButton onClick={openEstimateModal}>
            <AccessTimeFilledIcon
              style={{
                color: task.estimates.length ? theme.color.primary : "#757575",
              }}
            />
          </IconButton>
        </span>
        {Boolean(task.trello_url) && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              if (!task.seen_by?.length) {
                dispatch(sawTask(task.id));
              }
            }}
          >
            <a href={task.trello_url} target="_blank" rel="noopener noreferrer">
              <IconButton>
                <img
                  src={trelloIcon}
                  width="23px"
                  style={{ padding: 2 }}
                  alt="Trello icon"
                />
              </IconButton>
            </a>
          </span>
        )}
        {Boolean(user?.selectedOrg.role === "Admin") && renderQuoteButton()}
        {Boolean(user?.selectedOrg.role === "Admin") && renderInvoiceButton()}
        {Boolean(user?.selectedOrg.role === "Admin") && renderReleaseButton()}
      </TableCell>
    </TableRow>
  );
};

export default Task;
