import axios from "axios";
import { toast } from "react-hot-toast";
import QueryString from "qs";

import { getIntl } from "../../GlobalIntlProvider";

const initialState = {
  purchaseOrdersNotFullyInvoiced: [],
  purchaseOrders: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
};

const purchaseOrderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "PO/GETNOTFULLYINVOICED":
      const purchaseOrdersNotFullyInvoiced = action.payload.purchaseOrders;
      return {
        ...state,
        purchaseOrdersNotFullyInvoiced,
      };
    case "PO/GETALL":
      const {
        meta: { pagination },
        data,
      } = action.payload.purchaseOrdersRes;

      return {
        ...state,
        pagination,
        purchaseOrders: data.map((purchaseOrder: any) => {
          return {
            id: purchaseOrder.id,
            ...purchaseOrder.attributes,
            attachments:
              purchaseOrder.attributes.attachments.data?.map(
                (attachment: any) => {
                  return {
                    id: attachment.id,
                    ...attachment.attributes,
                  };
                }
              ) || [],
            company: purchaseOrder.attributes.company?.data
              ? {
                  id: purchaseOrder.attributes.company.data.id,
                  ...purchaseOrder.attributes.company.data.attributes,
                }
              : null,
          };
        }),
      };
    case "PO/EDIT/FILES":
      const updatedPOFiles = action.payload.purchaseOrder;

      return {
        ...state,
        purchaseOrders: state.purchaseOrders.map((purchaseOrder) => {
          if (purchaseOrder.id === updatedPOFiles.id) {
            return {
              ...purchaseOrder,
              attachments: updatedPOFiles.attachments.data.map(
                (attachment: any) => {
                  return {
                    id: attachment.id,
                    ...attachment.attributes,
                  };
                }
              ),
              company: {
                id: updatedPOFiles.company.data.id,
                ...updatedPOFiles.company.data.attributes,
              },
            };
          }

          return purchaseOrder;
        }),
      };
    default:
      return state;
  }
};

export const getPOsNotFullyInvoiced = () => {
  return async (dispatch: any) => {
    const response = await axios(`/purchase-orders-not-fully-invoiced`);
    const purchaseOrders = response.data;

    dispatch({
      type: "PO/GETNOTFULLYINVOICED",
      payload: {
        purchaseOrders,
      },
    });
  };
};

export const getPurchaseOrders = ({ page, pageSize }) => {
  return async (dispatch: any, getState: any) => {
    const {
      purchaseOrderState: { pagination },
    } = getState();

    try {
      const query = QueryString.stringify({
        pagination: {
          page: page || pagination.page,
          pageSize: pageSize || pagination.pageSize,
        },
        populate: {
          company: {
            fields: ["name", "requires_po"],
          },
          attachments: true,
        },
      });

      const response = await axios(`/purchase-orders?${query}`);
      const purchaseOrdersRes = response.data;

      dispatch({
        type: "PO/GETALL",
        payload: {
          purchaseOrdersRes,
        },
      });
    } catch (err) {
      console.error(err);

      toast.error(
        getIntl().formatMessage({
          id: "purchaseOrder.get.error",
          defaultMessage: "Error getting purchase orders",
        })
      );
    }
  };
};

export const refreshPurchaseOrders = () => {
  return async (dispatch: any, getState: any) => {
    const { pagination } = getState().purchaseOrderState;

    dispatch(getPurchaseOrders(pagination));
  };
};

export const createPurchaseOrder = (poDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.post(`/purchase-orders`, poDetails);

      dispatch(refreshPurchaseOrders());

      toast.success(
        getIntl().formatMessage({
          id: "purchaseOrder.create.success",
          defaultMessage: "Purchase order created successfully",
        })
      );
    } catch (err) {
      console.error(err);

      toast.error(
        getIntl().formatMessage({
          id: "purchaseOrder.create.error",
          defaultMessage: "Error creating purchase order",
        })
      );
    }
  };
};

export const deletePurchaseOrder = (ticketID: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/purchase-orders/${ticketID}`);

      dispatch(refreshPurchaseOrders());

      toast.success(
        getIntl().formatMessage({
          id: "purchaseOrder.delete.success",
          defaultMessage: "Purchase order deleted successfully",
        })
      );
    } catch (err) {
      console.error(err);

      toast.error(
        getIntl().formatMessage({
          id: "purchaseOrder.delete.error",
          defaultMessage: "Error deleting purchase order",
        })
      );
    }
  };
};

export const updatePurchaseOrder = (poDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/purchase-orders/${poDetails.data.id}`, {
        data: poDetails.data,
      });

      dispatch(refreshPurchaseOrders());

      toast.success(
        getIntl().formatMessage({
          id: "purchaseOrder.update.success",
          defaultMessage: "Purchase order updated successfully",
        })
      );
    } catch (err) {
      console.error(err);

      toast.error(
        getIntl().formatMessage({
          id: "purchaseOrder.update.error",
          defaultMessage: "Error updating purchase order",
        })
      );
    }
  };
};

export const updatePOFiles = (poDetails: any) => {
  return async (dispatch: any) => {
    const response = await axios.put(`/purchase-orders/${poDetails.data.id}`, {
      data: poDetails.data,
    });
    const purchaseOrder = response.data;

    dispatch({
      type: "PO/EDIT/FILES",
      payload: {
        purchaseOrder: { ...purchaseOrder },
      },
    });
  };
};

export default purchaseOrderReducer;
