import { TableCell, TableRow } from "@mui/material";
import { FormattedMessage } from "react-intl";

const SubscriptionRow: React.FC<{
  subscription: {
    handleOpenEdinModal: any;
    id?: number;
    title?: String;
    active?: String;
    company?: any;
  };
}> = ({ subscription }, props) => {
  const { title, active, company, handleOpenEdinModal } = subscription;

  const handleEditSubscription = () => {
    handleOpenEdinModal(subscription);
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditSubscription();
      }}
    >
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="left">
        {active ? (
          <FormattedMessage id="general.Yes" defaultMessage="Yes" />
        ) : (
          <FormattedMessage id="general.No" defaultMessage="No" />
        )}
      </TableCell>
      <TableCell align="left">{company?.name}</TableCell>
    </TableRow>
  );
};

export default SubscriptionRow;
