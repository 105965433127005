import axios from "axios";
import qs from "qs";

const initialState = {
  subscriptions: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
  loading: false,
};

const subscriptionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SUBSCRIPTIONS/LOADING/TRUE":
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case "SUBSCRIPTIONS/LOADING/FALSE":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "SUBSCRIPTIONS/GETALL":
      const { subscriptions, pagination } = action.payload;

      return {
        ...state,
        subscriptions: subscriptions.map((s) => {
          s.subscription_items?.sort(
            (a: any, b: any) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime()
          );

          return s;
        }),
        pagination,
      };
    default:
      return state;
  }
};

export const getSubscriptionsToInvoice = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      subscriptionState: { pagination },
    } = getState();

    const query = qs.stringify({
      pagination: {
        page: page ? page : pagination.page,
        pageSize: pageSize ? pageSize : pagination.pageSize,
      },
    });

    const res = await axios.get(`/subscriptions-to-invoice?${query}`);
    const subscriptions = res.data.results;

    dispatch({
      type: "SUBSCRIPTIONS/GETALL",
      payload: {
        subscriptions,
        pagination: res.data.pagination,
      },
    });
  };
};

export const getSubscriptions = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      subscriptionState: { pagination },
    } = getState();

    const query = qs.stringify({
      pagination: {
        page: page ? page : pagination.page,
        pageSize: pageSize ? pageSize : pagination.pageSize,
      },
      populate: ["company"],
    });

    const res = await axios.get(`/subscriptions?${query}`);

    const subscriptions = res.data.data.map((s: any) => {
      return {
        id: s.id,
        ...s.attributes,
        company: s.attributes.company.data
          ? {
              id: s.attributes.company.data?.id,
              ...s.attributes.company.data?.attributes,
            }
          : null,
      };
    });

    dispatch({
      type: "SUBSCRIPTIONS/GETALL",
      payload: {
        subscriptions,
        pagination: res.data.meta.pagination,
      },
    });
  };
};

export const createSubscription = (subscriptionDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.post(`/subscriptions`, {
        data: subscriptionDetails,
      });

      dispatch(getSubscriptions());
    } catch (err) {
      console.error(err);
      dispatch({
        type: "SUBSCRIPTIONS/CREATE",
        payload: null,
        error: err,
      });
    }
  };
};

export const deleteSubscription = (subscriptionID: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/subscriptions/${subscriptionID}`);

      dispatch(getSubscriptions());
    } catch (err) {
      console.error(err);
      dispatch({
        type: "SUBSCRIPTIONS/DELETE",
        payload: null,
        error: err,
      });
    }
  };
};

export const updateSubscription = (subscriptionDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/subscriptions/${subscriptionDetails.id}`, {
        data: subscriptionDetails,
      });

      dispatch(getSubscriptions());
    } catch (err) {
      console.error(err);
      dispatch({
        type: "SUBSCRIPTIONS/UPDATE",
        payload: null,
        error: err,
      });
    }
  };
};

export default subscriptionReducer;
