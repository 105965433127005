import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentIcon from "@mui/icons-material/Assignment";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import PeopleIcon from "@mui/icons-material/People";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, Collapse, FormControl, MenuItem, Select } from "@mui/material";
import { AppBar as MuiAppBar } from "@mui/material";
import { Toolbar } from "@mui/material";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import Box from "@mui/material/Box";
import { useHistory, Link } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  changeLanguage,
  changeOrganization,
} from "../redux/reducerSlices/user";
import InvitesModal from "./user/invitesModal";
import { getInviteRequests } from "../redux/reducerSlices/invite";
import { Paid } from "@mui/icons-material";

const gotologicLogo = require("../assets/img/gotologic-logo.png");

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function AppBar(props: any) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, organizations, inviteState, layout } = useSelector(
    (state: RootStateOrAny) => state
  );

  const selectedOrg = user.selectedOrg || {};
  const isAdmin = selectedOrg?.role === "Admin";
  const isDev = selectedOrg?.role === "Developer";

  const [open, setOpen] = React.useState(false);
  const [openLangCollapse, setOpenLangCollapse] =
    React.useState<boolean>(false);

  const [openInvitesModal, setOpenInvitesModal] = React.useState(false);

  React.useEffect(() => {
    if (user.jwt) {
      dispatch(getInviteRequests());
    }
  }, [dispatch, user.jwt]);

  React.useEffect(() => {
    if (!inviteState.requests.length) {
      setOpenInvitesModal(false);
    }
  }, [dispatch, inviteState.requests.length]);

  if (layout.disableAppBar) {
    return null;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenLangCollapse(false);
  };

  const handleLogout = () => {
    if (props.logout) {
      props.logout();
    } else {
      console.error("props.logout undefined", props);
    }
  };

  const handleChangeLanguage = (langCode: "en" | "fr" | "sv") => {
    dispatch(changeLanguage(langCode));
    setOpenLangCollapse(false);
  };

  const renderTopBar = () => {
    if (
      user.selectedOrg?.subscription_status !== "active" &&
      user.selectedOrg?.subscription_status !== "initial" &&
      user.selectedOrg?.isOwner
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            backgroundColor: "#f44336",
            color: "#fff",
            padding: "5px 15px",
          }}
        >
          <Box>
            <Link
              to="/profile/subscriptions"
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
            >
              <span style={{ fontSize: 12 }}>
                {intl.formatMessage({
                  id: "general.Your-subscription-is-past-due",
                  defaultMessage:
                    "Your subscription is past due. Please update your payment information.",
                })}
              </span>
            </Link>
          </Box>
        </Box>
      );
    }
  };

  return (
    <MuiAppBar
      position="absolute"
      elevation={0}
      style={{ backgroundColor: "#222321" }}
      sx={{
        position: "relative",
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      {renderTopBar()}
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link to="/dashboard">
            <img width={160} src={gotologicLogo} alt="Gotologic logo" />
          </Link>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {Boolean(inviteState?.requests.length) && (
            <IconButton
              onClick={() => {
                setOpenInvitesModal(true);
              }}
              style={{
                color: "#fff",
                marginRight: 15,
                position: "relative",
              }}
            >
              <DomainAddIcon />
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "#f44336",
                  borderRadius: "50%",
                  color: "#fff",
                  fontSize: 12,
                  fontWeight: 600,
                  width: 17,
                  height: 17,
                  paddingTop: 2,
                }}
              >
                {inviteState?.requests.length}
              </span>
            </IconButton>
          )}
          <FormControl
            style={{
              width: 190,
              color: "#fff",
            }}
            size="small"
          >
            <Select
              displayEmpty
              style={{
                color: "#fff",
                border: "none",
              }}
              sx={{
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#22232100",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#22232100",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#22232100",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
              name="selected-organization"
              value={selectedOrg?.id || ""}
              type="text"
              onChange={(event) => {
                if (event.target.value) {
                  dispatch(changeOrganization(event.target.value));
                }
              }}
            >
              <MenuItem value="">
                <em>
                  {intl.formatMessage({
                    id: "general.Select-organization",
                    defaultMessage: "Select organization",
                  })}
                </em>
              </MenuItem>
              {organizations?.map((org: any) => {
                return (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button onClick={handleDrawerOpen}>
            <MenuOpenIcon
              style={{
                fontSize: 30,
                color: "#fff",
              }}
            />
          </Button>
          <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                <ChevronRightIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => history.push("/dashboard")}>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.Dashboard",
                        defaultMessage: "Dashboard",
                      })}
                    />
                  </ListItemButton>
                </ListItem>
                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/projects")}>
                      <ListItemIcon>
                        <DesignServicesIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Projects",
                          defaultMessage: "Projects",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/quotes")}>
                      <ListItemIcon>
                        <RequestQuoteIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Quotes",
                          defaultMessage: "Quotes",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/invoices")}>
                      <ListItemIcon>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Invoices",
                          defaultMessage: "Invoices",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {(isAdmin || isDev) && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/tasks")}>
                      <ListItemIcon>
                        <AssignmentTurnedInIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Tasks",
                          defaultMessage: "Tasks",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/tickets")}>
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Tickets",
                          defaultMessage: "Tickets",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/expenses")}>
                      <ListItemIcon>
                        <Paid />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Expenses",
                          defaultMessage: "Expenses",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/releases")}>
                      <ListItemIcon>
                        <NewReleasesIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Releases",
                          defaultMessage: "Releases",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/users")}>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Users",
                          defaultMessage: "Users",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push("/companies")}>
                      <ListItemIcon>
                        <HandshakeIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Clients",
                          defaultMessage: "Clients",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => history.push("/purchase-orders")}
                    >
                      <ListItemIcon>
                        <ShoppingCartIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Purchase-Orders",
                          defaultMessage: "Purchase Orders",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isAdmin && (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => history.push("/subscriptions")}
                    >
                      <ListItemIcon>
                        <LoyaltyIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Subscriptions",
                          defaultMessage: "Subscriptions",
                        })}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => history.push("/organizations")}
                  >
                    <ListItemIcon>
                      <CorporateFareIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.Organizations",
                        defaultMessage: "Organizations",
                      })}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => history.push("/profile/basic-information")}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.Profile",
                        defaultMessage: "Profile",
                      })}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItemButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpenLangCollapse(!openLangCollapse);
                  }}
                >
                  <ListItemIcon>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage({
                      id: "general.Language",
                      defaultMessage: "Language",
                    })}
                  />
                  {openLangCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openLangCollapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeLanguage("en");
                      }}
                    >
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.English",
                          defaultMessage: "English",
                        })}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeLanguage("fr");
                      }}
                    >
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.French",
                          defaultMessage: "French",
                        })}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeLanguage("sv");
                      }}
                    >
                      <ListItemText
                        primary={intl.formatMessage({
                          id: "general.Swedish",
                          defaultMessage: "Swedish",
                        })}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.Logout",
                        defaultMessage: "Logout",
                      })}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Box>
      </Toolbar>
      <InvitesModal
        openModal={openInvitesModal}
        setOpenModal={setOpenInvitesModal}
      />
    </MuiAppBar>
  );
}
