import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { Check, Delete, Edit } from "@mui/icons-material";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { FormattedMessage, useIntl } from "react-intl";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import { QuoteItem, QuoteItemStatus, Task } from "../task/types";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const QuoteItemRow: React.FC<{
  qi: QuoteItem;
  openConfirmItemModal: (qi: QuoteItem) => void;
  openTaskModal: (task: Task) => void;
  updateItem: (
    qi: QuoteItem,
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<QuoteItemStatus>
  ) => void;
  updateQiStatus: (qi: QuoteItem, newStatus: QuoteItemStatus) => void;
  deleteDisabled: boolean;
  openQiModal: () => void;
  forwardRef: any;
  isDragging: boolean;
  dragHandleProps: any;
  company: any;
  updateQiTask: (task: Task) => void;
}> = ({
  qi,
  openConfirmItemModal,
  updateItem,
  deleteDisabled,
  updateQiStatus,
  openQiModal,
  forwardRef,
  isDragging,
  dragHandleProps,
  company,
  updateQiTask,
  ...rest
}) => {
  const intl = useIntl();

  const [edititngTitle, setEditingTitle] = useState<boolean>(false);
  const priceFieldRef = useRef<HTMLInputElement>(null);

  const handlePriceWheel = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    const priceElm = priceFieldRef.current;

    priceElm.addEventListener("wheel", handlePriceWheel, { passive: false });

    return () => {
      priceElm.removeEventListener("wheel", handlePriceWheel);
    };
  }, []);

  const renderTitle = () => {
    if (edititngTitle) {
      return (
        <div
          className="quote-item-title item-title"
          style={{ overflow: "visible" }}
        >
          <span style={{ overflow: "visible" }}>
            <TextField
              size="small"
              id="title"
              label={
                <FormattedMessage id="general.Title" defaultMessage="Title" />
              }
              type="text"
              name="title"
              variant="outlined"
              value={qi.title}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setEditingTitle(false);
                }
              }}
              onChange={(event) => updateItem(qi, event)}
              style={{
                width: 260,
                marginRight: 3,
              }}
            />
          </span>
          <div className="invoice-item-edit-title-btn">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setEditingTitle(!edititngTitle);
              }}
            >
              <Check />
            </IconButton>
          </div>
        </div>
      );
    }

    return (
      <div
        className="quote-item-title item-title"
        onClick={() => {
          openQiModal();
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <span style={{ position: "relative", padding: "5px 5px 5px 0" }}>
          {qi.title || qi.task?.title}
        </span>
        <div className="invoice-item-edit-title-btn">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setEditingTitle(!edititngTitle);
            }}
          >
            <Edit />
          </IconButton>
        </div>
      </div>
    );
  };

  const renderEstimates = () => {
    if (!qi.task?.estimates || qi.task?.estimates.length < 0) {
      return null;
    }

    return (
      <div
        className="quote-item-estimates"
        style={{
          display: edititngTitle ? "none" : "flex",
        }}
      >
        {qi.task?.estimates?.map((est: any) => {
          return (
            <div key={est.id} className="quote-item-estimate">
              <HtmlTooltip
                title={
                  <div>
                    <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                      <strong>
                        {est.user?.firstName ||
                          est.user?.lastName ||
                          est.user?.username}
                      </strong>
                    </h3>
                    <div>
                      <FormattedMessage
                        id="general.estimate"
                        defaultMessage="estimate"
                      />
                      : {est.hours}h
                    </div>
                    <div>
                      <FormattedMessage
                        id="general.comment"
                        defaultMessage="comment"
                      />
                      : "{est.comments}"
                    </div>
                  </div>
                }
              >
                <span>{est.hours}h</span>
              </HtmlTooltip>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div ref={forwardRef} {...rest}>
      <span
        {...dragHandleProps}
        style={{
          paddingTop: 16,
          paddingRight: 5,
          paddingLeft: 5,
        }}
      >
        <DragHandleIcon />
      </span>
      <div
        className="quote-item-row"
        style={{
          flexGrow: 1,
          maxWidth: "calc(100% - 35px)",
        }}
      >
        <div
          style={{
            width: "calc(100% - 300px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {renderTitle()}
          {renderEstimates()}
        </div>
        <div className="quote-item-inputs-container">
          <TextField
            size="small"
            id="price"
            label={
              <FormattedMessage id="general.Price" defaultMessage="Price" />
            }
            type="number"
            ref={priceFieldRef}
            name="price"
            className="quote-item-price"
            variant="outlined"
            value={qi.price}
            onChange={(event) => updateItem(qi, event)}
          />

          <ToggleButtonGroup
            size="small"
            color="primary"
            exclusive
            onChange={(event, newStatus) => {
              updateQiStatus(qi, newStatus);
            }}
            value={qi.status}
          >
            <ToggleButton
              title={intl.formatMessage({
                id: "quote-item-row.Pendint-status-title",
                defaultMessage:
                  "Pending: waiting for an answer from the client.",
              })}
              value={QuoteItemStatus.pending}
              key={QuoteItemStatus.pending}
            >
              <PendingIcon />
            </ToggleButton>
            <ToggleButton
              title={intl.formatMessage({
                id: "quote-item-row.Accepted-status-title",
                defaultMessage:
                  "Accepted: the client accepted the quote for this item.",
              })}
              value={QuoteItemStatus.accepted}
              key={QuoteItemStatus.accepted}
            >
              <CheckCircleIcon />
            </ToggleButton>
            <ToggleButton
              title={intl.formatMessage({
                id: "quote-item-row.Rejected-status-title",
                defaultMessage:
                  "Rejected: the client will not move forward with this item.",
              })}
              value={QuoteItemStatus.rejected}
              key={QuoteItemStatus.rejected}
            >
              <CancelIcon />
            </ToggleButton>
            <ToggleButton
              title={intl.formatMessage({
                id: "quote-item-row.Canceled-status-title",
                defaultMessage:
                  "Canceled: the client may want a new quote for this item later.",
              })}
              value={QuoteItemStatus.canceled}
              key={QuoteItemStatus.canceled}
            >
              <WatchLaterIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <div style={{ paddingTop: 3 }}>
            <IconButton
              aria-label="delete"
              disabled={deleteDisabled}
              onClick={() => {
                openConfirmItemModal(qi);
              }}
              style={{
                padding: 6,
              }}
            >
              <Delete />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteItemRow;
