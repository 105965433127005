import { CloudUpload } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC } from "react";
import { parse } from "csv-parse/browser/esm";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { uploadCsvExpenses } from "../../redux/reducerSlices/expense";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type CsvExpenseEntity = {
  Amount: string;
  Contract: string;
  Date: string;
  "Hourly rate": string;
  Hours: string;
  Memo: string;
  Talent: string;
};

export const ImportExpensesModal: FC<Props> = ({ onClose, open }) => {
  const dispatch = useDispatch();

  const [file, setFile] = React.useState<File | null>(null);
  const [csvExpenses, setCsvExpenses] = React.useState<CsvExpenseEntity[]>([]);

  const handleClear = () => {
    setFile(null);
    setCsvExpenses([]);
  };

  const handleImport = () => {
    dispatch(uploadCsvExpenses(csvExpenses));
    handleClear();
    onClose();
  };

  React.useEffect(() => {
    if (!file) return;

    const fr = new FileReader();

    const rows: string[][] = [];

    fr.onload = (e) => {
      const content = e.target.result.toString();
      const parser = parse(content);
      parser
        .on("data", (row) => rows.push(row))
        .on("end", () => {
          const headers = rows.shift();
          setCsvExpenses(
            rows.map((row) => {
              return row.reduce((acc, curr, idx) => {
                const header = headers[idx];
                acc[header] = curr;
                return acc;
              }, {} as CsvExpenseEntity);
            })
          );
        });
    };

    fr.readAsText(file);
  }, [file]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        {file ? (
          file.name
        ) : (
          <FormattedMessage
            id="expenses.ImportModalTitle"
            defaultMessage="Import expenses from CSV file"
          />
        )}
      </DialogTitle>
      <DialogContent>
        {!file && <FileInput onChange={(f) => setFile(f)} />}

        {!!csvExpenses.length && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(csvExpenses[0]).map((h) => (
                    <TableCell
                      sx={{
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        fontWeight: "600",
                      }}
                      key={h}
                    >
                      {h}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvExpenses.map((e, ridx) => (
                  <TableRow key={ridx}>
                    {Object.values(e).map((v, vidx) => (
                      <TableCell
                        sx={{ fontSize: 12, whiteSpace: "nowrap" }}
                        key={v + ridx + vidx}
                      >
                        {v}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      {!!csvExpenses.length && (
        <DialogActions>
          <Button onClick={handleClear}>
            <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
          </Button>
          <Button onClick={handleImport}>
            <FormattedMessage id="general.Import" defaultMessage="Import" />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const FileInput = ({ onChange }: { onChange: (f: File) => void }) => {
  return (
    <div
      style={{
        padding: 16,
        width: "100%",
        border: "1px dashed grey",
        position: "relative",
        height: 100,
        backgroundColor: "#fff",
        color: "grey",
        textAlign: "center",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CloudUpload />
      <span style={{ marginTop: 4, marginLeft: 5 }}>
        <FormattedMessage
          id="general.Click-or-drop-files"
          defaultMessage="Click or drop files in the box to upload them"
        />
      </span>
      <input
        type="file"
        accept=".csv"
        style={{
          width: "100%",
          height: 130,
          top: -30,
          left: 0,
          cursor: "pointer",
          position: "absolute",
        }}
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
    </div>
  );
};
