import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TableFooter, TablePagination } from "@mui/material";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormattedMessage, useIntl } from "react-intl";

import PurchaseOrderModal from "../components/PurchaseOrder/modal";
import {
  getPurchaseOrders,
  refreshPurchaseOrders,
} from "../redux/reducerSlices/purchase-order";
import PORow from "../components/PurchaseOrder/row";
import { PurchaseOrder } from "../components/PurchaseOrder/types";
import { getCompanies } from "../redux/reducerSlices/company";
import TablePaginationActions from "../components/TablePaginationActions";

const POTeble = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { purchaseOrders: tickets, pagination } = useSelector(
    (state: RootStateOrAny) => state.purchaseOrderState
  );

  useEffect(() => {
    dispatch(
      getPurchaseOrders({
        page: 1,
        pageSize: 10,
      })
    );
  }, [dispatch]);

  if (tickets.length <= 0) {
    return (
      <div>
        <FormattedMessage
          id="general.No-purchase-orders-found"
          defaultMessage="No purchase orders found"
        />
      </div>
    );
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(
      getPurchaseOrders({ page: newPage + 1, pageSize: pagination.pageSize })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      getPurchaseOrders({
        page: 1,
        pageSize: parseInt(event.target.value, 10),
      })
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 100,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Client" defaultMessage="Client" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage
                id="general.PO-Number"
                defaultMessage="PO Number"
              />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Summary" defaultMessage="Summary" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Amount" defaultMessage="Amount" />
            </TableCell>
            <TableCell align="left" colSpan={1}>
              <FormattedMessage
                id="general.Attachment"
                defaultMessage="Attachment"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets &&
            tickets.map((ticket) => (
              <PORow
                key={ticket.id}
                purchaseOrder={{
                  ...ticket,
                }}
                handleOpenEdinModal={props.handleOpenEdinModal}
              />
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={pagination.total}
              rowsPerPage={pagination.pageSize}
              page={pagination.page - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": intl.formatMessage({
                    id: "general.Per-page",
                    defaultMessage: "Per page",
                  }),
                },
                native: true,
              }}
              labelRowsPerPage={intl.formatMessage({
                id: "general.Per-page",
                defaultMessage: "Per page",
              })}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${intl.formatMessage({
                  id: "pagination.of",
                  defaultMessage: "of",
                })} ${count}`;
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const PurchaseOrders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies(1, 100));
  }, [dispatch]);

  const [openModal, setOpenModal] = useState(false);
  const [modalPurchseOrder, setModalPurchaseOrder] =
    useState<PurchaseOrder>(null);

  const openNewPurchaseOrderModal = () => {
    setModalPurchaseOrder(null);
    setOpenModal(true);
  };

  const handleOpenEdinModal = (ticket: any) => {
    setModalPurchaseOrder(ticket);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setModalPurchaseOrder(null);
    dispatch(refreshPurchaseOrders());
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage
              id="general.Purchase-Orders"
              defaultMessage="Purchase Orders"
            />
          </Typography>
          <Button onClick={openNewPurchaseOrderModal}>
            <FormattedMessage
              id="general.New-Purchase-Order"
              defaultMessage="New Purchase Order"
            />
          </Button>
        </Box>
        <PurchaseOrderModal
          openModal={openModal}
          setOpenModal={() => {
            setOpenModal(false);
            setModalPurchaseOrder(null);
          }}
          purchaseOrder={{
            ...modalPurchseOrder,
          }}
          onCloseModal={onCloseModal}
        />
        <POTeble handleOpenEdinModal={handleOpenEdinModal} />
      </Box>
    </Container>
  );
};

export default PurchaseOrders;
