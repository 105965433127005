import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Delete } from "@mui/icons-material";
import { useIntl, FormattedMessage } from "react-intl";

import ConfirmDialog from "../ConfirmDialog";
import { Task, TaskRow, Project } from "../task/types";
import { Release, ReleaseStatus } from "./types";
import {
  createRelease,
  deleteRelease,
  updateRelease,
} from "../../redux/reducerSlices/release";
import CreateTask from "../task/CreateTask";
import { sawTask } from "../../redux/reducerSlices/task";

const emptyRelease = {
  date: null,
  status: ReleaseStatus.draft,
  notes: "",
  project: {
    id: 0,
  },
} as Release;

interface ProjectsMap {
  [key: string]: Project;
}

const ReleaseModal: React.FC<{
  extRelease?: Release | null;
  setOpenModal: any;
  openModal: boolean;
  project?: Project | null;
  task?: TaskRow | null;
  onCreateCB?: () => void;
  onSubmitCB?: () => void;
}> = ({
  extRelease,
  setOpenModal,
  openModal,
  project,
  task,
  onCreateCB,
  onSubmitCB,
}) => {
  const isEditingRelease = Boolean(extRelease?.id);
  const hasTask = Boolean(task?.id);

  const {
    project: { projectOptions },
    user,
  } = useSelector((state: RootStateOrAny) => state);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [release, setRelease] = useState<Release>(emptyRelease);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isDeployedConfirmModal, setIsDeployedConfirmModal] = useState<boolean>(false);
  const [projectsMap, setProjectsMap] = useState<ProjectsMap>({});
  const [projectErr, setProjectErr] = useState<boolean>(false);
  const [modalTask, setModalTask] = useState<Task>(null);
  const [openTaskModal, setOpenTaskModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [stagingTasks, setStagingTasks] = useState<Task[]>([]);

  const handleSetModalTask = (task: Task) => {
    setModalTask(task);

    setSelectedTasks(
      [...selectedTasks].map((st) => {
        if (st.id === task.id) st.seen_by = [{ id: user?.id }];

        return st;
      })
    );
  };

  useEffect(() => {
    if (!openModal) return null;

    if (extRelease) {
      let date = null;

      if (extRelease.date) {
        date = new Date(extRelease.date);
      }

      setRelease({
        ...extRelease,
        date,
      });
      setSelectedTasks([...extRelease.tasks]);

      if (
        extRelease?.project?.id &&
        extRelease?.status !== ReleaseStatus.released
      ) {
        fetchProjectStagingTasks(extRelease.project);
      }
    }

    if (hasTask) {
      const setAddTaskToModal = async () => {
        const tasks = await fetchProjectStagingTasks(task.project);
        const newProject = { ...task.project };

        setRelease((release) => ({
          ...release,
          project: newProject,
        }));

        const isInStagingTasks: Task = tasks.find((t) => {
          return t.id === parseInt(String(task.id));
        });

        if (isInStagingTasks) {
          setSelectedTasks([isInStagingTasks]);
        } else {
          setSelectedTasks([task]);
        }
      };

      setAddTaskToModal();
    }
  }, [extRelease, setRelease, hasTask, task, openModal]);

  useEffect(() => {
    const getProjects = async () => {
      const cMap: ProjectsMap = {};

      projectOptions.forEach((project: Project) => {
        cMap[project.id] = project;
      });

      setProjectsMap(cMap);
    };

    getProjects();
  }, [projectOptions]);

  useEffect(() => {
    if (project) {
      setRelease((release) => ({
        ...release,
        project,
      }));
    }
  }, [project]);

  const fetchProjectStagingTasks = async (project: Project) => {
    const tasks: Task[] = (
      await axios.get(`/projects/${project.id}/staging-tasks`)
    ).data;

    tasks.forEach((task) => {
      task.project = project;
    })

    setStagingTasks(tasks);

    return tasks;
  };

  const closeModal = () => {
    setOpenModal(false);

    setTimeout(() => {
      setSelectedTasks([]);
      setRelease(emptyRelease);
    }, 200);
  };

  const handleDelete = async (release: Release) => {
    await dispatch(deleteRelease(release.id));
    setOpenConfirmModal(false);
    setOpenModal(false);

    if (onSubmitCB) onSubmitCB();
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setRelease({
        ...release,
        [event.target.name]: event.target.value,
      });
    } else {
      setRelease({
        ...release,
        [name]: event.toString(),
      });
    }
  };

  const validateRelease = () => {
    if (!release.project?.id) {
      setProjectErr(true);

      return false;
    }

    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateRelease()) {
      return null;
    }

    setLoading(true);

    if (isEditingRelease) {
      await dispatch(
        updateRelease({
          data: {
            ...release,
            tasks: selectedTasks,
          },
        })
      );
    } else {
      const newRelease = {
        ...release,
        tasks: selectedTasks,
      };

      delete newRelease.id;

      await dispatch(
        createRelease({
          release: newRelease,
        })
      );

      if (onCreateCB) {
        onCreateCB();
      }
    }

    if (onSubmitCB) onSubmitCB();

    setLoading(false);
    closeModal();
  };

  const getModalTitle = () => {
    return isEditingRelease ? (
      <FormattedMessage
        id="release-modal.Edit-Release"
        defaultMessage="Edit Release"
      />
    ) : (
      <FormattedMessage
        id="release-modal.New-Release"
        defaultMessage="New Release"
      />
    );
  };

  const handleDeleteTaskCallback = (task: Task) => {
    const newTasks = [...selectedTasks].filter((st) => {
      if (st.id !== task.id) return true;

      return false;
    });

    setSelectedTasks(newTasks);
  };

  const removeTask = (task: Task) => {
    setSelectedTasks([...selectedTasks].filter((st) => st.id !== task.id));
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "deployed":
        return (
          <FormattedMessage
            id="general-status.deployed"
            defaultMessage="deployed"
          />
        );
      case "toRedeploy":
        return (
          <FormattedMessage
            id="general-status.toRedeploy"
            defaultMessage="to redeploy"
          />
        );
      case "released":
        return (
          <FormattedMessage
            id="general-status.released"
            defaultMessage="released"
          />
        );
      case "canceled":
        return (
          <FormattedMessage
            id="general-status.canceled"
            defaultMessage="canceled"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              <FormControl
                variant="outlined"
                sx={{ marginTop: "10px" }}
                fullWidth
              >
                <InputLabel id="project-label">
                  <FormattedMessage
                    id="general.Project"
                    defaultMessage="Project"
                  />
                </InputLabel>
                <Select
                  labelId="project-label"
                  label={intl.formatMessage({
                    id: "general.Project",
                    defaultMessage: "Project",
                  })}
                  name="project"
                  error={projectErr}
                  disabled={Boolean(
                    extRelease?.status === "released" ||
                      extRelease?.status === "canceled"
                  )}
                  onChange={async (event) => {
                    const project = projectsMap[event.target.value];

                    if (project) {
                      const tasks = await fetchProjectStagingTasks(project);

                      setSelectedTasks(tasks);
                      setProjectErr(false);
                      setRelease({
                        ...release,
                        project,
                      });
                    }
                  }}
                  value={release.project?.id || 0}
                >
                  <MenuItem value={0}>
                    <FormattedMessage
                      id="general.Select-project"
                      defaultMessage="Select project"
                    />
                  </MenuItem>
                  {projectOptions.map((project) => {
                    return (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {projectErr && (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <FormattedMessage
                      id="general.Project-is-required"
                      defaultMessage="Project is required"
                    />
                  </FormHelperText>
                )}
              </FormControl>

              {Boolean(stagingTasks.length) && (
                <FormControl
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  fullWidth
                >
                  <Autocomplete
                    multiple
                    id="select-staging-tasks"
                    disableCloseOnSelect={true}
                    options={stagingTasks}
                    getOptionLabel={(task) => {
                      return task.title;
                    }}
                    value={selectedTasks}
                    onChange={(event: any, newValue) => {
                      if (event.key === "Backspace") return null;

                      const newTasks: Task[] = [];

                      newValue.forEach((t) => {
                        if (typeof t === "object") {
                          newTasks.push(t);
                        }
                      });

                      setSelectedTasks(newTasks);
                    }}
                    renderTags={() => null}
                    isOptionEqualToValue={(option: Task, value: Task) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({
                          id: "general.Add-tasks",
                          defaultMessage: "Add tasks",
                        })}
                        placeholder={intl.formatMessage({
                          id: "general.Filter-tasks-by-title",
                          defaultMessage: "Filter tasks by title",
                        })}
                      />
                    )}
                  />
                </FormControl>
              )}
              <div style={{ paddingLeft: 5, paddingTop: 5 }}>
                {selectedTasks?.map((task) => {
                  return (
                    <div
                      key={task.id}
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 5,
                      }}
                    >
                      <div
                        className="quote-item-title"
                        style={{
                          width: "auto",
                          position: "relative",
                          paddingRight: 10,
                        }}
                        onClick={() => {
                          handleSetModalTask(task);
                          setOpenTaskModal(true);
                          dispatch(sawTask(task.id));
                        }}
                      >
                        {task.title}
                        {!task.seen_by.length && (
                          <span
                            style={{
                              position: "absolute",
                              width: 7,
                              height: 7,
                              borderRadius: "50%",
                              background: "#DE350B",
                              top: 0,
                              right: 3,
                            }}
                          >
                            {/* empty */}
                          </span>
                        )}
                      </div>

                      <div>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            removeTask(task);
                          }}
                          style={{
                            padding: 6,
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
              {release.status === "released" && (
                <FormControl
                  variant="outlined"
                  sx={{ marginTop: "5px" }}
                  fullWidth
                >
                  <TextField
                    id="date"
                    label={intl.formatMessage({
                      id: "general.Date",
                      defaultMessage: "Date",
                    })}
                    type="date"
                    fullWidth
                    value={release.date?.toISOString().slice(0, 10) || ""}
                    onChange={(event) => {
                      if (event.target.value) {
                        setRelease({
                          ...release,
                          date: new Date(event.target.value),
                        });
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              )}
              <TextField
                multiline
                rows={2}
                style={{ width: "100%" }}
                margin="dense"
                id="notes"
                name="notes"
                value={release.notes}
                label={intl.formatMessage({
                  id: "general.Release-notes",
                  defaultMessage: "Release notes",
                })}
                type="text"
                fullWidth
                variant="outlined"
                onChange={(event) => handleOnChangeFormField(event)}
              />
              <FormControl
                variant="outlined"
                sx={{ marginTop: "5px" }}
                fullWidth
              >
                <InputLabel id="status-label">
                  <FormattedMessage
                    id="general.Status"
                    defaultMessage="Status"
                  />
                </InputLabel>
                <Select
                  labelId="status-label"
                  label={intl.formatMessage({
                    id: "general.Status",
                    defaultMessage: "Status",
                  })}
                  name="status"
                  onChange={(event) => {
                    if (event.target.value === "released") {
                      setRelease({
                        ...release,
                        status: ReleaseStatus.released,
                        date: new Date(),
                      });

                      return null;
                    }

                    if (event.target.value === "deployed") {
                      setIsDeployedConfirmModal(true);
                      return null;
                    }

                    handleOnChangeFormField(event);
                  }}
                  value={release.status.toString()}
                >
                  {Object.keys(ReleaseStatus)
                    .filter((q) => isNaN(Number(q)))
                    .map((status, i) => {
                      return (
                        <MenuItem key={status + i} value={status}>
                          {renderStatus(status)}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditingRelease && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    <FormattedMessage
                      id="general.Delete-Release"
                      defaultMessage="Delete Release"
                    />
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit">
                  {isEditingRelease ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Create"
                      defaultMessage="Create"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={isDeployedConfirmModal}
        setOpenModal={setIsDeployedConfirmModal}
        confirmHandler={() => {
          setRelease({
            ...release,
            status: ReleaseStatus.deployed,
          })
          setIsDeployedConfirmModal(false);
        }}
        text={intl.formatMessage({
            id: "release-modal.confirm-change-status-to-deploy",
            defaultMessage: `This project has ${stagingTasks.length - selectedTasks.length} tasks that are ready for release but were not included in this release. Continue anyway?`,
          }, {
            unselected_tasks_length: stagingTasks.length - selectedTasks.length
          })}
        confirmLabelId="general.Continue"
      />
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditingRelease) {
            handleDelete(release);
          }
        }}
        text={intl.formatMessage({
          id: "release-modal.Do-you-really-want-to-delete-release",
          defaultMessage: "Do you really want to delete the release?",
        })}
      />
      <CreateTask
        openModal={openTaskModal}
        setOpenModal={setOpenTaskModal}
        task={modalTask}
        projects={projectOptions}
        deleteCallback={handleDeleteTaskCallback}
        updateCallback={(task) => {
          const newStagingTasks = [...stagingTasks].map((sTask) => {
            if (sTask.id === task.id) {
              return task;
            }

            return sTask;
          });

          setStagingTasks(newStagingTasks);

          const newSelectedTasks = [...selectedTasks].map((sTask) => {
            if (sTask.id === task.id) {
              return task;
            }

            return sTask;
          });

          setSelectedTasks(newSelectedTasks);
        }}
      />
    </div>
  );
};

export default ReleaseModal;
