import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

const statusColors = {
  open: "green",
  closed: "red",
};

const Row: React.FC<{
  ticket: {
    title?: String;
    status: String;
    id?: String;
    handleOpenEdinModal: any;
    trello_url: string;
    project: any | null;
  };
}> = ({ ticket }, props) => {
  const { title, status, handleOpenEdinModal } = ticket;
  const handleEditTicket = () => {
    handleOpenEdinModal(ticket);
  };

  const renderStatus = () => {
    if (status === "open") {
      return <FormattedMessage id="general.Open" defaultMessage="Open" />;
    }

    return <FormattedMessage id="general.Closed" defaultMessage="Closed" />;
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditTicket();
      }}
    >
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="left">
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              border: "1px solid",
              borderColor: statusColors[String(ticket.status)],
              bgcolor: statusColors[String(ticket.status)],
              borderRadius: 1,
              color: "#fff",
              textTransform: "uppercase",
              fontWeight: "bold",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            {renderStatus()}
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          {ticket.project?.name}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Row;
