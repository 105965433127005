import axios from "axios";
import QueryString from "qs";
import { toast } from "react-hot-toast";
import { getIntl } from "../../GlobalIntlProvider";
import { getOrganizations } from "./organization";
import { refreshMe } from "./me";

const initialState = {
  all: [],
  developers: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
  requests: [],
};

const InviteReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "INVITES/GET/ALL":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getInvites = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      inviteState: { pagination },
    } = getState();

    const query = QueryString.stringify({
      pagination: {
        page: page ? page : pagination.page,
        pageSize: pageSize ? pageSize : pagination.pageSize,
      },
    });

    try {
      const res = await axios.get("/user-organization-invites?" + query);
      const { results: all, pagination } = res.data;

      dispatch({
        type: "INVITES/GET/ALL",
        payload: {
          all,
          pagination,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-get-users",
            default: "Could not get users. Reason: {error}",
            description: "Error message when getting users fails",
          },
          { error: String(err) }
        )
      );
    }
  };
};

export const getDeveloperInvites = () => {
  return async (dispatch: any) => {
    try {
      const res = await axios.get("/user-organization-invites/devs");

      dispatch({
        type: "INVITES/GET/ALL",
        payload: {
          developers: res.data,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-get-users",
            default: "Could not get users. Reason: {error}",
            description: "Error message when getting users fails",
          },
          { error: String(err) }
        )
      );
    }
  };
};

export const getInviteRequests = () => {
  return async (dispatch: any) => {
    try {
      const res = await axios.get("/user-organization-invites/requests");
      const requests = res.data;

      dispatch({
        type: "INVITES/GET/ALL",
        payload: {
          requests,
        },
      });
    } catch (err) {
      console.error(err);

      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-get-invite-requests",
            default: "Could not get invite requests. Reason: {error}",
          },
          { error: String(err) }
        )
      );
    }
  };
};

export const acceptInvite = (invite: any) => {
  return async (dispatch: any) => {
    try {
      await axios.post(`/user-organization-invites/${invite.id}/accept`);

      dispatch(getInviteRequests());
      dispatch(getOrganizations());
      dispatch(refreshMe());

      toast.success(
        getIntl().formatMessage(
          {
            id: "invite.You-have-joined-organization",
            default: "You have joined organization {organizationName}!",
          },
          { organizationName: invite.organization.name }
        )
      );
    } catch (err: any) {
      console.error(err);

      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-accept-invite",
            default: "Could not accept invite. Reason: {error}",
          },
          { error: String(err.response?.error?.message || err) }
        )
      );
    }
  };
};

export const rejectInvite = (invite: any) => {
  return async (dispatch: any) => {
    try {
      await axios.post(`/user-organization-invites/${invite.id}/reject`);

      dispatch(getInviteRequests());

      toast.success(
        getIntl().formatMessage(
          {
            id: "invite.You-have-rejected-invite",
            default: "You have rejected the invite from {organizationName}!",
          },
          { organizationName: invite.organization.name }
        )
      );
    } catch (err) {
      console.error(err);

      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-reject-invite",
            default: "Could not reject invite. Reason: {error}",
          },
          { error: String(err) }
        )
      );
    }
  };
};

export const createInvite = (invite: any) => {
  return async (dispatch: any) => {
    try {
      await axios.post("/user-organization-invites", invite);

      dispatch(getInvites());
    } catch (err: any) {
      console.error(err);

      if (err?.response?.data?.error?.message === "Invite already exists") {
        toast.error(
          getIntl().formatMessage(
            {
              id: "invite.User-already-invited",
              default:
                "The user {email} has already been invited to this organization",
            },
            { email: invite.email }
          )
        );

        return;
      } else if (
        err?.response?.data?.error?.message === "Email does not exist"
      ) {
        toast.error(
          getIntl().formatMessage(
            {
              id: "invite.Could-not-find-user",
              default: "Could not find user with email {email}",
            },
            { email: invite.email }
          )
        );
        return;
      } else if (
        err?.response?.data?.error?.message === "You can't invite yourself"
      ) {
        toast.error(
          getIntl().formatMessage({
            id: "invite.You-cant-invite-yourself",
            default: "You can't invite yourself",
          })
        );
        return;
      }

      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-create-invite",
            default: "Could not create invite. Reason: {error}",
          },
          { error: err?.response?.data?.error?.message || String(err) }
        )
      );

      dispatch(getInvites());
    }
  };
};

export const deleteInvite = (inviteId: number) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/user-organization-invites/${inviteId}`);

      dispatch(getInvites());
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-delete-invite",
            default: "Could not delete invite. Reason: {error}",
          },
          { error: String(err) }
        )
      );
    }
  };
};

export const updateInvite = (invite: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/user-organization-invites/${invite.id}`, invite);

      dispatch(getInvites());
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "invite.Could-not-update-invite",
            default: "Could not update invite. Reason: {error}",
          },
          { error: String(err) }
        )
      );
    }
  };
};

export default InviteReducer;
