import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { acceptInvite, rejectInvite } from "../../redux/reducerSlices/invite";

const InviteRow = ({ invite }) => {
  const dispatch = useDispatch();

  const handleRejectInvite = () => {
    dispatch(rejectInvite(invite));
  };

  const handleAcceptInvite = () => {
    dispatch(acceptInvite(invite));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <span>
        <FormattedMessage
          id="general.Organization"
          defaultMessage="Organization"
        />{" "}
        <strong>{invite.organization.name}</strong>
      </span>

      <span>
        <Button
          onClick={handleRejectInvite}
          color="error"
          style={{
            marginRight: 10,
          }}
        >
          <FormattedMessage id="general.Reject" defaultMessage="Reject" />
        </Button>
        <Button onClick={handleAcceptInvite} variant="outlined" color="success">
          <FormattedMessage id="general.Accept" defaultMessage="Accept" />
        </Button>
      </span>
    </div>
  );
};

const InvitesModal: React.FC<{
  setOpenModal: any;
  openModal: boolean;
}> = ({ setOpenModal, openModal }) => {
  const { inviteState } = useSelector((state: any) => state);

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>
          <FormattedMessage
            id="invite.You-have-been-invited-to-join"
            defaultMessage="You have been invited to join asdf"
          />
        </DialogTitle>
        <Box
          style={{
            width: 600,
            maxWidth: "100%",
          }}
        >
          <DialogContent>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item xs={12}>
                {inviteState.requests.map((invite: any) => {
                  return <InviteRow key={invite.id} invite={invite} />;
                })}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0px 24px 20px",
            }}
          >
            <div>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  closeModal();
                }}
              >
                <FormattedMessage id="general.Close" defaultMessage="Close" />
              </Button>
            </div>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default InvitesModal;
