import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TableFooter, TablePagination } from "@mui/material";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormattedMessage, useIntl } from "react-intl";

import InvoiceModal from "../components/invoice/modal";
import { getProjectOptions } from "../redux/reducerSlices/project";
import { getInvoices } from "../redux/reducerSlices/invoice";
import InvoiceRow from "../components/invoice/row";
import { Invoice } from "../components/task/types";
import { getPOsNotFullyInvoiced } from "../redux/reducerSlices/purchase-order";
import { getCompanies } from "../redux/reducerSlices/company";
import TablePaginationActions from "../components/TablePaginationActions";

const InvoicesTable = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { invoices, pagination } = useSelector(
    (state: RootStateOrAny) => state.invoices
  );

  useEffect(() => {
    dispatch(getInvoices());
    dispatch(getCompanies(1, 100));
  }, [dispatch]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getInvoices(newPage + 1, pagination.pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getInvoices(1, parseInt(event.target.value, 10)));
  };

  if (invoices.length <= 0) {
    return (
      <div>
        <FormattedMessage
          id="invoices-page.No-invoices-found"
          defaultMessage="No invoices found."
        />
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 100,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Number" defaultMessage="Number" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Client" defaultMessage="Client" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Status" defaultMessage="Status" />
            </TableCell>
            <TableCell align="left" colSpan={1}>
              <FormattedMessage id="general.Date" defaultMessage="Date" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices &&
            invoices.map((invoice) => (
              <InvoiceRow
                key={invoice.id}
                invoice={{
                  ...invoice,
                  handleOpenEdinModal: props.handleOpenEdinModal,
                }}
              />
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={pagination.total}
              rowsPerPage={pagination.pageSize}
              page={pagination.page - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": intl.formatMessage({
                    id: "general.Invoices-per-page",
                    defaultMessage: "Invoices per page",
                  }),
                },
                native: true,
              }}
              labelRowsPerPage={intl.formatMessage({
                id: "general.Invoices-per-page",
                defaultMessage: "Invoices per page",
              })}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${intl.formatMessage({
                  id: "pagination.of",
                  defaultMessage: "of",
                })} ${count}`;
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const Invoices = () => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [modalInvoice, setModalInvoice] = useState<Invoice | null>(null);

  useEffect(() => {
    dispatch(getProjectOptions());
    dispatch(getPOsNotFullyInvoiced());
  }, [dispatch]);

  const openNewInvoiceModal = () => {
    setModalInvoice(null);
    setOpenModal(true);
  };

  const handleOpenEdinModal = (invoice: any) => {
    setModalInvoice(invoice);
    setOpenModal(true);
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Invoices" defaultMessage="Invoices" />
          </Typography>
          <Button onClick={openNewInvoiceModal}>
            <FormattedMessage
              id="invoices-page.New-Invoice"
              defaultMessage="New Invoice"
            />
          </Button>
        </Box>
        <InvoiceModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          extInvoice={modalInvoice}
        />
        <InvoicesTable handleOpenEdinModal={handleOpenEdinModal} />
      </Box>
    </Container>
  );
};

export default Invoices;
