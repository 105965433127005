import React from 'react';
import { Page, View, Text, Image } from '@react-pdf/renderer';
import { styles } from '../styles/quotePdfStyles';
import { BackgroundSymbol } from './BackgroundSymbol';
import { Quote } from '../../../components/task/types';
import logo from '../../../assets/img/logo-for-pdf.png';
import { PartiesSection } from './sections/PartiesSection';
import { ContextSection } from './sections/ContextSection';
import { FunctionalitiesSection } from './sections/FunctionalitiesSection';
import { PriceSection } from './sections/PriceSection';
import { PaymentTermsSection } from './sections/PaymentTermsSection';
import { DelaySection } from './sections/DelaySection';
import { IntellectualPropertySection } from './sections/IntellectualPropertySection';
import { ValiditySection } from './sections/ValiditySection';
import { useIntl } from 'react-intl';

interface ContentPageProps {
  quote: Quote | null;
  contactName?: string | null;
}

const formatUrl = (url: string): string => {
  try {
    // Handle Strapi media URLs
    if (url.startsWith('/uploads/')) {
      return `${process.env.REACT_APP_HOST_URL}${url}`;
    }

    return url;
  } catch (error) {
    console.error('Error formatting URL:', error);
    return url;
  }
};

export const ContentPage: React.FC<ContentPageProps> = ({ quote }) => {
  const intl = useIntl();
  const clientLogoUrl = quote?.company?.invoice_to?.logo?.url;

  return (
    <Page size="A4" style={styles.page}>
      <BackgroundSymbol />
      <View fixed>
        <Text style={styles.pageHeader}>
          {intl.formatMessage(
            { id: 'quote.pdf.header' },
            { quote_number: quote?.number }
          )}
        </Text>
      </View>

      <View style={styles.contentWrapper}>
        <PartiesSection quote={quote} />
        <ContextSection quote={quote} />
        <FunctionalitiesSection quote={quote} />
        <PriceSection quote={quote} />
        <PaymentTermsSection quote={quote} />
        {quote?.delivery_time && <DelaySection quote={quote} />}
        <IntellectualPropertySection quote={quote} />
        <ValiditySection quote={quote} />
      </View>

      <View fixed>
        <Image src={logo} style={styles.footerLogo} />
        {clientLogoUrl && (
          <Image src={formatUrl(clientLogoUrl)} style={styles.footerClientLogo} />
        )}
        <Text style={styles.pageFooter} render={({ pageNumber }) => `${pageNumber}`} />
      </View>
    </Page>
  );
};
