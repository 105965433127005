import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import BasicInfoTab from "./Tabs/BasicInformation";
import PlansTab from "./Tabs/Plans";
import CardsTab from "./Tabs/Cards";
import TrelloTab from "./Tabs/Trello";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, pt: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const OrganizationsPage = (props: any) => {
  const intl = useIntl();
  const history = useHistory();

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ) => {
    if (newTabIndex === 0) {
      history.push("/profile/basic-information");
    } else if (newTabIndex === 1) {
      history.push("/profile/subscriptions");
    } else if (newTabIndex === 2) {
      history.push("/profile/cards");
    } else if (newTabIndex === 3) {
      history.push("/profile/trello");
    }
  };

  React.useEffect(() => {
    if (history.location.pathname === "/profile/basic-information") {
      setTabIndex(0);
    } else if (history.location.pathname === "/profile/subscriptions") {
      setTabIndex(1);
    } else if (history.location.pathname === "/profile/cards") {
      setTabIndex(2);
    } else if (history.location.pathname === "/profile/trello") {
      setTabIndex(3);
    }
  }, [history.location.pathname]);

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabIndex}
              onChange={handleTabChange}
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab
                style={{ alignItems: "flex-end" }}
                label={intl.formatMessage({
                  id: "general.Basic-Information",
                  defaultMessage: "Basic Information",
                })}
              />
              <Tab
                style={{ alignItems: "flex-end" }}
                label={intl.formatMessage({
                  id: "general.Plan",
                  defaultMessage: "Plan",
                })}
              />
              <Tab
                style={{ alignItems: "flex-end" }}
                label={intl.formatMessage({
                  id: "general.Payment-Methods",
                  defaultMessage: "Payment Methods",
                })}
              />
              <Tab
                style={{ alignItems: "flex-end" }}
                label={intl.formatMessage({
                  id: "general.Trello",
                  defaultMessage: "Trello",
                })}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <BasicInfoTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <PlansTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <CardsTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <TrelloTab />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default OrganizationsPage;
