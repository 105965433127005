import axios from "axios";
import toast from "react-hot-toast";
import qs from "qs";

import { TaskRow } from "../../components/task/types";
import { Release } from "../../components/release/types";
import { refreshTasks, updateTask } from "./task";
import { getIntl } from "../../GlobalIntlProvider";

const initialState = {
  releases: [],
  draftReleases: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
};

const releaseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "RELEASES/GETALL":
      return {
        ...state,
        ...action.payload,
      };
    case "RELEASES/CREATE":
      if (action.payload == null) return state;

      return {
        ...state,
        releases: action.payload,
      };
    case "RELEASES/REMOVE":
      return {
        ...state,
        releases: [...state.releases].filter(
          (release) => release.id !== action.payload.releaseID
        ),
      };
    case "RELEASES/EDIT":
      return {
        ...state,
        releases: [...state.releases].map((release) =>
          release.id === action.payload.release.id
            ? action.payload.release
            : release
        ),
      };
    default:
      return state;
  }
};

export const internalReleasesTaskSeenBy = (saw_task_id: any) => {
  return async (dispatch: any, getState: any) => {
    const {
      releases: { releases },
      user,
    } = getState();

    dispatch({
      type: "RELEASES/GETALL",
      payload: {
        releases: releases.map((release) => {
          return {
            ...release,
            tasks: [...release.tasks].map((t) => {
              if (t.id === saw_task_id) t.seen_by = [{ id: user.id }];

              return t;
            }),
          };
        }),
      },
    });
  };
};

export const getDraftReleases = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`/releases?filters[status]=draft`);
      const releases = response.data;

      dispatch({
        type: "RELEASES/GETALL",
        payload: {
          draftReleases: releases,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.draft-releases.fetch-error",
            defaultMessage: "Could not fetch draft releases. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const getReleases = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      releases: { pagination: statePagination },
    } = getState();

    const pagination = {
      page: page || statePagination.page,
      pageSize: pageSize || statePagination.pageSize,
    };

    const query = qs.stringify({
      pagination,
    });

    try {
      const response = await axios(`/releases?${query}`);
      const { results: releases, pagination } = response.data;

      dispatch({
        type: "RELEASES/GETALL",
        payload: {
          releases,
          pagination,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.releases.fetch-error",
            defaultMessage: "Could not fetch releases. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const fetchSingleReleaseWithouState = async (releaseId: string) => {
  const response = await axios(`/releases/${releaseId}`);

  return response.data;
};

export const createRelease = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { release } = payload;

      await axios.post(`/releases`, {
        data: release,
      });

      if (window.location.href.endsWith("/tasks")) {
        dispatch(refreshTasks());
        dispatch(getDraftReleases());
      } else {
        dispatch(getReleases());
      }
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.releases.create-error",
            defaultMessage: "Could not create release. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const updateRelease = (releaseDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/releases/${releaseDetails.data.id}`, {
        data: releaseDetails.data,
      });

      if (window.location.href.endsWith("/tasks")) {
        dispatch(refreshTasks());
        dispatch(getDraftReleases());
      } else {
        dispatch(getReleases());
      }

      toast.success(
        getIntl().formatMessage({
          id: "toast.releases.updated",
          defaultMessage: "Release updated",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.releases.update-error",
            defaultMessage: "Could not update release. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const deleteRelease = (releaseID: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/releases/${releaseID}`);

      dispatch({
        type: "RELEASES/REMOVE",
        payload: {
          releaseID,
        },
      });

      toast.success(
        getIntl().formatMessage({
          id: "toast.releases.deleted",
          defaultMessage: "Release deleted",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.releases.delete-error",
            defaultMessage: "Could not delete release. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const addTaskToRelease = (task: TaskRow, release: Release) => {
  return async (dispatch: any) => {
    try {
      await dispatch(
        updateTask({
          data: {
            id: task.id,
            release: release.id,
          },
        })
      );

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.added-to-release",
            defaultMessage: "{task_title} added to release #{release_id}",
          },
          {
            task_title: task.title,
            release_id: release.id,
          }
        )
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.task.add-to-release-error",
            defaultMessage:
              'Error adding task "{task_title}" to release #{release_id}. Reason: {error}',
          },
          {
            error: String(err),
            task_title: task.title,
            release_id: release.id,
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const removeTaskFromRelease = (task: TaskRow, release: Release) => {
  return async (dispatch: any) => {
    try {
      await dispatch(
        updateTask({
          data: {
            id: task.id,
            release: null,
          },
        })
      );

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.removed-from-release",
            defaultMessage: "{task_title} removed from release #{release_id}",
          },
          {
            task_title: task.title,
            release_id: release.id,
          }
        )
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.task.remove-from-release-error",
            defaultMessage:
              'Error removing task "{task_title}" to release #{release_id}. Reason: {error}',
          },
          {
            error: String(err),
            task_title: task.title,
            release_id: release.id,
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export default releaseReducer;
