import { Box, FormControlLabel, IconButton, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  deleteExpense,
  Expense,
  toggleExpensePublishedStatus,
} from "../../redux/reducerSlices/expense";
import React, { FC } from "react";
import { ExpenseModal } from "./ExpenseModal";
import { Delete, Edit } from "@mui/icons-material";
import { getIntl } from "../../GlobalIntlProvider";
import ConfirmDialog from "../../components/ConfirmDialog";

type Props = {
  expense: Expense;
};

export const ExpensesActionCell: FC<Props> = ({ expense }) => {
  const dispatch = useDispatch();

  const [openExpenseModal, setOpenExpenseModal] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

  return (
    <>
      <Box display="flex" justifyContent="end" alignItems="center" gap={1}>
        <FormControlLabel
          control={
            <Switch
              checked={!!expense.publishedAt}
              onChange={(e) => {
                const isPublished = e.target.checked;
                dispatch(
                  toggleExpensePublishedStatus(expense.id, !isPublished)
                );
              }}
            />
          }
          label={getIntl().formatMessage({
            id: "general.Published",
            defaultMessage: "Published",
          })}
        />
        {!expense.publishedAt && (
          <IconButton onClick={() => setOpenExpenseModal(true)}>
            <Edit />
          </IconButton>
        )}
        <IconButton onClick={() => setOpenConfirmModal(true)}>
          <Delete />
        </IconButton>
      </Box>

      <ExpenseModal
        open={openExpenseModal}
        onClose={() => setOpenExpenseModal(false)}
        expense={expense}
      />
      <ConfirmDialog
        text={getIntl().formatMessage({
          id: "general.DeleteExpenseConfirm",
          defaultMessage: "Do you really want to delete the expense?",
        })}
        confirmHandler={() => dispatch(deleteExpense(expense.id))}
        setOpenModal={(b) => setOpenConfirmModal(b)}
        openModal={openConfirmModal}
      />
    </>
  );
};
