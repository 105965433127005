import axios from "axios";
import QueryString from "qs";
import { toast } from "react-hot-toast";
import { getInvites } from "./invite";

const initialState = {
  companies: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
  loading: false,
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getUsers = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      companyState: { pagination },
    } = getState();

    const query = QueryString.stringify({
      pagination: {
        page: page ? page : pagination.page,
        pageSize: pageSize ? pageSize : pagination.pageSize,
      },
    });

    try {
      const res = await axios.get("/users?" + query);
      const { results: users, pagination } = res.data;

      dispatch({
        type: "GET_USERS",
        payload: {
          users,
          pagination,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not get users. Reason: ${String(err)}`);
    }
  };
};

export const createUser = (user: any) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.post("/users", user);

      dispatch({
        type: "CREATE_USER",
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not create user. Reason: ${String(err)}`);
    }
  };
};

export const updateUser = (user: any) => {
  return async (dispatch: any, getState: any) => {
    const { user: authUser } = getState();

    try {
      const res = await axios.put(`/users/${user.id}`, user);

      dispatch({
        type: "UPDATE_USER",
        payload: res.data,
      });

      if (authUser.id === user.id) {
        dispatch({
          type: "LOGIN_USER",
          payload: {
            language: user.language,
          },
        });
      }

      dispatch(getInvites());
    } catch (err) {
      console.error(err);
      toast.error(`Could not update user. Reason: ${String(err)}`);
    }
  };
};

export const deleteUser = (id: number) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/users/${id}`);

      dispatch({
        type: "DELETE_USER",
        payload: id,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not delete user. Reason: ${String(err)}`);
    }
  };
};

export default userReducer;
