import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Row: React.FC<{
  project: {
    id?: String;
    name: String;
    handleOpenEdinModal: any;
    trello_id: string;
    trello_url: string;
    trello_webhook_id: string;
    archived: boolean;
  };
}> = ({ project }, props) => {
  const { name, archived = false, handleOpenEdinModal } = project;
  const handleEditProject = () => {
    handleOpenEdinModal(project);
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditProject();
      }}
    >
      <TableCell component="th" scope="row">
        {name}{" "}
        {archived && (
          <span
            style={{
              color: "#999",
            }}
          >
            (archived)
          </span>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;
