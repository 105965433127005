import { Font } from '@react-pdf/renderer';
import MulishRegular from '../../../assets/fonts/Mulish-Regular.ttf';
import MulishBold from '../../../assets/fonts/Mulish-Bold.ttf';
import MulishItalic from '../../../assets/fonts/Mulish-Italic.ttf';

export const registerFonts = () => {
  Font.register({
    family: 'Mulish',
    fonts: [
      { src: MulishRegular },
      { src: MulishBold, fontWeight: 'bold' },
      { src: MulishItalic, fontStyle: 'italic' }
    ]
  });
};
