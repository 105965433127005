import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';
import { formatDeliveryTime } from '../../utils/helpers';

interface DelaySectionProps {
  quote: Quote | null;
}

export const DelaySection: React.FC<DelaySectionProps> = ({ quote }) => {
  const intl = useIntl();

  return (
    <View style={styles.section}>
      <View style={styles.sectionTitleGroup} wrap={false}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.delay' })}
        </Text>
        <Text style={styles.partyText}>
          {intl.formatMessage(
            { id: 'quote.pdf.deliveryText' },
            { time: formatDeliveryTime(quote?.delivery_time, intl) }
          )}
        </Text>
      </View>
    </View>
  );
};
