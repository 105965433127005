import { useIntl } from "react-intl";

let intl;

export function GlobalIntlProvider({ children }) {
  intl = useIntl();
  return children;
}

export function getIntl() {
  return intl;
}
