import * as enLocale from "./en.json";
import * as frLocale from "./fr.json";
import * as svLocale from "./sv.json";

const languages = {
  en: enLocale,
  fr: frLocale,
  sv: svLocale,
};

export const DEFAULT_LANGUAGE = 'en';

export default languages;
