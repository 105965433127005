import axios from "axios";
import QueryString from "qs";
import { toast } from "react-hot-toast";

const initialState = {
  companies: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
  loading: false,
};

const companyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "COMPANIES/GETALL":
      const { companies, pagination } = action.payload;
      return {
        ...state,
        companies,
        pagination,
      };
    case "COMPANIES/CREATE":
      if (action.payload == null) return state;
      return state.companies.concat(action.payload);
    case "COMPANIES/REMOVE":
      return state.companies.filter(
        (company) => company.id !== action.payload.companyID
      );
    case "COMPANIES/EDIT":
      const updatedCompany = action.payload.company;
      return state.companies.map((company) =>
        company.id === updatedCompany.id ? updatedCompany : company
      );
    case "COMPANIES/EDIT/FILES":
      const updatedCompanyFiles = action.payload.company;
      return state.companies.map((company) => {
        if (company.id === updatedCompanyFiles.id) {
          return {
            ...company,
            attachments: updatedCompanyFiles.attachments,
          };
        }

        return company;
      });
    case "COMPANIY/GET/TASKS":
      const { tasks, companyId } = action.payload;

      return {
        ...state,
        companies: state.companies.map((company) => {
          if (company.id === companyId) {
            return {
              ...company,
              tasks,
            };
          }

          return company;
        }),
      };
    case "COMPANIY/GET/SUBSCRIPTIONS/ITEMS":
      const { subscription_items, companyId: subCompanyId } = action.payload;

      return {
        ...state,
        companies: state.companies.map((company) => {
          if (company.id === subCompanyId) {
            return {
              ...company,
              subscription_items,
            };
          }

          return company;
        }),
      };
    case "COMPANIY/TASKS/ADD": {
      const { task, companyId } = action.payload;

      return {
        ...state,
        companies: state.companies.map((company) => {
          if (company.id === companyId) {
            const newTasks = [...(company?.tasks || []), task];

            return {
              ...company,
              tasks: newTasks,
            };
          }

          return company;
        }),
      };
    }
    default:
      return state;
  }
};

export const getCompanies = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      companyState: { pagination },
    } = getState();

    const query = QueryString.stringify({
      pagination: {
        page: page ? page : pagination.page,
        pageSize: pageSize ? pageSize : pagination.pageSize,
      },
      populate: {
        invoice_to: {
          fields: ["id", "name"],
        },
        logo: {
          fields: ["id", "url", "mime", "name"],
        },
      },
    });

    try {
      const response = await axios(`/companies?${query}`);

      const companies = response.data.data.map((c: any) => {
        return {
          id: c.id,
          ...c.attributes,
          invoice_to: c.attributes.invoice_to.data
            ? {
                id: c.attributes.invoice_to.data.id,
                name: c.attributes.invoice_to.data.attributes.name,
              }
            : null,
          logo: c.attributes.logo.data
            ? {
                id: c.attributes.logo.data.id,
                url: c.attributes.logo.data.attributes.url,
                mime: c.attributes.logo.data.attributes.mime,
                name: c.attributes.logo.data.attributes.name,
              }
            : null,
        };
      });

      dispatch({
        type: "COMPANIES/GETALL",
        payload: {
          companies,
          pagination: response.data.meta.pagination,
        },
      });
    } catch (err) {
      dispatch({
        type: "COMPANIES/GETALL",
        payload: null,
        error: err,
      });
    }
  };
};

export const createCompany = (companyDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.post(`/companies`, {
        data: companyDetails,
      });

      dispatch(getCompanies());
    } catch (err) {
      console.error(err);
      dispatch({
        type: "COMPANIES/CREATE",
        payload: null,
        error: err,
      });
    }
  };
};

export const deleteCompany = (companyID: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/companies/${companyID}`);

      dispatch(getCompanies());
    } catch (err) {
      dispatch({
        type: "COMPANIES/REMOVE",
        payload: null,
        error: err,
      });
    }
  };
};

export const updateCompany = (companyDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/companies/${companyDetails.id}`, {
        data: companyDetails,
      });

      dispatch(getCompanies());
    } catch (err) {
      console.error(err);
    }
  };
};

export const getCompanyTasks = (companyId: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`/companies/${companyId}/tasks`);
      const tasks = response.data;

      dispatch({
        type: "COMPANIY/GET/TASKS",
        payload: {
          tasks,
          companyId,
        },
      });
    } catch (err: any) {
      console.error(err);

      toast.error(err.response?.data?.message || err.message || err);
    }
  };
};

export const getCompanySubscriptionsItems = (companyId: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(
        `/companies/${companyId}/subscriptions/items`
      );
      const subscription_items = response.data;

      dispatch({
        type: "COMPANIY/GET/SUBSCRIPTIONS/ITEMS",
        payload: {
          subscription_items,
          companyId,
        },
      });
    } catch (err: any) {
      console.error(err);

      toast.error(err.response?.data?.message || err.message || err);
    }
  };
};

export const addTaskToCompany = ({ companyId, task }) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "COMPANIY/TASKS/ADD",
        payload: {
          task,
          companyId,
        },
      });
    } catch (err: any) {
      console.error(err);

      toast.error(err.response?.data?.message || err.message || err);
    }
  };
};

export default companyReducer;
