import { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "query-string";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";

const backendUrl = process.env.REACT_APP_baseUrl;

const LoginRedirect = (props) => {
  const [text, setText] = useState("");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const authorize = useCallback(() => {
    const parsedSerachQuery = parse(location.search);
    if (parsedSerachQuery && parsedSerachQuery.error) {
      setText(
        intl.formatMessage({
          id: "login.virify-you-email",
          defaultMessage: "Please verify your email first before login",
        })
      );
      return null;
    }

    fetch(`${backendUrl}/auth/auth0/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((loginRes) => {
        const jwt = loginRes.jwt;

        fetch(`${backendUrl}/users/me`, {
          headers: {
            Authorization: "Bearer " + jwt,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((meRes) => {
            loginRes.role = meRes.role;

            dispatch({
              type: "LOGIN_USER",
              payload: {
                ...meRes,
                jwt,
              },
            });

            setText(
              intl.formatMessage({
                id: "login.You-have-been-logged-in",
                defaultMessage:
                  "You have been successfully logged in. You will be redirected in a few seconds.",
              })
            );
            setTimeout(() => {
              if (meRes?.selectedOrg?.role === "Admin") {
                history.push("/dashboard");
              } else if (meRes?.selectedOrg?.role === "Developer") {
                history.push("/dashboard");
              } else {
                history.push("/organizations");
              }
            }, 1000);
          });
      })
      .catch((err) => {
        console.error("err", err);
        setText(
          intl.formatMessage({
            id: "login.error-occured",
            defaultMessage: "An error occurred, please try again later.",
          })
        );
      });
  }, [dispatch, history, location.search, intl]);

  useEffect(() => {
    authorize();
  }, [authorize]);

  return (
    <>
      <div
        style={{
          backgroundColor: "darkgrey",
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Boolean(text) ? (
          <div style={{ display: "block", margin: "auto" }}>
            <h2 style={{ fontStyle: "oblique", lineHeight: "5rem" }}>{text}</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  if (props.logout) {
                    props.logout();
                  } else {
                    console.error("props.logout undefined", props);
                  }
                }}
              >
                <FormattedMessage
                  id="general.Go-Back"
                  defaultMessage="Go Back"
                />
              </button>
            </div>
          </div>
        ) : (
          <CircularProgress color="primary" />
        )}
      </div>
    </>
  );
};

export default LoginRedirect;
