import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { CloudUpload } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";

import {
  createTicket,
  deleteTicket,
  updateTicket,
  updateTicketFiles,
} from "../../redux/reducerSlices/ticket";
import Attachment from "../Attachment";
import ConfirmDialog from "../ConfirmDialog";

const Modal: React.FC<{
  ticket?: any;
  setOpenModal: any;
  openModal: boolean;
  projects: [any];
  onCloseModal: () => void;
}> = ({
  ticket: currTicket,
  setOpenModal,
  openModal,
  projects,
  onCloseModal,
}) => {
  const isEdit = Boolean(currTicket.id);

  const emptyTicket = {
    title: "",
    description: "",
    status: "open",
    project: {
      name: "",
    },
  };

  const dispatch = useDispatch();
  const intl = useIntl();

  const [ticket, setTicket] = useState(emptyTicket);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [extFiles, setExtFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currTicket.id) {
      setTicket(currTicket);
      setExtFiles(currTicket.attachments || []);
    }
  }, [currTicket, setExtFiles]);

  const uploadFiles = async (upFiles) => {
    const formData = new FormData();

    upFiles.forEach((file) => {
      formData.append("files", file);
    });

    const response = await axios.post("/upload", formData);

    return response.data;
  };

  const getFilesIds = async (files: any) => {
    const filesIds = [...extFiles].map((file) => file.id);

    if (files.length) {
      const uptFiles = await uploadFiles(files);

      uptFiles.forEach((upld) => {
        filesIds.push(upld.id);
      });

      setExtFiles([...extFiles, ...uptFiles]);
    }

    return filesIds;
  };

  const updateFiles = async (files: any) => {
    setLoading(true);

    const ticketToSave = {
      data: {
        id: currTicket.id,
        attachments: await getFilesIds(files),
      },
    };

    dispatch(updateTicketFiles(ticketToSave));
    setLoading(false);
  };

  const handleExtFileDelete = async (file) => {
    try {
      await axios.delete(`/upload/files/${file.id}`);
    } catch (e) {
      console.error("e", e);
    }

    const filesCopy = [...extFiles].filter((f) => f.id !== file.id);

    setExtFiles(filesCopy);
  };

  const handleDelete = (ticketId: any) => {
    dispatch(deleteTicket(ticketId));
    setOpenConfirmModal(false);
    setOpenModal(false);
  };

  const handleOnChangeFormField = (event, name = null) => {
    if (name === null) {
      setTicket({ ...ticket, [event.target.name]: event.target.value });
    } else {
      setTicket({ ...ticket, [name]: event.toString() });
    }
  };

  const validateTicket = () => {
    if (!ticket.title) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!validateTicket()) {
      setShowFormErrors(true);
      return null;
    }

    setLoading(true);

    setTicket({ ...ticket });

    const ticketToSave = {
      data: {
        ...ticket,
        attachments: await getFilesIds(newFiles),
      },
    };

    if (isEdit) {
      dispatch(updateTicket(ticketToSave));
    } else {
      dispatch(createTicket(ticketToSave));
    }

    resetState();
  };

  const resetState = () => {
    setShowFormErrors(false);
    setTicket(emptyTicket);
    setOpenModal(false);
    setNewFiles([]);
    setExtFiles([]);
    setLoading(false);
  };

  const renderFiles = () => {
    return (
      <FormControl variant="outlined" sx={{ marginTop: "10px" }} fullWidth>
        <div>
          {newFiles.map((file, index) => {
            return (
              <Attachment
                key={index}
                mime={file.type}
                name={file.name}
                src={URL.createObjectURL(file)}
                deleteCallback={async () => {
                  const filesCopy = [...newFiles];
                  filesCopy.splice(index, 1);
                  setNewFiles(filesCopy);
                }}
              />
            );
          })}

          {extFiles.map((file, index) => {
            return (
              <Attachment
                key={index}
                mime={file.mime}
                name={file.name}
                src={(process.env.REACT_APP_FILES_URI || "") + file.url}
                deleteCallback={async () => {
                  setLoading(true);
                  await handleExtFileDelete(file);
                  setLoading(false);
                }}
              />
            );
          })}
        </div>
        <div
          style={{
            width: "100%",
            border: "1px dashed grey",
            position: "relative",
            height: 100,
            backgroundColor: "#fff",
            color: "grey",
            textAlign: "center",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloudUpload />
          <span style={{ marginTop: 4, marginLeft: 5 }}>
            <FormattedMessage
              id="general.Click-or-drop-files"
              defaultMessage="Click or drop files in the box to upload them"
            />
          </span>
          <input
            type="file"
            name="files"
            style={{
              width: "100%",
              height: 130,
              top: -30,
              left: 0,
              cursor: "pointer",
              position: "absolute",
            }}
            onChange={(e) => {
              const filesMap = e.target.files;
              const files = Object.keys(filesMap).map(
                (fileKey) => filesMap[fileKey]
              );

              if (isEdit) {
                updateFiles(files);
              } else {
                setNewFiles([...newFiles, ...files]);
              }

              e.target.value = null;
              e.target.files = null;
            }}
            multiple
          />
        </div>
      </FormControl>
    );
  };

  const renderDialogActions = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 56.5,
            minWidth: 400,
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 24px 20px",
        }}
      >
        <div>
          {isEdit && (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                setOpenConfirmModal(true);
              }}
              color="error"
            >
              <FormattedMessage
                id="general.Delete-Ticket"
                defaultMessage="Delete Ticket"
              />
            </Button>
          )}
        </div>
        <div>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              resetState();
              onCloseModal();
            }}
          >
            <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" color="primary">
            {isEdit ? (
              <FormattedMessage id="general.Update" defaultMessage="Update" />
            ) : (
              <FormattedMessage id="general.Create" defaultMessage="Create" />
            )}
          </Button>
        </div>
      </DialogActions>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          resetState();
          onCloseModal();
        }}
      >
        <DialogTitle>
          {isEdit ? (
            <FormattedMessage
              id="general.Edit-Ticket"
              defaultMessage="Edit Ticket"
            />
          ) : (
            <FormattedMessage
              id="general.New-Ticket"
              defaultMessage="New Ticket"
            />
          )}
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              value={ticket.title}
              margin="dense"
              id="title"
              label={intl.formatMessage({
                id: "general.Title",
                defaultMessage: "Title",
              })}
              type="text"
              name="title"
              fullWidth
              required
              variant="outlined"
              error={showFormErrors && ticket.title === ""}
              helperText={
                showFormErrors && ticket.title === ""
                  ? intl.formatMessage({
                      id: "general.Title-is-required",
                      defaultMessage: "Title is required",
                    })
                  : ""
              }
              onChange={(event) => handleOnChangeFormField(event)}
            />
            <TextField
              multiline
              rows={2}
              style={{ width: "100%" }}
              margin="dense"
              id="description"
              name="description"
              value={ticket.description}
              label={intl.formatMessage({
                id: "general.Description",
                defaultMessage: "Description",
              })}
              type="text"
              fullWidth
              variant="outlined"
              onChange={(event) => handleOnChangeFormField(event)}
            />
            <FormControl
              variant="outlined"
              sx={{ marginTop: "10px" }}
              fullWidth
            >
              <InputLabel id="status-label">
                <FormattedMessage id="general.Status" defaultMessage="Status" />
              </InputLabel>
              <Select
                labelId="status-label"
                label={intl.formatMessage({
                  id: "general.Status",
                  defaultMessage: "Status",
                })}
                name="status"
                onChange={(event) => handleOnChangeFormField(event)}
                value={ticket.status || "open"}
                defaultValue="Gathering Data"
                error={showFormErrors && ticket.status === ""}
              >
                <MenuItem value="open">
                  <FormattedMessage id="general.Open" defaultMessage="Open" />
                </MenuItem>
                <MenuItem value="closed">
                  <FormattedMessage
                    id="general.Closed"
                    defaultMessage="Closed"
                  />
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              sx={{ marginTop: "10px" }}
              fullWidth
            >
              <InputLabel id="project-label">
                <FormattedMessage
                  id="general.Project"
                  defaultMessage="Project"
                />
              </InputLabel>
              <Select
                labelId="project-label"
                label={intl.formatMessage({
                  id: "general.Project",
                  defaultMessage: "Project",
                })}
                name="project"
                onChange={(event) => handleOnChangeFormField(event)}
                value={ticket.project}
                renderValue={(project) => <span>{project.name}</span>}
              >
                {projects.map((project) => {
                  return (
                    <MenuItem key={project.id} value={project}>
                      {project.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {renderFiles()}
          </DialogContent>
          {renderDialogActions()}
        </Box>
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleDelete(currTicket.id);
        }}
        text={intl.formatMessage({
          id: "ticket-modal.Do-you-really-want-to-delete-ticket",
          defaultMessage: "Do you really want to delete this ticket?",
        })}
      />
    </div>
  );
};

export default Modal;
