import { AttachFile, Delete } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { useState } from "react";

const Attachment: React.FC<{
  src: string;
  name: string;
  mime: string;
  isNew?: boolean;
  deleteCallback?: any;
}> = (props) => {
  const [loading, setLoading] = useState(false);

  let resourceURL = "";

  if (props.src.startsWith("http") || props.src.startsWith("blob")) {
    resourceURL = props.src;
  } else {
    resourceURL = `${process.env.REACT_APP_HOST_URL}${props.src}`;
  }

  const handleDelete = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (props.deleteCallback) {
      await props.deleteCallback();
    }

    setLoading(false);
  };

  const getPreviewImage = () => {
    if (["image/gif", "image/jpeg", "image/png"].includes(props.mime)) {
      return (
        <span
          style={{
            minWidth: 24,
            textAlign: "center",
          }}
        >
          <img
            alt=""
            src={resourceURL}
            style={{
              height: 24,
              marginRight: 5,
            }}
          />
        </span>
      );
    }

    return <AttachFile />;
  };

  const renderDeleteIconBtn = () => {
    if (loading) {
      return (
        <div
          style={{
            width: 36,
            height: 36,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size="20px" />
        </div>
      );
    }

    return (
      <IconButton
        aria-label="delete"
        onClick={handleDelete}
        style={{
          padding: 6,
        }}
      >
        <Delete />
      </IconButton>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {getPreviewImage()}
        <a href={resourceURL} target="_blank" rel="noopener noreferrer">
          {props.name} {props.isNew}
        </a>
      </div>
      {renderDeleteIconBtn()}
    </div>
  );
};

export default Attachment;
