import axios from "axios";
import toast from "react-hot-toast";
import qs from "qs";

import { refreshTasks } from "./task";
import { Quote } from "../../components/task/types";
import { getIntl } from "../../GlobalIntlProvider";

const initialState = {
  quotes: [],
  draftQuotes: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
};

const generateQuoteFillQuery = (pagination: any = {}) => {
  return qs.stringify(
    {
      populate: {
        company: {
          fields: [
            "name",
            "country",
            "city",
            "province",
            "addressLine1",
            "addressLine2",
            "zipCode",
            "language",
            "hourly_rate",
            "email",
            "contactFullName"
          ],
          populate: {
            invoice_to: {
              fields: ["id", "name"],
              populate: {
                logo: {
                  fields: ["url", "mime", "name"]
                }
              }
            }
          }
        },
        quote_items: {
          fields: ["price", "status", "title", "description"],
          populate: {
            task: {
              fields: ["title", "status", "description", "invoicing_scheme"],
              populate: {
                project: {
                  fields: ["name", "intellectualProperty"],
                },
                estimates: {
                  populate: {
                    user: {
                      fields: ["id", "firstName", "lastName", "username"],
                    },
                  },
                },
                seen_by: {
                  fields: ["id"],
                },
              },
            },
            subscription_item: true,
          },
          sort: ["order:asc"],
        },
      },
      sort: ["number:desc"],
      pagination,
    },
    {
      encodeValuesOnly: true,
    }
  );
};

const serializeQuote = (q: any) => {
  const quote: Quote = {
    id: q.id,
    ...q.attributes,
    company: {
      id: parseInt(q.attributes.company.data?.id || 0),
      ...q.attributes.company.data?.attributes,
      invoice_to: q.attributes.company.data?.attributes?.invoice_to?.data ? {
        id: parseInt(q.attributes.company.data.attributes.invoice_to.data.id),
        ...q.attributes.company.data.attributes.invoice_to.data.attributes,
        logo: q.attributes.company.data.attributes.invoice_to.data.attributes?.logo?.data ? {
          id: parseInt(q.attributes.company.data.attributes.invoice_to.data.attributes.logo.data.id),
          ...q.attributes.company.data.attributes.invoice_to.data.attributes.logo.data.attributes
        } : undefined
      } : undefined
    },
    quote_items: q.attributes.quote_items.data?.map((qi: any) => ({
      id: parseInt(qi.id),
      ...qi.attributes,
      task: {
        id: parseInt(qi.attributes.task.data?.id || 0),
        ...qi.attributes.task.data?.attributes,
        project: {
          id: qi.attributes.task.data?.attributes?.project?.data?.id,
          ...qi.attributes.task.data?.attributes?.project?.data?.attributes,
        },
        estimates: qi.attributes.task.data?.attributes?.estimates?.data.map(
          (est: any) => ({
            id: est.id,
            ...est.attributes,
            user: {
              id: est.attributes.user?.data.id,
              ...est.attributes.user?.data.attributes,
            },
          })
        ),
        seen_by: qi.attributes.task.data?.attributes?.seen_by?.data.map(
          (s: any) => ({
            id: s.id,
            ...s.attributes,
          })
        ),
      },
      subscription_item: {
        id: qi.attributes.subscription_item?.data?.id,
        ...qi.attributes.subscription_item?.data?.attributes,
      },
    })),
  };

  return quote;
};

const quoteReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "QUOTES/GETALL":
      return {
        ...state,
        ...action.payload,
      };
    case "QUOTES/CREATE":
      if (action.payload == null) return state;
      return {
        ...state,
        quotes: action.payload,
      };
    case "QUOTES/REMOVE":
      return {
        ...state,
        quotes: [...state.quotes].filter(
          (quote) => quote.id !== action.payload.quoteID
        ),
      };
    case "QUOTES/EDIT":
      const updatedQuote = action.payload.quote;

      return {
        ...state,
        quotes: [...state.quotes].map((quote) =>
          quote.id === updatedQuote.id ? updatedQuote : quote
        ),
      };
    case "QUOTE/ITEMS/REMOVE":
      const newQuotes = [...state.quotes].map((quote) => {
        quote.quote_items = quote.quote_items.filter((qi: any) => {
          return qi.id !== action.payload.qiId;
        });

        return quote;
      });

      return {
        ...state,
        quotes: newQuotes,
      };
    default:
      return state;
  }
};

export const getQuotes = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      quotes: { pagination },
    } = getState();

    const qPagination = {
      page: page ? page : pagination.page,
      pageSize: pageSize ? pageSize : pagination.pageSize,
    };

    const query = generateQuoteFillQuery(qPagination);

    try {
      const response = await axios(`/quotes?${query}`);
      const {
        data,
        meta: { pagination },
      } = response.data;

      const quotes = data.map((q: any) => serializeQuote(q));

      dispatch({
        type: "QUOTES/GETALL",
        payload: {
          quotes,
          pagination,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quotes.fetch-error",
            defaultMessage: "Could not fetch quotes. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const fetchSingleQuoteWithouState = async (quoteId: string) => {
  const query = generateQuoteFillQuery();

  const response = await axios(`/quotes/${quoteId}?${query}`);

  return serializeQuote(response.data.data);
};

export const getDraftQuotes = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(
        `/quotes?filters[status]=draft&pagination[pageSize]=100`
      );
      const quotes = response.data.data;

      dispatch({
        type: "QUOTES/GETALL",
        payload: {
          draftQuotes: quotes.map((q: any) => {
            return {
              id: q.id,
              ...q.attributes,
            };
          }),
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.draft-quotes.fetch-error",
            defaultMessage: "Could not fetch draft quotes. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const getQuoteItemsInIds = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`/quotes-items`);
      const quotes = response.data;

      dispatch({
        type: "QUOTES/GETALL",
        payload: {
          quotes,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quotes.fetch-error",
            defaultMessage: "Could not fetch quotes. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const createQuote = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { quote, task } = payload;

      const response = await axios.post(`/quotes`, {
        data: quote,
      });

      const newQuote = {
        ...response.data,
      };

      if (task) {
        dispatch(
          createQuoteItem({
            data: {
              quote: newQuote,
              task,
            },
          })
        );

        dispatch(getDraftQuotes());
      } else {
        dispatch(getQuotes());
      }
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quotes.create-error",
            defaultMessage: "Could not create quote. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const createQuoteItem = (payload: any) => {
  return async (dispatch: any) => {
    const title = payload.data.title || payload.data.task.title;
    const description =
      payload.data.description || payload.data.task.description;

    payload.data.title = title;
    payload.data.description = description;

    try {
      await axios.post(`/quote-items`, payload);

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.added-to-quote",
            defaultMessage: "{task_title} added to quote #{quote_number}",
          },
          {
            task_title: title,
            quote_number: payload.data.quote.number,
          }
        )
      );

      dispatch(refreshTasks());
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quote-item.create-error",
            defaultMessage: "Could not create quote item. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const removeQuoteItems = (payload: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const { quote, task } = payload;

      if (task.quoteItemsMap[quote.id]) {
        for (const qi of task.quoteItemsMap[quote.id]) {
          await axios.delete(`/quote-items/${qi.id}`);
        }
      }

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.removed-from-quote",
            defaultMessage: "{task_title} removed from quote #{quote_number}",
          },
          {
            task_title: task.title,
            quote_number: quote.number,
          }
        )
      );

      dispatch(refreshTasks());
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quote-item.remove",
            defaultMessage: "Could not remove quote items. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const deleteQuote = (quoteID: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/quotes/${quoteID}`);

      dispatch({
        type: "QUOTES/REMOVE",
        payload: {
          quoteID,
        },
      });

      toast.success(
        getIntl().formatMessage({
          id: "toast.quote.deleted",
          defaultMessage: "Quote deleted",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quote.delete-error",
            defaultMessage: "Could not delete quote. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const deleteQuoteItem = (qiId: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/quote-items/${qiId}`);

      dispatch({
        type: "QUOTE/ITEMS/REMOVE",
        payload: {
          qiId,
        },
      });
      toast.success(
        getIntl().formatMessage({
          id: "toast.quote-item.deleted",
          defaultMessage: "Quote item deleted",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quote-item.delete-error",
            defaultMessage: "Could not delete quote item. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const updateQuote = (quoteDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/quotes/${quoteDetails.data.id}`, {
        data: quoteDetails.data,
      });

      dispatch(getQuotes());

      toast.success(
        getIntl().formatMessage({
          id: "toast.quote.updated",
          defaultMessage: "Quote updated",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.quote.update-error",
            defaultMessage: "Could not update quote. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export default quoteReducer;
