import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';

interface PaymentTermsSectionProps {
  quote: Quote | null;
}

export const PaymentTermsSection: React.FC<PaymentTermsSectionProps> = ({ quote }) => {
  const intl = useIntl();

  return (
    <View style={styles.section}>
      <View style={styles.sectionTitleGroup} wrap={false}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.paymentTerms' })}
        </Text>
        <Text style={styles.partyText}>
          {(() => {
            const approvalPercentage = quote?.partial_invoicing ?? 0;
            if (approvalPercentage === 100) {
              return intl.formatMessage({ id: 'quote.pdf.paymentTerms.full_on_approval' });
            }
            if (approvalPercentage === 0) {
              return intl.formatMessage({ id: 'quote.pdf.paymentTerms.full_on_delivery' });
            }
            if (approvalPercentage === 50) {
              return intl.formatMessage({ id: 'quote.pdf.paymentTerms.half_half' });
            }
            return intl.formatMessage(
              { id: 'quote.pdf.paymentTerms.split' },
              {
                approval_percentage: approvalPercentage,
                remaining_percentage: 100 - approvalPercentage
              }
            );
          })()} {intl.formatMessage({ id: 'quote.pdf.paymentTerms.standard' })}
        </Text>
        {quote?.company?.invoice_to && (
          <Text style={styles.partyText}>
            {intl.formatMessage(
              { id: 'quote.pdf.invoiceThrough' },
              { company: quote.company.invoice_to.name }
            )}
          </Text>
        )}
      </View>
    </View>
  );
};
