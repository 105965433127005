import React, { useEffect } from "react";

const Login = () => {
  useEffect(() => {
    window.location.href = process.env.REACT_APP_baseUrl + "/connect/auth0";
  }, []);

  return <div></div>;
};

export default Login;
