import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import QuoteModal from "../components/quote/modal";
import { Button, TableFooter, TablePagination } from "@mui/material";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormattedMessage, useIntl } from "react-intl";

import { getProjectOptions } from "../redux/reducerSlices/project";
import { getQuotes } from "../redux/reducerSlices/quote";
import QuoteRow from "../components/quote/row";
import { Quote } from "../components/task/types";
import { getCompanies } from "../redux/reducerSlices/company";
import TablePaginationActions from "../components/TablePaginationActions";

const QuotesTable = (props: any) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { quotes, pagination } = useSelector(
    (state: RootStateOrAny) => state.quotes
  );

  useEffect(() => {
    dispatch(getQuotes());
    dispatch(getCompanies(1, 100));
  }, [dispatch]);

  if (quotes.length <= 0) {
    return (
      <div>
        <FormattedMessage
          id="quotes-page.No-quotes-found"
          defaultMessage="No quotes found"
        />
      </div>
    );
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getQuotes(newPage + 1, pagination.pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getQuotes(1, parseInt(event.target.value, 10)));
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 100,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Number" defaultMessage="Number" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Client" defaultMessage="Client" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Status" defaultMessage="Status" />
            </TableCell>
            <TableCell align="left" colSpan={1}>
              <FormattedMessage id="general.Date" defaultMessage="Date" />
            </TableCell>
            <TableCell>{/* empty */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotes &&
            quotes.map((quote) => (
              <QuoteRow
                key={quote.id}
                quote={{
                  ...quote,
                  handleOpenEdinModal: props.handleOpenEdinModal,
                }}
              />
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={pagination.total}
              rowsPerPage={pagination.pageSize}
              page={pagination.page - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": intl.formatMessage({
                    id: "general.Quotes-per-page",
                    defaultMessage: "Quotes per page",
                  }),
                },
                native: true,
              }}
              labelRowsPerPage={intl.formatMessage({
                id: "general.Quotes-per-page",
                defaultMessage: "Quotes per page",
              })}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${intl.formatMessage({
                  id: "pagination.of",
                  defaultMessage: "of",
                })} ${count}`;
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const Quotes = () => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [modalQuote, setModalQuote] = useState<Quote | null>(null);

  useEffect(() => {
    dispatch(getProjectOptions());
  }, [dispatch]);

  const openNewQuoteModal = () => {
    setModalQuote(null);
    setOpenModal(true);
  };

  const handleOpenEdinModal = (quote: any) => {
    setModalQuote(quote);
    setOpenModal(true);
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Quotes" defaultMessage="Quotes" />
          </Typography>
          <Button onClick={openNewQuoteModal}>
            <FormattedMessage
              id="quotes-page.New-Quote"
              defaultMessage="New Quote"
            />
          </Button>
        </Box>
        <QuoteModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          extQuote={modalQuote}
        />
        <QuotesTable handleOpenEdinModal={handleOpenEdinModal} />
      </Box>
    </Container>
  );
};

export default Quotes;
