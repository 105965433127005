import { Card as CardMui, CardContent, CardHeader } from "@mui/material";

const Card: React.FC<{
  title: any;
  description: any;
  onClick: any;
  icon: any;
}> = ({ title, description, onClick, icon }) => {
  return (
    <CardMui
      style={{
        cursor: "pointer",
        width: "100%",
      }}
      onClick={() => {
        onClick();
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "h5",
        }}
        avatar={icon}
      />
      <CardContent
        style={{
          paddingTop: 0,
        }}
      >
        {description}
      </CardContent>
    </CardMui>
  );
};

export default Card;
