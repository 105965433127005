import axios from "axios";

const ticketReducer = (state = [], action: any) => {
  switch (action.type) {
    case "TICKETS/GETALL":
      const tickets = action.payload.tickets;
      return [...tickets];
    case "TICKETS/CREATE":
      if (action.payload == null) return state;
      return state.concat(action.payload);
    case "TICKETS/REMOVE":
      return state.filter((ticket) => ticket.id !== action.payload.ticketID);
    case "TICKETS/EDIT":
      const updatedTicket = action.payload.ticket;
      return state.map((ticket) =>
        ticket.id === updatedTicket.id ? updatedTicket : ticket
      );
    case "TICKETS/EDIT/FILES":
      const updatedTicketFiles = action.payload.ticket;
      return state.map((ticket) => {
        if (ticket.id === updatedTicketFiles.id) {
          return {
            ...ticket,
            attachments: updatedTicketFiles.attachments,
          };
        }

        return ticket;
      });
    default:
      return state;
  }
};

export const getTickets = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`/tickets`);
      const tickets = response.data;

      dispatch({
        type: "TICKETS/GETALL",
        payload: {
          tickets,
        },
      });
    } catch (err) {
      dispatch({
        type: "TICKETS/GETALL",
        payload: null,
        error: err,
      });
    }
  };
};

export const createTicket = (ticketDetails: any) => {
  return async (dispatch: any) => {
    try {
      if (!ticketDetails.data.project.id) {
        delete ticketDetails.data.project;
      }

      await axios.post(`/tickets`, ticketDetails);

      dispatch(getTickets());
    } catch (err) {
      console.error(err);
      dispatch({
        type: "TICKETS/CREATE",
        payload: null,
        error: err,
      });
    }
  };
};

export const deleteTicket = (ticketID: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/TICKETS/${ticketID}`);

      dispatch(getTickets());
    } catch (err) {
      dispatch({
        type: "TICKETS/REMOVE",
        payload: null,
        error: err,
      });
    }
  };
};

export const updateTicket = (ticketDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/TICKETS/${ticketDetails.data.id}`, {
        data: ticketDetails.data,
      });

      dispatch(getTickets());
    } catch (err) {
      dispatch({
        type: "TICKETS/EDIT",
        payload: null,
        error: err,
      });
    }
  };
};

export const updateTicketFiles = (ticketDetails: any) => {
  return async (dispatch: any) => {
    const response = await axios.put(`/TICKETS/${ticketDetails.data.id}`, {
      data: ticketDetails.data,
    });
    const ticket = response.data;

    dispatch({
      type: "TICKETS/EDIT/FILES",
      payload: {
        ticket: { ...ticket },
      },
    });
  };
};

export default ticketReducer;
