import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  connectTrello,
  disconnectTrello,
} from "../../../redux/reducerSlices/trello";

const TrelloTab = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state);

  return (
    <Box style={{ marginTop: 0 }}>
      <Typography variant="h5">
        <FormattedMessage id="general.Trello" defaultMessage="Trello" />
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item sm={12}>
          {Boolean(user.trello_token) ? (
            <Button
              onClick={() => {
                dispatch(disconnectTrello());
              }}
            >
              <FormattedMessage
                id="general.Disconnect-Trello"
                defaultMessage="Disconnect Trello"
              />
            </Button>
          ) : (
            <Button
              onClick={() => {
                dispatch(connectTrello());
              }}
            >
              <FormattedMessage
                id="general.Connect-Trello"
                defaultMessage="Connect Trello"
              />
            </Button>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};

export default TrelloTab;
