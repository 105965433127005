import axios from "axios";
import toast from "react-hot-toast";
import QueryString from "qs";

import { getQuotes } from "./quote";
import { internalReleasesTaskSeenBy } from "./release";
import { getIntl } from "../../GlobalIntlProvider";

const initialState = {
  tasks: [],
  pagination: { page: 1, pageSize: 10 },
  loading: false,
  estimates: [],
  activeTasks: [],
  activeTasksProjectId: null,
  showAll: true,
  showInReview: false,
  showAddToQuote: false,
  showAddToRelease: false,
  showAddToInvoice: false,
  showToDo: false,
  showInProgress: false,
  showToEstimate: false,
};

const reorder = (arr: any[], startIndex: number, endIndex: number) => {
  const result = [...arr];
  const [removed] = result.splice(startIndex, 1);
  const prev = result[endIndex - 1];
  const next = result[endIndex];
  let newPos = 0;

  if (prev && next) {
    newPos = (prev.pos + next.pos) / 2;
  } else if (prev) {
    newPos = prev.pos + 200;
  } else if (next) {
    newPos = next.pos - 200;
  }

  removed.pos = newPos;
  result.splice(endIndex, 0, removed);

  return {
    tasks: result,
    uptTask: removed,
  };
};

const taskReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "TASKS/REORDER":
      return {
        ...initialState,
        ...state,
        tasks: action.payload.tasks,
      };
    case "TASKS/LOADING/TRUE":
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case "TASKS/LOADING/FALSE":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "TASKS/GETALL":
      const tasks = [...action.payload.state.tasks];
      const estimates = [...action.payload.state.estimates];

      const estimatesTasksMap = {};

      estimates.forEach((est) => {
        if (!estimatesTasksMap[est.task.id]) {
          estimatesTasksMap[est.task.id] = [];
        }

        estimatesTasksMap[est.task.id].push(est);
      });

      tasks.forEach((task) => {
        const qiMap = {};
        const iiMap = {};

        if (!task.quote_items) task.quote_items = [];
        if (!task.invoice_items) task.invoice_items = [];

        task.quote_items.forEach((qi) => {
          if (!qi.quote) return null;

          if (!qiMap[qi.quote.id]) {
            qiMap[qi.quote.id] = [];
          }

          qiMap[qi.quote.id].push(qi);
        });

        task.invoice_items.forEach((ii) => {
          if (!ii.invoice) return null;

          if (!iiMap[ii.invoice.id]) {
            iiMap[ii.invoice.id] = [];
          }

          iiMap[ii.invoice.id].push(ii);
        });

        task.invoiceItemsMap = iiMap;
        task.quoteItemsMap = qiMap;

        if (!Array.isArray(task.estimates)) {
          task.estimates = estimatesTasksMap[task.id] || [];
        }
      });

      return {
        ...initialState,
        ...state,
        ...action.payload.state,
        tasks,
      };
    case "TASKS/CREATE":
      if (action.payload == null) {
        return {
          ...initialState,
          ...state,
        };
      }

      return {
        ...initialState,
        ...state,
        tasks: [...state.tasks.concat(action.payload)],
      };
    case "TASKS/REMOVE":
      return {
        ...initialState,
        ...state,
        tasks: [
          ...state.tasks.filter((task) => task.id !== action.payload.taskID),
        ],
      };
    case "TASKS/EDIT":
      const updatedTask = action.payload.task;

      return {
        ...initialState,
        ...state,
        tasks: [
          ...state.tasks.map((task) =>
            task.id === updatedTask.id ? updatedTask : task
          ),
        ],
      };
    case "TASKS/GET/ACTIVE":
      const { activeTasks = [], activeTasksProjectId } = action.payload;

      return {
        ...initialState,
        ...state,
        activeTasks,
        activeTasksProjectId,
      };
    case "TASKS/SET/TABS":
      const {
        page = 1,
        pageSize = 10,
        showAll = false,
        showInReview = false,
        showAddToQuote = false,
        showAddToRelease = false,
        showAddToInvoice = false,
        showToDo = false,
        showInProgress = false,
        showToEstimate = false,
        showMyToDo = false,
        showMyInProgress = false,
        showMyToEstimate = false,
        selectProjectId = "",
      } = action.payload;

      return {
        ...initialState,
        ...state,
        pagination: {
          page,
          pageSize,
        },
        showAll,
        showInReview,
        showAddToQuote,
        showAddToRelease,
        showAddToInvoice,
        showToDo,
        showInProgress,
        showToEstimate,
        showMyToDo,
        showMyInProgress,
        showMyToEstimate,
        selectProjectId,
      };
    default:
      return {
        ...initialState,
        ...state,
      };
  }
};

export const getTasks = ({
  page = 1,
  pageSize = 10,
  showAll = false,
  showInReview = false,
  showAddToQuote = false,
  showAddToRelease = false,
  showAddToInvoice = false,
  showToDo = false,
  showInProgress = false,
  showToEstimate = false,
  showMyToDo = false,
  showMyInProgress = false,
  showMyToEstimate = false,
  selectProjectId = "",
}) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: "TASKS/SET/TABS",
      payload: {
        page,
        pageSize,
        showAll,
        showInReview,
        showAddToQuote,
        showAddToRelease,
        showAddToInvoice,
        showToDo,
        showInProgress,
        showToEstimate,
        showMyToDo,
        showMyInProgress,
        showMyToEstimate,
        selectProjectId,
      },
    });

    dispatch(refreshTasks());
  };
};

export const getAddQuoteTasks = ({ page, pageSize, selectProjectId }) => {
  return async (dispatch: any) => {
    dispatch({ type: "TASKS/LOADING/TRUE" });
    try {
      const pIdQ = selectProjectId ? `&projectId=${selectProjectId}` : "";
      const response = await axios(
        `/tasks-add-to-quote?pagination[page]=${page}&pagination[pageSize]=${pageSize}${pIdQ}`
      );
      const { results: tasks, pagination, estimates = [] } = response.data;

      dispatch({
        type: "TASKS/GETALL",
        payload: {
          state: {
            tasks,
            pagination,
            loading: false,
            estimates,
          },
        },
      });
    } catch (err) {
      console.error("err", err);
      dispatch({
        type: "TASKS/GETALL",
        payload: null,
        error: err,
      });
    }
  };
};

export const getTasksByStatus = ({ page, pageSize }) => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: "TASKS/LOADING/TRUE" });

    const { user, task } = getState();
    const {
      showInProgress,
      showToDo,
      showToEstimate,
      showInReview,
      showMyToDo,
      showMyInProgress,
      showMyToEstimate,
      selectProjectId,
    } = task;

    let query = QueryString.stringify({
      pagination: {
        page,
        pageSize,
      },
      showInProgress,
      showToDo,
      showToEstimate,
      showMyToDo,
      showMyInProgress,
      showMyToEstimate,
      showInReview,
      projectId: selectProjectId,
    });

    let response = null;

    if (user?.selectedOrg?.role === "Developer") {
      response = await axios(`/tasks-dev-view?${query}`);
    } else {
      response = await axios(`/tasks?${query}`);
    }

    const { results: tasks, pagination, estimates = [] } = response.data;

    dispatch({
      type: "TASKS/GETALL",
      payload: {
        state: {
          tasks,
          pagination,
          loading: false,
          estimates,
        },
      },
    });
  };
};

export const getAddInvoiceTasks = ({ page, pageSize, selectProjectId }) => {
  return async (dispatch: any) => {
    dispatch({ type: "TASKS/LOADING/TRUE" });
    try {
      const pIdQ = selectProjectId ? `&projectId=${selectProjectId}` : "";
      const response = await axios(
        `/tasks-add-to-invoice?pagination[page]=${page}&pagination[pageSize]=${pageSize}${pIdQ}`
      );
      const { results: tasks, pagination, estimates = [] } = response.data;

      dispatch({
        type: "TASKS/GETALL",
        payload: {
          state: {
            tasks,
            pagination,
            loading: false,
            estimates,
          },
        },
      });
    } catch (err) {
      console.error("err", err);
      dispatch({
        type: "TASKS/GETALL",
        payload: null,
        error: err,
      });
    }
  };
};

export const getToReleaseTasks = ({
  page,
  pageSize,
  showInReview = false,
  selectProjectId,
}) => {
  return async (dispatch: any) => {
    dispatch({ type: "TASKS/LOADING/TRUE" });
    try {
      const pIdQ = selectProjectId ? `&projectId=${selectProjectId}` : "";
      const response = await axios(
        `/tasks-to-release?pagination[page]=${page}&pagination[pageSize]=${pageSize}&showInReview=${showInReview}${pIdQ}`
      );
      const { results: tasks, pagination, estimates = [] } = response.data;

      dispatch({
        type: "TASKS/GETALL",
        payload: {
          state: {
            tasks,
            pagination,
            loading: false,
            estimates,
          },
        },
      });
    } catch (err) {
      console.error("err", err);
      dispatch({
        type: "TASKS/GETALL",
        payload: null,
        error: err,
      });
    }
  };
};

export const createTask = (
  taskDetails: any,
  callback?: (task: any) => void
) => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: "TASKS/LOADING/TRUE" });
    try {
      if (!taskDetails.data.project.id) {
        delete taskDetails.data.project;
      }

      const createRes = await axios.post(`/tasks`, taskDetails);

      if (window.location.href.endsWith("/add-to-release")) {
        const { pagination, selectProjectId } = getState().task;

        dispatch(
          getToReleaseTasks({
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
            selectProjectId,
          })
        );
      } else {
        dispatch(refreshTasks());
      }

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.created",
            defaultMessage: 'Task "{task_title}" created successfully',
          },
          {
            task_title: taskDetails.data.title,
          }
        )
      );

      if (callback) {
        callback(createRes.data);
      }

      if (createRes.data.syncErr) {
        toast.error(
          getIntl().formatMessage(
            {
              id: "toast.task.sync-error",
              defaultMessage:
                'Task "{task_title}" was not sync in Trello. Reason: "{sync_error}"',
            },
            {
              task_title: taskDetails.data.title,
              sync_error: createRes.data.syncErr,
            }
          ),
          {
            duration: 5000,
          }
        );
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "TASKS/CREATE",
        payload: null,
        error: err,
      });
    }
  };
};

export const deleteTask = (taskID: any, task: any) => {
  return async (dispatch: any) => {
    try {
      const delRes = await axios.delete(`/tasks/${taskID}`);

      dispatch(refreshTasks());

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.deleted",
            defaultMessage: 'Task "{task_title}" deleted',
          },
          {
            task_title: task.title,
          }
        )
      );

      if (delRes.data.syncErr) {
        toast.error(
          getIntl().formatMessage(
            {
              id: "toast.task.sync-error",
              defaultMessage:
                'Task "{task_title}" was not sync in Trello. Reason: "{sync_error}"',
            },
            {
              task_title: task.title,
              sync_error: delRes.data.syncErr,
            }
          ),
          {
            duration: 5000,
          }
        );
      }
    } catch (err) {
      dispatch({
        type: "TASKS/REMOVE",
        payload: null,
        error: err,
      });
    }
  };
};

export const updateTask = (taskDetails: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: "TASKS/LOADING/TRUE" });
    try {
      if (!taskDetails.data.project?.id) {
        delete taskDetails.data.project;
      }

      const uptTaskRes = await axios.put(`/tasks/${taskDetails.data.id}`, {
        data: taskDetails.data,
      });

      if (window.location.href.endsWith("/quotes")) {
        dispatch(getQuotes());
      } else if (window.location.href.endsWith("/add-to-release")) {
        const { pagination, selectProjectId } = getState().task;

        dispatch(
          getToReleaseTasks({
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
            selectProjectId,
          })
        );
      } else {
        dispatch(refreshTasks());
      }

      if (taskDetails.data.title) {
        toast.success(
          getIntl().formatMessage(
            {
              id: "toast.task.updated",
              defaultMessage: 'Task "{task_title}" updated successfully',
            },
            {
              task_title: taskDetails.data.title,
            }
          )
        );
      }

      if (uptTaskRes.data.syncErr) {
        toast.error(
          getIntl().formatMessage(
            {
              id: "toast.task.sync-error",
              defaultMessage:
                'Task "{task_title}" was not sync in Trello. Reason: "{sync_error}"',
            },
            {
              task_title: taskDetails.data.title,
              sync_error: uptTaskRes.data.syncErr,
            }
          ),
          {
            duration: 5000,
          }
        );
      }
    } catch (err) {
      dispatch({
        type: "TASKS/EDIT",
        payload: null,
        error: err,
      });
    }
  };
};

export const refreshTasks = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: "TASKS/LOADING/TRUE" });
    const { task } = getState();
    const {
      showAddToQuote,
      showAddToInvoice,
      showAddToRelease,
      pagination,
      selectProjectId,
    } = task;
    const { page, pageSize } = pagination;

    try {
      if (showAddToQuote) {
        return dispatch(getAddQuoteTasks({ page, pageSize, selectProjectId }));
      } else if (showAddToInvoice) {
        return dispatch(
          getAddInvoiceTasks({ page, pageSize, selectProjectId })
        );
      } else if (showAddToRelease) {
        return dispatch(
          getToReleaseTasks({
            page,
            pageSize,
            selectProjectId,
          })
        );
      }

      return dispatch(
        getTasksByStatus({
          page,
          pageSize,
        })
      );
    } catch (err) {
      console.error("err", err);
      dispatch({
        type: "TASKS/GETALL",
        payload: null,
        error: err,
      });
    }
  };
};

export const createEstimate = (payload: any) => {
  return async (dispatch: any) => {
    try {
      await axios.post(`/estimates`, payload);

      dispatch(refreshTasks());

      toast.success(
        getIntl().formatMessage({
          id: "toast.estimate.created",
          defaultMessage: "The estimate was created successfully",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.estimate.create-error",
            defaultMessage:
              "The estimate wasn't created. Reason: {creation_error}",
          },
          {
            creation_error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const updateEstimate = (payload: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/estimates/${payload.data.id}`, payload);

      dispatch(refreshTasks());

      toast.success(
        getIntl().formatMessage({
          id: "toast.estimate.updated",
          defaultMessage: "The estimate was updated successfully",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.estimate.update-error",
            defaultMessage:
              "The estimate wasn't updated. Reason: {update_error}",
          },
          {
            update_error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const deleteEstimate = (estimateId: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/estimates/${estimateId}`);

      dispatch(refreshTasks());

      toast.success(
        getIntl().formatMessage({
          id: "toast.estimate.deleted",
          defaultMessage: "The estimate was deleted successfully",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.estimate.delete-error",
            defaultMessage:
              "The estimate wasn't deleted. Reason: {delete_error}",
          },
          {
            delete_error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const sawTask = (id: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const { task: taskState, user } = getState();

      await axios.get(`/saw-task/${id}`);

      dispatch({
        type: "TASKS/GETALL",
        payload: {
          state: {
            ...taskState,
            tasks: [...taskState.tasks].map((t) => {
              if (t.id === id) t.seen_by = [{ id: user.id }];

              return t;
            }),
          },
        },
      });
      dispatch(internalReleasesTaskSeenBy(id));
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.task.seen-error",
            defaultMessage:
              "Could not update user's seen tasks. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const reorderTasks = (payload: any) => {
  return async (dispatch: any, getState) => {
    try {
      const { task: taskState } = getState();
      const { tasks } = taskState;
      const newTasks = [...tasks];
      const { sourceIndex, destinationIndex } = payload;

      const reordered = reorder(newTasks, sourceIndex, destinationIndex);

      dispatch({
        type: "TASKS/REORDER",
        payload: {
          tasks: reordered.tasks,
        },
      });

      await axios.post(`/update-task-position`, {
        uptTask: reordered.uptTask,
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.task.reorder-error",
            defaultMessage: "Could not reorder tasks. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const getProjectActiveTasks = (projectId: number) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`/project-active-tasks/${projectId}`);

      dispatch({
        type: "TASKS/GET/ACTIVE",
        payload: {
          activeTasks: response.data,
          activeTasksProjectId: projectId,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.task.find-error",
            defaultMessage: "Could not find tasks. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export default taskReducer;
