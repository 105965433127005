import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import OrganizationModal from "../components/organization/modal";
import PaymentModal from "../components/organization/paymentModal";
import OrganizationRow from "../components/organization/row";
import { getOwnedOrganizations } from "../redux/reducerSlices/owned-organization";
import { refreshMe } from "../redux/reducerSlices/me";
import { updateLayout } from "../redux/reducerSlices/layout";

const OrganizationsPage = (props: any) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    ownedOrganizationState: { organizations: ownedOrgs, shouldInit },
    organizations,
    user,
    layout,
  } = useSelector((state: any) => state);
  const [modalOrganization, setModalOrganization] = useState<any>(null);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);

  const myOrgsMap = ownedOrgs.reduce((acc: any, org: any) => {
    acc[org.id] = org;
    return acc;
  }, {});

  useEffect(() => {
    if (shouldInit) {
      dispatch(getOwnedOrganizations());
    }
  }, [dispatch, shouldInit]);

  const handleOpenEdinModal = (organization: any) => {
    if (["initial", "canceled", "unpaid"].includes(user.subscription_status)) {
      setOpenPaymentModal(true);
    } else {
      setModalOrganization(organization);
      dispatch(
        updateLayout({
          openOrganizationModal: true,
        })
      );
    }
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage
              id="general.My-Organizations"
              defaultMessage="My Organizations"
            />
          </Typography>

          <Button onClick={() => handleOpenEdinModal(null)}>
            <FormattedMessage
              id="general.New-Organization"
              defaultMessage="New Organization"
            />
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Name" defaultMessage="Name" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(ownedOrgs.length) ? (
                ownedOrgs.map((organization: any) => {
                  return (
                    <OrganizationRow
                      key={organization.id}
                      clickable={true}
                      organization={{
                        ...organization,
                      }}
                      handleOpenEdinModal={handleOpenEdinModal}
                    />
                  );
                })
              ) : (
                <div style={{ padding: "5px 10px" }}>
                  <FormattedMessage
                    id="general.Currently-you-dont-own-any-organizations"
                    defaultMessage="Currently you don't own any organizations"
                  />
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage
              id="general.Other-Organizations"
              defaultMessage="Other-Organizations"
            />
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Name" defaultMessage="Name" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations
                .filter((org) => !myOrgsMap[org.id])
                .map((organization: any) => {
                  return (
                    <OrganizationRow
                      key={organization.id}
                      organization={{
                        ...organization,
                      }}
                      handleOpenEdinModal={handleOpenEdinModal}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <OrganizationModal
        openModal={layout.openOrganizationModal}
        extOrganization={modalOrganization}
        setOpenModal={(open: boolean) => {
          dispatch(
            updateLayout({
              openOrganizationModal: open,
            })
          );
        }}
      />
      <PaymentModal
        title={intl.formatMessage({
          id: "general.You-must-be-subscribed-to-create-organization",
          defaultMessage: "You must be subscribed to create an organization",
        })}
        openModal={openPaymentModal}
        setOpenModal={setOpenPaymentModal}
        onSubscribeCB={() => {
          dispatch(
            refreshMe(() => {
              dispatch(
                updateLayout({
                  openOrganizationModal: true,
                })
              );
            })
          );
        }}
      />
    </Container>
  );
};

export default OrganizationsPage;
