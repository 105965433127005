import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { CloudUpload } from "@mui/icons-material";
import axios from "axios";

import ConfirmDialog from "../ConfirmDialog";
import { Client, Language } from "./types";
import {
  createCompany,
  deleteCompany,
  updateCompany,
} from "../../redux/reducerSlices/company";
import { Currency } from "../task/types";
import CompaniesAutocomplete from "./CompaniesAutocomplete";
import Attachment from "../Attachment";

const emptyCompany: Client = {
  name: "",
  contactFullName: "",
  email: "",
  billing_email: "",
  language: "en" as Language,
  base_rate: 0,
  hourly_rate: 0,
  currency: Currency.USD,
  country: "",
  province: "",
  city: "",
  zipCode: "",
  addressLine1: "",
  addressLine2: "",
  requires_po: false,
};

const CompanyModal: React.FC<{
  extCompany?: Client | null;
  setOpenModal: any;
  openModal: boolean;
  onCreateCB?: () => void;
}> = ({ extCompany, setOpenModal, openModal, onCreateCB }) => {
  const isEditingCompany = Boolean(extCompany?.id);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [company, setCompany] = useState<Client>(emptyCompany);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [logoFile, setLogoFile] = useState<File | null>(null);

  useEffect(() => {
    if (!openModal) return null;

    if (extCompany) {
      setCompany({
        ...extCompany,
      });
    } else {
      setCompany(emptyCompany);
    }
    setLogoFile(null);
  }, [extCompany, openModal]);

  const closeModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setCompany(emptyCompany);
      setNameError(false);
      setLoading(false);
      setLogoFile(null);
    }, 200);
  };

  const handleDelete = (company: Client) => {
    dispatch(deleteCompany(company.id));
    setOpenConfirmModal(false);
    closeModal();
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setCompany({
        ...company,
        [event.target.name]: event.target.value,
      });
    } else {
      setCompany({
        ...company,
        [name]: event.toString(),
      });
    }
  };

  const uploadLogo = async (file: File) => {
    const formData = new FormData();
    formData.append("files", file);
    const response = await axios.post("/upload", formData);
    return response.data[0];
  };

  const validateCompany = () => {
    let isValid = true;

    if (company.name === "") {
      setNameError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateCompany()) {
      return null;
    }

    setLoading(true);

    let logoData = company.logo;
    if (logoFile) {
      logoData = await uploadLogo(logoFile);
    }

    if (isEditingCompany) {
      await dispatch(
        updateCompany({
          ...company,
          logo: logoData,
        })
      );
    } else {
      const newCompany = {
        ...company,
        logo: logoData,
      };

      delete newCompany.id;

      await dispatch(createCompany(newCompany));

      if (onCreateCB) {
        onCreateCB();
      }
    }

    setLoading(false);
    closeModal();
  };

  const handleLogoDelete = async () => {
    if (company.logo?.id) {
      try {
        await axios.delete(`/upload/files/${company.logo.id}`);
      } catch (e) {
        console.error("Error deleting logo", e);
      }
    }
    setLogoFile(null);
    setCompany({
      ...company,
      logo: null,
    });
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (company.logo) {
        handleLogoDelete();
      }
      setLogoFile(e.target.files[0]);
    }
    e.target.value = null;
  };

  const renderLogo = () => {
    const hasLogo = Boolean(logoFile || company.logo);

    return (
      <FormControl variant="outlined" sx={{ marginTop: "10px" }} fullWidth>
        {hasLogo ? (
          <div style={{ marginBottom: 10 }}>
            <Attachment
              mime={logoFile ? logoFile.type : company.logo.mime}
              name={logoFile ? logoFile.name : company.logo.name}
              src={
                logoFile
                  ? URL.createObjectURL(logoFile)
                  : `${process.env.REACT_APP_HOST_URL}${company.logo.url}`
              }
              deleteCallback={handleLogoDelete}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              border: "1px dashed grey",
              position: "relative",
              height: 100,
              backgroundColor: "#fff",
              color: "grey",
              textAlign: "center",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloudUpload />
            <span style={{ marginTop: 4, marginLeft: 5 }}>
              <FormattedMessage
                id="general.Click-or-drop-logo"
                defaultMessage="Click or drop logo image here"
              />
            </span>
            <input
              type="file"
              accept="image/*"
              name="logo"
              style={{
                width: "100%",
                height: 130,
                top: -30,
                left: 0,
                cursor: "pointer",
                position: "absolute",
                opacity: 0,
              }}
              onChange={handleLogoChange}
            />
          </div>
        )}
      </FormControl>
    );
  };

  const getModalTitle = () => {
    return isEditingCompany ? (
      <FormattedMessage
        id="client-modal.Edit-Client"
        defaultMessage="Edit Client"
      />
    ) : (
      <FormattedMessage
        id="client-modal.New-Client"
        defaultMessage="New Client"
      />
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    value={company.name || ""}
                    label={intl.formatMessage({
                      id: "general.Name",
                      defaultMessage: "Name",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => {
                      setNameError(false);
                      handleOnChangeFormField(event);
                    }}
                  />
                  {nameError && (
                    <FormHelperText sx={{ color: "#bf3333" }}>
                      <FormattedMessage
                        id="general.Name-is-required"
                        defaultMessage="Name is required"
                      />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    id="contactFullName"
                    name="contactFullName"
                    value={company.contactFullName || ""}
                    label={intl.formatMessage({
                      id: "general.Contact-Full-Name",
                      defaultMessage: "Contact Full Name",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="language-label">
                      <FormattedMessage
                        id="general.Language"
                        defaultMessage="Language"
                      />
                    </InputLabel>
                    <Select
                      fullWidth
                      id="language"
                      name="language"
                      value={company.language || ""}
                      label={intl.formatMessage({
                        id: "general.Language",
                        defaultMessage: "Language",
                      })}
                      type="text"
                      variant="outlined"
                      onChange={(event) => handleOnChangeFormField(event)}
                    >
                      <MenuItem value="en">
                        <FormattedMessage
                          id="general.English"
                          defaultMessage="English"
                        />
                      </MenuItem>
                      <MenuItem value="fr">
                        <FormattedMessage
                          id="general.French"
                          defaultMessage="French"
                        />
                      </MenuItem>
                      <MenuItem value="sv">
                        <FormattedMessage
                          id="general.Swedish"
                          defaultMessage="Swedish"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    value={company.email || ""}
                    label={intl.formatMessage({
                      id: "general.Email",
                      defaultMessage: "Email",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="billing_email"
                    name="billing_email"
                    value={company.billing_email || ""}
                    label={intl.formatMessage({
                      id: "general.Billing-Email",
                      defaultMessage: "Billing Email",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={company.base_rate || 0}
                    id="base_rate"
                    label={
                      <FormattedMessage
                        id="general.Base-rate"
                        defaultMessage="Base rate"
                      />
                    }
                    type="number"
                    name="base_rate"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      if (Number(event.target.value) >= 0) {
                        handleOnChangeFormField(event);
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={company.hourly_rate || 0}
                    id="hourly_rate"
                    label={
                      <FormattedMessage
                        id="general.Hourly-rate"
                        defaultMessage="Hourly rate"
                      />
                    }
                    type="number"
                    name="hourly_rate"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      if (Number(event.target.value) >= 0) {
                        handleOnChangeFormField(event);
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="currency-label">
                      <FormattedMessage
                        id="general.Currency"
                        defaultMessage="Currency"
                      />
                    </InputLabel>
                    <Select
                      fullWidth
                      id="currency"
                      name="currency"
                      value={company.currency || ""}
                      label={intl.formatMessage({
                        id: "general.Currency",
                        defaultMessage: "Currency",
                      })}
                      type="text"
                      variant="outlined"
                      onChange={(event) => handleOnChangeFormField(event)}
                    >
                      <MenuItem value="USD">
                        <FormattedMessage
                          id="general.USD"
                          defaultMessage="USD"
                        />
                      </MenuItem>
                      <MenuItem value="CAD">
                        <FormattedMessage
                          id="general.CAD"
                          defaultMessage="CAD"
                        />
                      </MenuItem>
                      <MenuItem value="SEK">
                        <FormattedMessage
                          id="general.SEK"
                          defaultMessage="SEK"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="country"
                    name="country"
                    value={company.country || ""}
                    label={intl.formatMessage({
                      id: "general.Country",
                      defaultMessage: "Country",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="province"
                    name="province"
                    value={company.province || ""}
                    label={intl.formatMessage({
                      id: "general.Province",
                      defaultMessage: "Province",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    value={company.city || ""}
                    label={intl.formatMessage({
                      id: "general.City",
                      defaultMessage: "City",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    id="zipCode"
                    name="zipCode"
                    value={company.zipCode || ""}
                    label={intl.formatMessage({
                      id: "general.Zip-Code",
                      defaultMessage: "Zip Code",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    id="addressLine1"
                    name="addressLine1"
                    value={company.addressLine1 || ""}
                    label={intl.formatMessage({
                      id: "general.Address-Line-1",
                      defaultMessage: "Address Line 1",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    id="addressLine2"
                    name="addressLine2"
                    value={company.addressLine2 || ""}
                    label={intl.formatMessage({
                      id: "general.Address-Line-2",
                      defaultMessage: "Address Line 2",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={12}>
                  <CompaniesAutocomplete
                    selectedCompanyId={extCompany?.id || 0}
                    value={company.invoice_to}
                    handleOnChange={(invoice_to) => {
                      setCompany({
                        ...company,
                        invoice_to,
                      });
                    }}
                  />
                </Grid>
                {Boolean(company.invoice_to) && (
                  <Grid item sm={12}>
                    <TextField
                      fullWidth
                      id="markup_percentage"
                      name="markup_percentage"
                      value={company.markup_percentage || ""}
                      label={intl.formatMessage({
                        id: "general.Markup-Percentage",
                        defaultMessage: "Markup Percentage",
                      })}
                      type="number"
                      variant="outlined"
                      onChange={(event) => {
                        const value = Number(event.target.value);

                        if (value >= 0 && value <= 100) {
                          handleOnChangeFormField(event);
                        }
                      }}
                    />
                  </Grid>
                )}
                <Grid item sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={company.requires_po || false}
                        onChange={(event) => {
                          setCompany({
                            ...company,
                            requires_po: event.target.checked ? true : false,
                          });
                        }}
                        name="requires_po"
                        color="primary"
                      />
                    }
                    label={intl.formatMessage({
                      id: "general.Requires-PO",
                      defaultMessage: "Requires Purchase Order",
                    })}
                  />
                </Grid>
                <Grid item sm={12}>
                  {renderLogo()}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditingCompany && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    <FormattedMessage
                      id="general.Delete-Client"
                      defaultMessage="Delete Client"
                    />
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit">
                  {isEditingCompany ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Create"
                      defaultMessage="Create"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditingCompany) {
            handleDelete(company);
          }
        }}
        text={intl.formatMessage({
          id: "client-modal.Do-you-really-want-to-delete-client",
          defaultMessage: "Do you really want to delete the client?",
        })}
      />
    </div>
  );
};

export default CompanyModal;
