import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";

import store from "./redux/store";
import axios from "axios";

const extUser = localStorage.getItem("user");

if (extUser) {
  axios
    .get(`${process.env.REACT_APP_baseUrl}/users/me`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(extUser).jwt}`,
      },
    })
    .then((res) => {
      store.dispatch({
        type: "LOGIN_USER",
        payload: {
          ...res.data,
          jwt: JSON.parse(extUser).jwt,
        },
      });
    });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
