import axios from "axios";

const OrganizationReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ORGANIZATIONS/GETALL":
      const organizations = action.payload.organizations;
      return [...organizations];
    default:
      return state;
  }
};

export const getOrganizations = () => {
  return async (dispatch: any) => {
    const response = await axios(`/organizations`);
    const organizations = response.data;

    dispatch({
      type: "ORGANIZATIONS/GETALL",
      payload: {
        organizations,
      },
    });
  };
};

// export const changeOrganization = (organizationId: string) => {
//   return async (dispatch: any) => {
//     const response = await axios(`/organizations/${organizationId}`);
//     const organization = response.data;

//     dispatch({
//       type: 'ORGANIZATIONS/CHANGE',
//       payload: {
//         organization,
//       },
//     });
//   };
// };

export default OrganizationReducer;
