import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";

import ConfirmDialog from "../ConfirmDialog";
import { Organization } from "./types";
import {
  createOrganization,
  updateOrganization,
  deleteOrganization,
} from "../../redux/reducerSlices/owned-organization";
import { connectTrello } from "../../redux/reducerSlices/trello";
import { refreshMe } from "../../redux/reducerSlices/me";

const emptyOrganization = {
  id: null,
  name: "",
  trello_token: "",
} as Organization;

const OrganizationModal: React.FC<{
  extOrganization?: Organization | null;
  setOpenModal: any;
  openModal: boolean;
  onCreateCB?: () => void;
}> = ({ extOrganization, setOpenModal, openModal, onCreateCB }) => {
  const isEdit = Boolean(extOrganization?.id);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { user } = useSelector((state: any) => state);

  const [organization, setOrganization] =
    useState<Organization>(emptyOrganization);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!openModal) return null;

    if (extOrganization) {
      setOrganization({
        ...extOrganization,
      });
    }
  }, [extOrganization, setOrganization, openModal]);

  const closeModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setOrganization(emptyOrganization);
    }, 200);
  };

  const handleDelete = (organization: Organization) => {
    dispatch(deleteOrganization(organization.id));
    setOpenConfirmModal(false);
    setOpenModal(false);
    setTimeout(() => {
      setOrganization(emptyOrganization);
    }, 200);
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setOrganization({
        ...organization,
        [event.target.name]: event.target.value,
      });
    } else {
      setOrganization({
        ...organization,
        [name]: event.toString(),
      });
    }
  };

  const validateUser = () => {
    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateUser()) {
      return null;
    }

    setLoading(true);

    if (isEdit) {
      await dispatch(
        updateOrganization({
          ...organization,
        })
      );

      if (organization.id === user.selected_organization_id) {
        await dispatch(refreshMe());
      }
    } else {
      const newOrg = {
        ...organization,
      };

      delete newOrg.id;

      await dispatch(createOrganization(newOrg));

      if (onCreateCB) {
        onCreateCB();
      }
    }

    setLoading(false);
    closeModal();
  };

  const handleOrgTrelloChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setOrganization({
        ...organization,
        trello_token: user.trello_token,
      });
    } else {
      setOrganization({
        ...organization,
        trello_token: "",
      });
    }
  };

  const getModalTitle = () => {
    return isEdit ? (
      <FormattedMessage
        id="organization-modal.Edit-Organization"
        defaultMessage="Edit Organization"
      />
    ) : (
      <FormattedMessage
        id="general.New-Organization"
        defaultMessage="New Organization"
      />
    );
  };

  const renderTrelloConnection = () => {
    if (!Boolean(user.trello_token)) {
      return (
        <Button
          onClick={() => {
            dispatch(connectTrello());
          }}
        >
          <FormattedMessage
            id="general.Connect-Trello"
            defaultMessage="Connect Trello"
          />
        </Button>
      );
    }

    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={handleOrgTrelloChange}
              checked={Boolean(organization.trello_token)}
            />
          }
          label={intl.formatMessage({
            id: "general.Connect-organization-Trello",
            defaultMessage: "Connect Trello",
          })}
        />
      </FormGroup>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    value={organization.name || ""}
                    label={intl.formatMessage({
                      id: "general.Name",
                      defaultMessage: "Name",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={12}>
                  {renderTrelloConnection()}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEdit && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    <FormattedMessage
                      id="general.Delete-Organization"
                      defaultMessage="Delete Organization"
                    />
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit">
                  {isEdit ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Create"
                      defaultMessage="Create"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEdit) {
            handleDelete(extOrganization);
          }
        }}
        text={intl.formatMessage({
          id: "ogranization-modal.Do-you-really-want-to-delete-ogranization",
          defaultMessage: "Do you really want to delete the ogranization?",
        })}
      />
    </div>
  );
};

export default OrganizationModal;
