import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormattedMessage } from "react-intl";

import TicketModal from "../components/Ticket/modal";
import { getProjectOptions } from "../redux/reducerSlices/project";
import { getTickets } from "../redux/reducerSlices/ticket";
import TicketRow from "../components/Ticket/row";

const TicketsTable = (props: any) => {
  const dispatch = useDispatch();

  const tickets = useSelector((state: RootStateOrAny) => state.ticket);

  useEffect(() => {
    dispatch(getTickets());
  }, [dispatch]);

  if (tickets.length <= 0) {
    return (
      <div>
        <FormattedMessage
          id="general.No-tickets-found"
          defaultMessage="No tickets found"
        />
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 100,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Ticket" defaultMessage="Ticket" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="general.Status" defaultMessage="Status" />
            </TableCell>
            <TableCell align="left" colSpan={1}>
              <FormattedMessage id="general.Project" defaultMessage="Project" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets &&
            tickets.map((ticket) => (
              <TicketRow
                key={ticket.id}
                ticket={{
                  ...ticket,
                  handleOpenEdinModal: props.handleOpenEdinModal,
                }}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Tickets = () => {
  const dispatch = useDispatch();

  const { projectOptions } = useSelector(
    (state: RootStateOrAny) => state.project
  );

  const [openModal, setOpenModal] = useState(false);
  const [modalTicket, setModalTicket] = useState({});

  useEffect(() => {
    dispatch(getProjectOptions());
  }, [dispatch]);

  const openNewTicketModal = () => {
    setModalTicket({});
    setOpenModal(true);
  };

  const handleOpenEdinModal = (ticket: any) => {
    setModalTicket(ticket);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    dispatch(getTickets());
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Tickets" defaultMessage="Tickets" />
          </Typography>
          <Button onClick={openNewTicketModal}>
            <FormattedMessage
              id="general.New-Ticket"
              defaultMessage="New Ticket"
            />
          </Button>
        </Box>
        <TicketModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          ticket={modalTicket}
          projects={projectOptions}
          onCloseModal={onCloseModal}
        />
        <TicketsTable handleOpenEdinModal={handleOpenEdinModal} />
      </Box>
    </Container>
  );
};

export default Tickets;
