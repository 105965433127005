import { Task } from "../task/types";

enum ReleaseStatus {
  draft = "draft",
  deployed = "deployed",
  toRedeploy = "toRedeploy",
  released = "released",
  canceled = "canceled",
}

enum ReleaseItemStatus {
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
  canceled = "canceled",
}

interface Release {
  id?: number;
  date: Date | null;
  status: ReleaseStatus;
  project: any;
  notes?: string;
  tasks?: [Task];
  preliminary_notes?: string;
}

export type { Release };

export { ReleaseStatus, ReleaseItemStatus };
