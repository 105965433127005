import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionModal from "../components/subscription/modal";
import SubscriptionRow from "../components/subscription/row";
import TablePaginationActions from "../components/TablePaginationActions";
import { getCompanies } from "../redux/reducerSlices/company";
import { getSubscriptions } from "../redux/reducerSlices/subscription";

const Subscriptions = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { subscriptionState } = useSelector((state: any) => state);
  const { subscriptions, pagination } = subscriptionState;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalSubscription, setModalSubscription] = useState<any>(null);

  useEffect(() => {
    dispatch(getSubscriptions());
    dispatch(getCompanies(1, 100));
  }, [dispatch]);

  const handleOpenEdinModal = (subscription: any) => {
    setModalSubscription(subscription);
    setOpenModal(true);
  };

  const handleSubsChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getSubscriptions(newPage + 1, pagination.pageSize));
  };

  const handleSubsChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getSubscriptions(1, parseInt(event.target.value, 10)));
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage
              id="general.Subscriptions"
              defaultMessage="Subscriptions"
            />
          </Typography>
          <Button onClick={() => handleOpenEdinModal(null)}>
            <FormattedMessage
              id="general.New-Subscription"
              defaultMessage="New Subscription"
            />
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Title" defaultMessage="Title" />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage
                    id="general.Active"
                    defaultMessage="Active"
                  />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage
                    id="general.Client"
                    defaultMessage="Client"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions?.map((subscription: any) => {
                return (
                  <SubscriptionRow
                    key={subscription.id}
                    subscription={{
                      ...subscription,
                      handleOpenEdinModal,
                    }}
                  />
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pagination.total}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.page - 1}
                  SelectProps={{
                    inputProps: {
                      "aria-label": intl.formatMessage({
                        id: "general.Subscriptions-per-page",
                        defaultMessage: "Subscriptions per page",
                      }),
                    },
                    native: true,
                  }}
                  labelRowsPerPage={intl.formatMessage({
                    id: "general.Subscriptions-per-page",
                    defaultMessage: "Subscriptions per page",
                  })}
                  onPageChange={handleSubsChangePage}
                  onRowsPerPageChange={handleSubsChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} ${intl.formatMessage({
                      id: "pagination.of",
                      defaultMessage: "of",
                    })} ${count}`;
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <SubscriptionModal
        openModal={openModal}
        extSubscription={modalSubscription}
        setOpenModal={setOpenModal}
      />
    </Container>
  );
};

export default Subscriptions;
