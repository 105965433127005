import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';
import { styles } from '../styles/quotePdfStyles';

export const BackgroundSymbol = () => (
  <Svg style={styles.backgroundSymbol} viewBox="0 0 148.7 138.8" fixed>
    <Path
      d="M74.1,138.8c-10.7,0-20.8-1.8-29.8-5.4c-9.1-3.6-17-8.6-23.5-14.9c-6.5-6.3-11.7-13.7-15.3-22C1.8,88.2,0,79.2,0,69.9v-0.5C0,60,1.9,51.1,5.6,42.7c3.7-8.4,8.9-15.8,15.4-22.1c6.5-6.3,14.4-11.3,23.5-15C53.6,1.9,63.7,0,74.6,0c10.7,0,20.8,1.8,29.8,5.4c9.1,3.6,17,8.6,23.5,14.9c6.5,6.3,11.7,13.7,15.3,22c3.6,8.3,5.5,17.3,5.5,26.6v0.5c0,9.4-1.9,18.4-5.6,26.7c-3.7,8.4-8.9,15.8-15.4,22.1c-6.5,6.3-14.5,11.3-23.6,15C95,136.9,84.9,138.8,74.1,138.8z M74.6,4C64.2,4,54.6,5.8,46,9.3c-8.6,3.5-16.1,8.3-22.2,14.2c-6.1,5.9-11,12.9-14.5,20.8C5.8,52.2,4,60.6,4,69.4v0.5c0,8.8,1.7,17.2,5.1,25c3.4,7.8,8.3,14.8,14.4,20.7c6.2,5.9,13.6,10.7,22.2,14.1c8.6,3.4,18.1,5.2,28.4,5.2c10.2,0,19.8-1.8,28.5-5.3c8.7-3.5,16.2-8.3,22.3-14.2c6.1-5.9,11-12.9,14.5-20.8c3.5-7.9,5.2-16.3,5.2-25.1v-0.5c0-8.8-1.7-17.2-5.1-25l0,0c-3.4-7.8-8.3-14.8-14.4-20.7C119,17.3,111.6,12.6,103,9.2C94.4,5.7,84.8,4,74.6,4z M108.1,90.1H40.6l33.7-57.9L108.1,90.1z M47.6,86.1h53.6L74.3,40.1L47.6,86.1z"
      fill="#E24D2A"
      fillOpacity="0.15"
    />
  </Svg>
);
