import moment from 'moment';
import { Quote } from '../../../components/task/types';

export const formatDate = (date: Date | null, locale: string) => {
  if (!date) return '';
  return moment(date).locale(locale).format('LL');
};

export const formatDeliveryTime = (days: number | null | undefined, intl: any) => {
  if (!days) return null;
  if (days % 7 === 0) {
    const weeks = days / 7;
    return `${weeks} ${weeks === 1 ? 
      intl.formatMessage({ id: 'quote.pdf.week' }) : 
      intl.formatMessage({ id: 'quote.pdf.weeks' })}`;
  }
  return `${days} ${days === 1 ? 
    intl.formatMessage({ id: 'quote.pdf.day' }) : 
    intl.formatMessage({ id: 'quote.pdf.days' })}`;
};

export const getCurrencyText = (currency: string | undefined, intl: any) => {
  if (!currency) return '';
  const currencyMap: { [key: string]: string } = {
    SEK: intl.formatMessage({ id: 'quote.pdf.currency.SEK' }),
    CAD: intl.formatMessage({ id: 'quote.pdf.currency.CAD' }),
    USD: intl.formatMessage({ id: 'quote.pdf.currency.USD' })
  };
  return currencyMap[currency] || currency;
};

export const getProjectName = (quote: Quote | null) => {
  const projectName = quote?.quote_items?.find(item => item.task?.project?.name)?.task?.project?.name;
  return projectName || quote?.company?.name || '';
};
