import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

import ConfirmDialog from "../ConfirmDialog";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const PaymentModal: React.FC<{
  setOpenModal: any;
  openModal: boolean;
  onSubscribeCB: () => void;
  title: string;
}> = ({ setOpenModal, openModal, onSubscribeCB, title }) => {
  const { user } = useSelector((state: any) => state);
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cardFilled, setCardFilled] = useState<boolean>(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({});
  const [stripeCards, setStripeCards] = useState<any>([]);
  const [defaultCard, setDefaultCard] = useState<any>(null);
  const [modalLoading, setModalLoading] = useState<boolean>(true);
  const [payCardId, setPayCardId] = useState<string>("new");

  const handlePayCardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayCardId((event.target as HTMLInputElement).value);
  };

  const getSubscriptionPrice = async () => {
    const res = await axios.get("/cs/get-subscription-price");

    setSubscriptionDetails(res.data);
  };

  const getStripeCards = async () => {
    const res = await axios("/cs/get-stripe-user-cards");
    const {
      customer,
      cards: { data: cards },
    } = res.data;
    let defaultCard = null;

    if (cards) {
      setStripeCards(
        cards.filter((card: any) => {
          if (card.id === customer.invoice_settings.default_payment_method) {
            defaultCard = card;

            return false;
          }

          return true;
        })
      );
      setDefaultCard(defaultCard);
      setPayCardId(defaultCard?.id || "new");
    }
    setModalLoading(false);
  };

  useEffect(() => {
    if (openModal) {
      getStripeCards();
    }
  }, [openModal]);

  useEffect(() => {
    if (["initial", "canceled", "unpaid"].includes(user.subscription_status)) {
      getSubscriptionPrice();
    }
  }, [user.subscription_status]);

  const createSubscription = async () => {
    try {
      let paymentMethodId = payCardId;

      if (!paymentMethodId || paymentMethodId === "new") {
        const stripeRes = await stripe.createPaymentMethod({
          card: elements.getElement("card"),
          type: "card",
        });

        paymentMethodId = stripeRes.paymentMethod.id;

        await axios.post("/cs/add-stripe-user-card", {
          payment_method_id: stripeRes.paymentMethod.id,
        });
      }

      if (paymentMethodId !== defaultCard?.id) {
        await axios.post("/cs/update-stripe-user-default-card", {
          card_id: paymentMethodId,
        });
      }

      const response = await axios.post("/cs/create-stripe-subscription", {
        payment_method: paymentMethodId,
      });

      if (response.data.subscription.latest_invoice.payment_intent) {
        const confirm = await stripe.confirmCardPayment(
          response.data.subscription.latest_invoice.payment_intent
            ?.client_secret
        );

        return confirm;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleDelete = (organization: any) => {
    setOpenConfirmModal(false);
    setOpenModal(false);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!cardFilled && payCardId === "new") {
      return alert("Please fill out the card details");
    }

    setLoading(true);

    try {
      await createSubscription();
      toast.success("You have successfully subscribed");
      onSubscribeCB();
      setLoading(false);
      closeModal();
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong");
      console.error(e);
    }
  };

  const renderActions = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 30,
            maxWidth: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <div>
        <Button
          onClick={(event) => {
            event.stopPropagation();

            closeModal();
          }}
        >
          <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={handleSubmit}>
          <FormattedMessage id="general.Subscribe" defaultMessage="Subscribe" />
        </Button>
      </div>
    );
  };

  if (modalLoading) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <Box
          style={{
            width: 600,
            maxWidth: "100%",
          }}
        >
          <DialogContent>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              spacing={1}
            >
              {Boolean(subscriptionDetails.amount) && (
                <Grid item xs={12}>
                  <FormattedMessage
                    id="general.You-will-be-charged-price-per-month-per-user"
                    defaultMessage="You will be charged {price} per month per user. You can cancel at any time."
                    values={{
                      price:
                        "$" + (subscriptionDetails.amount / 100).toFixed(2),
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>
                    <FormattedMessage
                      id="general.Payment-Method"
                      defaultMessage="Payment Method"
                    />
                  </FormLabel>
                  <RadioGroup
                    name="pay-card-id"
                    value={payCardId}
                    onChange={handlePayCardChange}
                  >
                    {defaultCard && (
                      <FormControlLabel
                        value={defaultCard.id}
                        control={<Radio />}
                        label={`${capitalizeFirstLetter(
                          defaultCard.card.brand
                        )} ending in ${defaultCard.card.last4}`}
                      />
                    )}

                    {stripeCards.map((card: any) => {
                      return (
                        <FormControlLabel
                          key={card.id}
                          value={card.id}
                          control={<Radio />}
                          label={`${capitalizeFirstLetter(
                            card.card.brand
                          )} ending in ${card.card.last4}`}
                        />
                      );
                    })}

                    <FormControlLabel
                      value="new"
                      control={<Radio />}
                      label={
                        <FormattedMessage
                          id="general.Add-new-card"
                          defaultMessage="Add new card"
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {Boolean(payCardId === "new") && (
                <Grid item xs={12}>
                  <CardElement
                    onChange={(e) => {
                      setCardFilled(e.complete);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 24px 20px",
            }}
          >
            <div>{/* empty */}</div>
            {renderActions()}
          </DialogActions>
        </Box>
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleDelete({});
        }}
        text={intl.formatMessage({
          id: "ogranization-modal.Do-you-really-want-to-delete-ogranization",
          defaultMessage: "Do you really want to delete the ogranization?",
        })}
      />
    </div>
  );
};

export default PaymentModal;
