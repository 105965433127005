import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';
import { formatDate } from '../../utils/helpers';

interface ValiditySectionProps {
  quote: Quote | null;
}

export const ValiditySection: React.FC<ValiditySectionProps> = ({ quote }) => {
  const intl = useIntl();

  const getValidityText = () => {
    const params = {
      company: quote?.company?.name,
      date: formatDate(quote?.date, intl.locale),
      expirationDate: formatDate(quote?.expiration_date, intl.locale)
    };

    if (quote?.company?.contactFullName) {
      return intl.formatMessage(
        { id: 'quote.pdf.validityText' },
        {
          ...params,
          contact: quote.company.contactFullName
        }
      );
    }

    return intl.formatMessage(
      { id: 'quote.pdf.validityTextNoContact' },
      params
    );
  };

  return (
    <View style={styles.section}>
      <View style={styles.sectionTitleGroup}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.validity' })}
        </Text>
        <Text style={styles.partyText}>
          {getValidityText()}
        </Text>
      </View>
    </View>
  );
};
