import axios from "axios";
import { toast } from "react-hot-toast";

const roleReducer = (state = [], action: any) => {
  switch (action.type) {
    case "GET_ROLES":
      return [...action.payload].filter((role: any) => role.type !== "public");
    default:
      return state;
  }
};

export const getRoles = () => {
  return async (dispatch: any) => {
    try {
      const res = await axios.get("/users-permissions/roles");

      dispatch({
        type: "GET_ROLES",
        payload: res.data.roles,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not get roles. Reason: ${String(err)}`);
    }
  };
};

export default roleReducer;
