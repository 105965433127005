import axios from "axios";
import { toast } from "react-hot-toast";
import { getIntl } from "../../GlobalIntlProvider";

let initialState = {};
const extUser = localStorage.getItem("user");

if (extUser) {
  initialState = {
    role: {},
    jwt: JSON.parse(extUser).jwt,
  };
}

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LOGIN_USER":
    case "REFRESH_ME":
      const user = {
        ...state,
        ...action.payload,
      };

      localStorage.setItem("user", JSON.stringify(user));

      return user;
    default:
      return state;
  }
};

export const changeLanguage = (langCode: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.put(`/users/me`, {
        language: langCode,
      });

      dispatch({
        type: "REFRESH_ME",
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not change language. Reason: ${String(err)}`);
    }
  };
};

export const connectTrelloUser = ({ trelloToken = "", trelloId = "" }) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.put(`/users/me`, {
        trello_token: trelloToken,
        trello_id: trelloId,
      });

      dispatch({
        type: "REFRESH_ME",
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not change language. Reason: ${String(err)}`);
    }
  };
};

export const changeOrganization = (selected_organization_id: number) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.put(`/users/change-organization`, {
        selected_organization_id,
      });

      dispatch({
        type: "REFRESH_ME",
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not change language. Reason: ${String(err)}`);
    }
  };
};

export const refreshMe = (callback?: () => void) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.get(`/users/me`);

      dispatch({
        type: "REFRESH_ME",
        payload: res.data,
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      toast.error(`Could not change language. Reason: ${String(err)}`);
    }
  };
};

export const updateMe = (data: any) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.put(`/users/me`, data);

      dispatch({
        type: "REFRESH_ME",
        payload: res.data,
      });

      toast.success(
        getIntl().formatMessage({
          id: "success.changes-saved",
          defaultMessage: "Changes saved",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "error.could-not-save-changes",
            defaultMessage: "Could not save changes. Reason: {reason}",
          },
          { reason: String(err) }
        )
      );
    }
  };
};

export default userReducer;
