import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CompanyModal from "../components/company/modal";
import CompanyRow from "../components/company/row";
import TablePaginationActions from "../components/TablePaginationActions";
import { getCompanies } from "../redux/reducerSlices/company";

const CompaniesPage = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    companyState: { companies, pagination },
  } = useSelector((state: any) => state);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalCompany, setModalCompany] = useState<any>(null);

  useEffect(() => {
    dispatch(getCompanies(1, 10));
  }, [dispatch]);

  const handleOpenEdinModal = (company: any) => {
    setModalCompany(company);
    setOpenModal(true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getCompanies(newPage + 1, pagination.pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getCompanies(1, parseInt(event.target.value, 10)));
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Clients" defaultMessage="Clients" />
          </Typography>
          <Button onClick={() => handleOpenEdinModal(null)}>
            <FormattedMessage
              id="general.New-Client"
              defaultMessage="New Client"
            />
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Name" defaultMessage="Name" />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage id="general.Email" defaultMessage="Email" />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage
                    id="general.Country"
                    defaultMessage="Country"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies?.map((company: any) => {
                return (
                  <CompanyRow
                    key={company.id}
                    company={{
                      ...company,
                      handleOpenEdinModal,
                    }}
                  />
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pagination.total}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.page - 1}
                  SelectProps={{
                    inputProps: {
                      "aria-label": intl.formatMessage({
                        id: "general.Clients-per-page",
                        defaultMessage: "Clients per page",
                      }),
                    },
                    native: true,
                  }}
                  labelRowsPerPage={intl.formatMessage({
                    id: "general.Clients-per-page",
                    defaultMessage: "Clients per page",
                  })}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} ${intl.formatMessage({
                      id: "pagination.of",
                      defaultMessage: "of",
                    })} ${count}`;
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <CompanyModal
        openModal={openModal}
        extCompany={modalCompany}
        setOpenModal={setOpenModal}
      />
    </Container>
  );
};

export default CompaniesPage;
