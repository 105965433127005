import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { useIntl } from 'react-intl';
import { Quote } from '../../../../components/task/types';

interface IntellectualPropertySectionProps {
  quote: Quote | null;
}

export const IntellectualPropertySection: React.FC<IntellectualPropertySectionProps> = ({ quote }) => {
  const intl = useIntl();
  
  // Find first quote item that has a task with a project that has intellectualProperty set
  const project = quote?.quote_items?.find(item => 
    item.task?.project?.intellectualProperty
  )?.task?.project;
  
  // Skip section if IP is unspecified or project is not available
  if (!project?.intellectualProperty || project.intellectualProperty === 'unspecified') {
    return null;
  }

  const messageId = project.intellectualProperty === 'supplier' 
    ? 'quote.pdf.ipTextSupplier' 
    : 'quote.pdf.ipText';

  return (
    <View style={styles.section}>
      <View style={styles.sectionTitleGroup} wrap={false}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.intellectualProperty' })}
        </Text>
        <Text style={styles.partyText}>
          {intl.formatMessage({ id: messageId })}
        </Text>
      </View>
    </View>
  );
};
