import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    color: {
      primary: string;
      brand: string;
    };
  }
  interface ThemeOptions {
    color?: {
      primary?: string;
      brand?: string;
    };
  }
}

const theme = createTheme({
  color: {
    primary: "#1976d2",
    brand: "#e14d29",
  },
});

export default theme;
