import { TableCell, TableRow } from "@mui/material";

const OrganizationRow: React.FC<{
  handleOpenEdinModal: any;
  organization: {
    id?: number;
    name?: String;
  };
  clickable?: boolean;
}> = ({ organization, handleOpenEdinModal, clickable = false }, props) => {
  const handleEditOrganization = () => {
    handleOpenEdinModal(organization);
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: clickable ? "pointer" : "default" }}
      onClick={(event) => {
        if (!clickable) return;

        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditOrganization();
      }}
    >
      <TableCell component="th" scope="row">
        {organization.name}
      </TableCell>
    </TableRow>
  );
};

export default OrganizationRow;
