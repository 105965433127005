import { getIntl } from "../../GlobalIntlProvider";

interface Role {
  id: number;
  name: string;
  description: string;
  type: string;
}

interface User {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  language: "en" | "fr" | "sv";
  role?: Role;
}

export const renderRole = (role_name) => {
  switch (role_name) {
    case "Developer":
      return getIntl().formatMessage({
        id: "user.role.Developer",
        defaultMessage: "Developer",
      });
    case "Admin":
      return getIntl().formatMessage({
        id: "user.role.Admin",
        defaultMessage: "Admin",
      });
    default:
      console.error("unexpected role: ", role_name);
      return getIntl().formatMessage({
        id: "user.role.unexpected-role",
        defaultMessage: "unexpected role",
      });
  }
};

export type { User, Role };
