import { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TableFooter, TablePagination } from "@mui/material";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormattedMessage, useIntl } from "react-intl";

import ProjectModal from "../components/Project/modal";
import { getProjects } from "../redux/reducerSlices/project";
import ProjectRow from "../components/Project/row";
import { getTrelloBoards } from "../redux/reducerSlices/trello";
import { getOwnedOrganizations } from "../redux/reducerSlices/owned-organization";
import { updateLayout } from "../redux/reducerSlices/layout";
import { getDeveloperInvites } from "../redux/reducerSlices/invite";
import { getCompanies } from "../redux/reducerSlices/company";
import TablePaginationActions from "../components/TablePaginationActions";

const ProjectsTable = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { projects, pagination } = useSelector(
    (state: RootStateOrAny) => state.project
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(
      getProjects({
        page: newPage + 1,
        pageSize: pagination.pageSize,
        archived: true,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      getProjects({
        page: 1,
        pageSize: parseInt(event.target.value, 10),
        archived: true,
      })
    );
  };

  if (projects.length <= 0) {
    return (
      <div>
        <FormattedMessage
          id="general.No-projects-found"
          defaultMessage="No projects found"
        />
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 100,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Project" defaultMessage="Project" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects &&
            projects.map((project) => (
              <ProjectRow
                key={project.id}
                project={{
                  ...project,
                  handleOpenEdinModal: props.handleOpenEdinModal,
                }}
              />
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={pagination.total}
              rowsPerPage={pagination.pageSize}
              page={pagination.page - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": intl.formatMessage({
                    id: "general.Projects-per-page",
                    defaultMessage: "Projects per page",
                  }),
                },
                native: true,
              }}
              labelRowsPerPage={intl.formatMessage({
                id: "general.Projects-per-page",
                defaultMessage: "Projects per page",
              })}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${intl.formatMessage({
                  id: "pagination.of",
                  defaultMessage: "of",
                })} ${count}`;
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const Projects = () => {
  const dispatch = useDispatch();
  const {
    ownedOrganizationState,
    user,
    layout: { modalProject },
  } = useSelector((state: RootStateOrAny) => state);

  const ownsCurrOrg = ownedOrganizationState.organizations.find((o: any) => {
    return o.id === user.selected_organization_id;
  });

  useEffect(() => {
    dispatch(getOwnedOrganizations());
    dispatch(getDeveloperInvites());
    dispatch(getCompanies(1, 100));
  }, [dispatch]);

  useEffect(() => {
    if (ownsCurrOrg) {
      if (user.trello_token) {
        window.Trello.setToken(user.trello_token);

        try {
          dispatch(getTrelloBoards());
        } catch (e: any) {
          console.error("Trello connection error", e);
        }
      } else {
        window.Trello.deauthorize();
      }
    }
  }, [user.trello_token, dispatch, ownsCurrOrg]);

  useEffect(() => {
    dispatch(getProjects({ archived: true, page: 1, pageSize: 10 }));
  }, [dispatch]);

  const openNewProjectModal = () => {
    dispatch(
      updateLayout({
        openProjectModal: true,
        modalProject: {},
      })
    );
  };

  const handleOpenEdinModal = (project: any) => {
    dispatch(
      updateLayout({
        openProjectModal: true,
        modalProject: project,
      })
    );
  };

  const onCloseModal = () => {
    dispatch(getProjects({ archived: true }));
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Projects" defaultMessage="Projects" />
          </Typography>
          <Button onClick={openNewProjectModal}>
            <FormattedMessage
              id="general.New-Project"
              defaultMessage="New Project"
            />
          </Button>
        </Box>
        <ProjectModal
          project={modalProject}
          onCloseModal={onCloseModal}
          ownsCurrOrg={ownsCurrOrg}
        />
        <ProjectsTable handleOpenEdinModal={handleOpenEdinModal} />
      </Box>
    </Container>
  );
};

export default Projects;
