import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { updateMe } from "../../../redux/reducerSlices/me";

const BasicInformation = () => {
  const { user } = useSelector((state: any) => state);
  const intl = useIntl();
  const dispatch = useDispatch();

  const [uptUser, setUptUser] = React.useState<any>({ ...user });

  const hasChanges = () => {
    return !(
      user.firstName === uptUser.firstName && user.lastName === uptUser.lastName
    );
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setUptUser({
        ...uptUser,
        [event.target.name]: event.target.value,
      });
    } else {
      setUptUser({
        ...uptUser,
        [name]: event.toString(),
      });
    }
  };

  const handleupdateMe = () => {
    dispatch(updateMe(uptUser));
  };

  return (
    <Box style={{ marginTop: 0 }}>
      <Typography variant="h5">
        <FormattedMessage
          id="general.Basic-information"
          defaultMessage="Basic information"
        />
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item sm={6}>
          <TextField
            value={uptUser.firstName}
            id="firstName"
            label={
              <FormattedMessage
                id="general.First-name"
                defaultMessage="First name"
              />
            }
            type="firstName"
            name="firstName"
            fullWidth
            required
            variant="outlined"
            onChange={(event) => handleOnChangeFormField(event)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            value={uptUser.lastName}
            id="lastName"
            label={
              <FormattedMessage
                id="general.Last-name"
                defaultMessage="Last name"
              />
            }
            type="lastName"
            name="lastName"
            fullWidth
            required
            variant="outlined"
            onChange={(event) => handleOnChangeFormField(event)}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            value={uptUser.email}
            id="email"
            label={
              <FormattedMessage id="general.Email" defaultMessage="Email" />
            }
            type="email"
            name="email"
            fullWidth
            disabled
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!hasChanges()}
          onClick={() => {
            handleupdateMe();
          }}
        >
          <FormattedMessage id="general.Save" defaultMessage="Save" />
        </Button>
      </Box>
      <Prompt
        message={() => {
          return !hasChanges()
            ? true
            : intl.formatMessage({
                id: "general.Are-you-sure-you-want-to-leave-the-page",
                defaultMessage:
                  "Are you sure you want to leave this page? You have unsaved changes.",
              });
        }}
      />
    </Box>
  );
};

export default BasicInformation;
