import React from 'react';
import { Document } from '@react-pdf/renderer';
import { Quote } from '../../components/task/types';
import { IntlProvider } from 'react-intl';
import languages, { DEFAULT_LANGUAGE } from '../../languages';
import { registerFonts } from './utils/fonts';
import { FirstPage } from './components/FirstPage';
import { ContentPage } from './components/ContentPage';

// Register fonts
registerFonts();

interface QuotePDFProps {
  quote: Quote | null;
}

const QuotePDFContent = ({ quote }: QuotePDFProps) => {
  return (
    <Document
      title={quote?.number ? `Quote ${quote.number}` : 'Quote'}
    >
      <FirstPage quote={quote} />
      <ContentPage quote={quote} />
    </Document>
  );
};

const QuotePDF = ({ quote }: QuotePDFProps) => {
  // Use client's language preference from company data, fallback to default if not available
  const requestedLocale = quote?.company?.language || DEFAULT_LANGUAGE;
  const locale = Object.keys(languages).includes(requestedLocale) ? requestedLocale : DEFAULT_LANGUAGE;
  const messages = languages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale={DEFAULT_LANGUAGE}>
      <QuotePDFContent quote={quote} />
    </IntlProvider>
  );
};

export default QuotePDF;
