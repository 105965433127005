import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-hot-toast";

import ConfirmDialog from "../../../components/ConfirmDialog";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const AddNewCardModal: React.FC<{
  setOpenModal: any;
  openModal: boolean;
  onAddCallback: () => void;
}> = ({ setOpenModal, openModal, onAddCallback }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState<boolean>(false);
  const [cardFilled, setCardFilled] = useState<boolean>(false);

  const addCard = async () => {
    try {
      const stripeRes = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });

      await axios.post("/cs/add-stripe-user-card", {
        payment_method_id: stripeRes.paymentMethod.id,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!cardFilled) {
      return alert("Please fill out the card details");
    }

    setLoading(true);

    try {
      await addCard();
      toast.success("Your card was added successfully");
      onAddCallback();
      setLoading(false);
      closeModal();
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong");
      console.error(e);
    }
  };

  const getModalTitle = () => {
    return (
      <FormattedMessage
        id="general.Add-new-card"
        defaultMessage="Add new card"
      />
    );
  };

  const renderActions = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 30,
            maxWidth: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <div>
        <Button
          onClick={(event) => {
            event.stopPropagation();

            closeModal();
          }}
        >
          <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={handleSubmit}>
          <FormattedMessage id="general.Add" defaultMessage="Add" />
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        <Box
          style={{
            width: 600,
          }}
        >
          <DialogContent>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item xs={12}>
                <CardElement
                  onChange={(e) => {
                    setCardFilled(e.complete);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 24px 20px",
            }}
          >
            <div>{/* empty */}</div>
            {renderActions()}
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

const CardRow: React.FC<{
  card: any;
  removeCard: any;
  getStripeCards: any;
}> = ({ card, removeCard, getStripeCards }, props) => {
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);

  const handleUpdateDefaultCard = async () => {
    setLoading(true);

    try {
      await axios.post("/cs/update-stripe-user-default-card", {
        card_id: card.id,
      });

      await getStripeCards();

      toast.success(
        intl.formatMessage({
          id: "general.Default-card-updated",
          defaultMessage: "Default card updated",
        })
      );
    } catch (e: any) {
      console.error(e);

      toast.error(e.response.data.message);
    }

    setLoading(false);
  };

  const renderActions = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 30,
            maxWidth: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            removeCard(card.id);
          }}
          color="error"
          style={{
            marginRight: 10,
          }}
        >
          <FormattedMessage id="general.Remove" defaultMessage="Remove" />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            handleUpdateDefaultCard();
          }}
          color="success"
          style={{
            marginRight: 10,
          }}
        >
          <FormattedMessage
            id="general.Make-default"
            defaultMessage="Make default"
          />
        </Button>
      </Box>
    );
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row">
        XXXX XXXX XXXX {card.card.last4}
      </TableCell>
      <TableCell component="th" scope="row">
        {card.card.brand}
      </TableCell>
      <TableCell component="th" scope="row">
        {card.card.exp_month}/{card.card.exp_year}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(card.created * 1000).format("LL")}
      </TableCell>
      <TableCell component="th" scope="row" style={{ textAlign: "right" }}>
        {renderActions()}
      </TableCell>
    </TableRow>
  );
};

const CardsTab = () => {
  const intl = useIntl();

  const [stripeCards, setStripeCards] = useState<any>([]);
  const [defaultCard, setDefaultCard] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [cardId, setCardId] = useState<string>("");
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const getStripeCards = async () => {
    const res = await axios("/cs/get-stripe-user-cards");
    const {
      customer,
      cards: { data: cards },
    } = res.data;
    let defaultCard = null;

    if (cards) {
      setStripeCards(
        cards.filter((card: any) => {
          if (card.id === customer.invoice_settings.default_payment_method) {
            defaultCard = card;

            return false;
          }

          return true;
        })
      );
      setDefaultCard(defaultCard);
    }
    setPageLoading(false);
  };

  React.useEffect(() => {
    getStripeCards();
  }, []);

  const handleCancelSub = async () => {
    try {
      setLoadingCancel(true);

      await axios.post("/cs/delete-stripe-user-card", {
        card_id: cardId,
      });

      await getStripeCards();

      toast.success(
        intl.formatMessage({
          id: "general.Card-removed",
          defaultMessage: "Card removed",
        })
      );
    } catch (e: any) {
      console.error(e);

      toast.error(e.response.data.message);
    } finally {
      setCardId("");
      setOpenConfirmModal(false);
      setLoadingCancel(false);
    }
  };

  const renderDefaultCard = () => {
    if (!defaultCard) return null;

    // MUI two columns Table
    return (
      <Box style={{ maxWidth: 400, marginBottom: 20 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h5">
            <FormattedMessage
              id="general.Default-Card"
              defaultMessage="Default Card"
            />
          </Typography>
          <Button
            color="error"
            onClick={() => {
              setCardId(defaultCard.id);
              setOpenConfirmModal(true);
            }}
          >
            <FormattedMessage id="general.Remove" defaultMessage="Remove" />
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="general.Card-number"
                    defaultMessage="Card number"
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  XXXX XXXX XXXX {defaultCard.card.last4}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Brand" defaultMessage="Brand" />
                </TableCell>
                <TableCell component="th" scope="row">
                  {defaultCard.card.brand}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="general.Expires"
                    defaultMessage="Expires"
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {defaultCard.card.exp_month}/{defaultCard.card.exp_year}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Added" defaultMessage="Added" />
                </TableCell>
                <TableCell component="th" scope="row">
                  {moment(defaultCard.created * 1000).format("LL")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  if (pageLoading) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 650,
          height: 140,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box style={{ marginTop: 0 }}>
      {renderDefaultCard()}

      {Boolean(defaultCard) && <Divider style={{ marginBottom: 10 }} />}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">
          <FormattedMessage id="general.Cards" defaultMessage="Cards" />
        </Typography>
        <Button onClick={() => setOpenAddCardModal(true)}>
          <FormattedMessage
            id="general.Add-New-Card"
            defaultMessage="Add New Card"
          />
        </Button>
      </Box>

      {Boolean(stripeCards.length) && (
        <TableContainer component={Paper} style={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="general.Card-number"
                    defaultMessage="Card number"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="general.Brand" defaultMessage="Brand" />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="general.Expires"
                    defaultMessage="Expires"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="general.Added" defaultMessage="Added" />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stripeCards.map((card: any) => {
                return (
                  <CardRow
                    key={card.id}
                    card={{
                      ...card,
                    }}
                    getStripeCards={getStripeCards}
                    removeCard={(cardId: string) => {
                      setCardId(cardId);
                      setOpenConfirmModal(true);
                    }}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ConfirmDialog
        loading={loadingCancel}
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleCancelSub();
        }}
        text={intl.formatMessage({
          id: "general.Are-you-sure-you-want-to-remove-the-card",
          defaultMessage: "Are you sure you want to remove the card?",
        })}
      />
      <AddNewCardModal
        onAddCallback={getStripeCards}
        openModal={openAddCardModal}
        setOpenModal={setOpenAddCardModal}
      />
    </Box>
  );
};

export default CardsTab;
