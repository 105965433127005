import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';

interface PartiesSectionProps {
  quote: Quote | null;
}

export const PartiesSection: React.FC<PartiesSectionProps> = ({ quote }) => {
  const intl = useIntl();

  return (
    <View style={styles.section}>
      <View style={styles.sectionTitleGroup}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.parties' })}
        </Text>
        <View style={styles.partiesGrid}>
          <View style={styles.partyBox}>
            <Text style={styles.partyTitle}>
              {intl.formatMessage({ id: 'quote.pdf.supplier' })}
            </Text>
            <Text style={styles.partyText}>Gotologic AB</Text>
            <Text style={styles.partyText}>Palandergatan 17</Text>
            <Text style={styles.partyText}>121 37 Johanneshov</Text>
            <Text style={styles.partyText}>Sweden</Text>
          </View>
          {quote?.company?.invoice_to && (
            <View style={styles.partyBox}>
              <Text style={styles.partyTitle}>
                {intl.formatMessage({ id: 'quote.pdf.inPartnershipWith' })}
              </Text>
              <Text style={styles.partyText}>{quote.company.invoice_to.name}</Text>
            </View>
          )}
          <View style={styles.partyBox}>
            <Text style={styles.partyTitle}>
              {intl.formatMessage({ id: 'quote.pdf.client' })}
            </Text>
            <Text style={styles.partyText}>{quote?.company?.name}</Text>
            <Text style={styles.partyText}>{quote?.company?.addressLine1}</Text>
            <Text style={styles.partyText}>{quote?.company?.addressLine2}</Text>
            <Text style={styles.partyText}>{quote?.company?.city} {quote?.company?.zipCode}</Text>
            <Text style={styles.partyText}>{quote?.company?.country}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
