import { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import { Box, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { pdf } from '@react-pdf/renderer';
import QuotePDF from '../pdf/QuotePDF';
import { fetchSingleQuoteWithouState } from "../../redux/reducerSlices/quote";
import { Quote } from "../task/types";

interface RowQuote {
  number: number;
  status: string;
  id?: string;
  handleOpenEdinModal: any;
  trello_url: string;
  project: any | null;
  company: any;
  date: string;
}

const Row: React.FC<{
  quote: RowQuote;
}> = ({ quote }, props) => {
  const { number, status, handleOpenEdinModal, company, date } = quote;
  const [loading, setLoading] = useState(false);

  const handleEditQuote = () => {
    handleOpenEdinModal(quote);
  };

  const getFileName = (completeQuote: Quote) => {
    // Use French name for Canadian clients, English for others
    return completeQuote.company?.country === 'Canada'
      ? `Devis Gotologic ${completeQuote.number}.pdf`
      : `Gotologic Quote ${completeQuote.number}.pdf`;
  };

  const handlePrintClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.nativeEvent.stopPropagation();

    if (!quote.id || loading) return;

    try {
      setLoading(true);
      const completeQuote = await fetchSingleQuoteWithouState(quote.id);
      
      // Generate PDF blob
      const doc = <QuotePDF quote={completeQuote} />;
      const blob = await pdf(doc).toBlob();
      
      // Create download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = getFileName(completeQuote);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error generating PDF:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderStatus = () => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "sent":
        return (
          <FormattedMessage id="general-status.sent" defaultMessage="sent" />
        );
      case "closed":
        return (
          <FormattedMessage
            id="general-status.closed"
            defaultMessage="closed"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditQuote();
      }}
    >
      <TableCell component="th" scope="row">
        #{number}
      </TableCell>
      <TableCell component="th" scope="row">
        {company?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {renderStatus()}
      </TableCell>
      <TableCell component="th" scope="row">
        {date}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            disabled={loading}
            onClick={handlePrintClick}
          >
            <PrintIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Row;
