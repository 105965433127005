import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 65,
    paddingTop: 80,
    backgroundColor: '#fff',
    fontFamily: 'Mulish',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  firstPage: {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#fff',
    fontFamily: 'Mulish',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  firstPageContent: {
    paddingLeft: 40,
    paddingRight: 40,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  backgroundSymbol: {
    position: 'absolute',
    top: 90,
    right: -60,
    width: 275,
    height: 275
  },
  contentWrapper: {
    flex: 1
  },
  pageHeader: {
    position: 'absolute',
    top: -30,
    right: -20,
    fontSize: 12,
    fontFamily: 'Mulish'
  },
  pageFooter: {
    position: 'absolute',
    bottom: -30,
    right: -20,
    fontSize: 12,
    fontFamily: 'Mulish'
  },
  footerLogo: {
    position: 'absolute',
    bottom: -35,
    left: -20,
    width: 80,
    height: 'auto',
    objectFit: 'contain'
  },
  footerClientLogo: {
    position: 'absolute',
    bottom: -35,
    left: 70,
    width: 80,
    height: 'auto',
    objectFit: 'contain'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
    breakInside: 'avoid'
  },
  logo: {
    width: 120,
    marginBottom: 40
  },
  firstPageLogo: {
    width: 180,
    height: 'auto',
    marginBottom: 20,
    objectFit: 'contain'
  },
  firstPageClientLogo: {
    width: 180,
    height: 'auto',
    marginLeft: 20,
    marginBottom: 20,
    objectFit: 'contain'
  },
  firstPageLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20
  },
  headerText: {
    textAlign: 'right'
  },
  firstPageText: {
    textAlign: 'left'
  },
  projectName: {
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'Mulish'
  },
  firstPageProjectName: {
    fontSize: 32,
    fontWeight: 'bold',
    fontFamily: 'Mulish'
  },
  quoteNumber: {
    fontSize: 14
  },
  firstPageQuoteNumber: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  section: {
    marginBottom: 20,
    breakInside: 'avoid',
    minHeight: 40
  },
  sectionTitleGroup: {
    breakInside: 'avoid',
    marginBottom: 10
  },
  sectionTitle: {
    fontSize: 20,
    fontFamily: 'Mulish',
    marginBottom: 10
  },
  partiesGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
    breakInside: 'avoid'
  },
  partyBox: {
    flex: 1,
    breakInside: 'avoid'
  },
  partyTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Mulish',
    marginBottom: 5
  },
  partyText: {
    fontSize: 14,
    marginBottom: 3,
    orphans: 3,
    widows: 3
  },
  functionItem: {
    marginBottom: 0,
    breakInside: 'avoid'
  },
  functionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Mulish',
    marginBottom: 5,
    breakInside: 'avoid'
  },
  priceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    breakInside: 'avoid'
  },
  priceTitle: {
    fontSize: 14,
    flex: 1,
    maxWidth: '85%'
  },
  priceText: {
    fontSize: 14,
    textAlign: 'right'
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    paddingTop: 5,
    marginBottom: 10,
    borderTopWidth: 1,
    borderTopColor: '#000',
    breakInside: 'avoid'
  },
  totalText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Mulish'
  },
  smallText: {
    fontSize: 14,
    marginTop: 5,
    orphans: 2,
    widows: 2
  },
  bottomImage: {
    width: '100%',
    height: 400,
    marginTop: 'auto',
    objectFit: 'cover',
    objectPosition: 'bottom'
  }
});
