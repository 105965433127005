export const TASKS = [
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "Gathering Data",
    due_date: "2022-01-20",
  },
  {
    title: "Mauris placerat nisl quis nulla placerat dapibus.",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "To Do",
    due_date: "2022-02-20",
  },
  {
    title: "Duis ullamcorper orci vel faucibus egesta",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "In Progress",
    due_date: "2022-03-20",
  },
  {
    title:
      "In at lorem ultricies erat porttitor scelerisque sit amet non libero",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "In Review",
    due_date: "2022-01-20",
  },
  {
    title: "Nunc congue quam quis luctus malesuada",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "Done",
    due_date: "2022-02-20",
  },
  {
    title: "Donec quis lacus et ipsum aliquet molestie.",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "Gathering Data",
    due_date: "2022-03-20",
  },
  {
    title: "Integer a lorem at nunc lacinia bibendum non non magna.",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "ToDo",
    due_date: "2022-01-20",
  },
  {
    title: "Quisque sagittis enim nec mauris tristique sempe",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "InProgress",
    due_date: "2022-02-20",
  },
  {
    title: "Curabitur efficitur urna vel neque interdum cursus",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "InReview",
    due_date: "2022-03-20",
  },
  {
    title: "Nunc ultrices purus et urna feugiat convallis.",
    description:
      "Pressing a task in the list should show the same modal view with the task's current values and allow the user to edit them",
    assignee: "",
    status: "Done",
    due_date: "2022-01-20",
  },
];

export const TASK_STATUS = [
  {
    label: "Gathering Data",
    value: "Gathering Data",
    color: {
      border: "#f4e0e9",
      background: "#f4e0e9",
      text: "#4c2337",
    },
  },
  {
    label: "To Estimate",
    value: "To Estimate",
    color: {
      border: "#fdecc8",
      background: "#fdecc8",
      text: "#402c1b",
    },
  },
  {
    label: "Pending Approval",
    value: "Pending Approval",
    color: {
      border: "#efdfda",
      background: "#efdfda",
      text: "#442a1e",
    },
  },
  {
    label: "To Do",
    value: "To Do",
    color: {
      border: "#d3e5ef",
      background: "#d3e5ef",
      text: "#193347",
    },
  },
  {
    label: "In Progress",
    value: "In Progress",
    color: {
      border: "#fadeca",
      background: "#fadeca",
      text: "#4a280d",
    },
  },
  {
    label: "In Review",
    value: "In Review",
    color: {
      border: "#dbeddb",
      background: "#dbeddb",
      text: "#1d3829",
    },
  },
  {
    label: "In Staging",
    value: "In Staging",
    color: {
      border: "#e8deee",
      background: "#e8deee",
      text: "#412454",
    },
  },
  {
    label: "Closed",
    value: "Closed",
    color: {
      border: "#e3e2e0",
      background: "#e3e2e0",
      text: "#32302b",
    },
  },
];

export const TASK_LABEL_COLOR = {
  red: {
    background: "#fadeca",
    text: "#4a280d",
  },
  blue: {
    background: "#d3e5ef",
    text: "#193347",
  },
  black: {
    background: "#e3e2e0",
    text: "#32302b",
  },
}
