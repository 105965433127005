import axios from "axios";
import { toast } from "react-hot-toast";
import { getIntl } from "../../GlobalIntlProvider";
import QueryString from "qs";

const initialState = {
  projects: [],
  projectOptions: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
};

const projectReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "PROJECTS/GETALL":
      return {
        ...state,
        ...action.payload,
      };
    case "PROJECTS/CREATE":
      return {
        ...state,
        projects: action.payload,
      };
    case "PROJECTS/REMOVE":
      return {
        ...state,
        projects: state.projects.filter(
          (project: any) => project.id !== action.payload.projectID
        ),
      };
    case "PROJECTS/EDIT":
      return {
        ...state,
        projects: state.projects.map((project: any) =>
          project.id === action.payload.project.id
            ? action.payload.project
            : project
        ),
      };
    default:
      return state;
  }
};

type getProjectsType = (args?: {
  archived?: boolean;
  page?: number;
  pageSize?: number;
}) => any;

export const getProjects: getProjectsType = ({
  archived = false,
  page,
  pageSize,
} = {}) => {
  return async (dispatch: any, getState: any) => {
    const {
      project: { pagination: paginationState },
    } = getState();

    const query: any = {
      pagination: {
        page: page || paginationState.page,
        pageSize: pageSize || paginationState.pageSize,
      },
    };

    if (archived) {
      query.archived = true;
    }

    const response = await axios(`/projects?${QueryString.stringify(query)}`);
    const { results, pagination } = response.data;
    const projects = results.map((p) => {
      return {
        ...p,
        trelloBoard: {
          id: p.trello_id,
        },
      };
    });

    dispatch({
      type: "PROJECTS/GETALL",
      payload: {
        projects,
        pagination,
      },
    });
  };
};

export const getProjectOptions = () => {
  return async (dispatch: any) => {
    const response = await axios(`/projects?pagination[pageSize]=100`);
    const projects = response.data.results.map((p: any) => {
      return {
        ...p,
        trelloBoard: {
          id: p.trello_id,
        },
      };
    });

    dispatch({
      type: "PROJECTS/GETALL",
      payload: {
        projectOptions: projects,
      },
    });
  };
};

export const createProject = (projectDetails: any) => {
  return async (dispatch: any) => {
    try {
      if (!projectDetails.data.project.id) {
        delete projectDetails.data.project;
      }

      await axios.post(`/projects`, projectDetails);

      dispatch(getProjects());

      toast.success(
        getIntl().formatMessage({
          id: "toast.project.created",
          defaultMessage: "Project created",
        })
      );
    } catch (err: any) {
      console.error("Could not create project", err.response.data.error);

      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.project.create-error",
            defaultMessage:
              "The project wasn't created. Reason: {creation_error}",
          },
          {
            creation_error: String(err?.response?.data?.error?.message || err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const deleteProject = (project: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/projects/${project}`);

      const withArchived = window.location.href.endsWith("/projects");

      dispatch(getProjects({ archived: withArchived }));

      toast.success(
        getIntl().formatMessage({
          id: "toast.project.deleted",
          defaultMessage: "Release deleted",
        })
      );
    } catch (err) {
      console.error("Could not delete project", err);

      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.project.delete-error",
            defaultMessage:
              "The project wasn't deleted. Reason: {delete_error}",
          },
          {
            delete_error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const updateProject = (projectDetails: any) => {
  return async (dispatch: any) => {
    try {
      await axios.put(`/projects/${projectDetails.data.id}`, {
        data: projectDetails.data,
      });

      const withArchived = window.location.href.endsWith("/projects");

      dispatch(getProjects({ archived: withArchived }));

      toast.success(
        getIntl().formatMessage({
          id: "toast.project.updated",
          defaultMessage: "Project updated",
        })
      );
    } catch (err: any) {
      console.error("Could not update project", err);

      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.project.update-error",
            defaultMessage:
              "The project wasn't updated. Reason: {update_error}",
          },
          {
            update_error: String(err?.response?.data?.error?.message || err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export default projectReducer;
