import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { InvoiceToClient } from "./types";
import { FormattedMessage } from "react-intl";

interface CompaniesAutocompleteProps {
  selectedCompanyId: number;
  value: InvoiceToClient | null;
  handleOnChange: (invoice_to: InvoiceToClient | null) => void;
}

const CompaniesAutocomplete = ({
  selectedCompanyId,
  value,
  handleOnChange,
}: CompaniesAutocompleteProps) => {
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [loadingOptions, setLoadingOptions] = React.useState(false);

  const findCompany = async (searchTerm = "") => {
    const companyRes = await axios.get(
      `/find-company-by-name?searchTerm=${searchTerm}&company_id=${selectedCompanyId}`
    );

    setCompanyOptions(companyRes.data);
    setLoadingOptions(false);
  };

  return (
    <Autocomplete
      loading={loadingOptions}
      options={companyOptions}
      getOptionLabel={(c) => c.name}
      value={value}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onChange={(_, newValue) => {
        const invoice_to = typeof newValue === "string" ? null : newValue;

        handleOnChange(invoice_to);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <FormattedMessage
              id="general.Invoice-To"
              defaultMessage="Invoice To"
            />
          }
        />
      )}
      onInputChange={(e, newInputValue) => {
        if (newInputValue.length > 0) {
          setLoadingOptions(true);
          findCompany(newInputValue);
        }
      }}
    />
  );
};

export default CompaniesAutocomplete;
