import {
  CircularProgress,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { QuoteMenu } from "./task";
import { Invoice, Subscription } from "./types";
import { createInvoiceItem } from "../../redux/reducerSlices/invoice";
import { getSubscriptionsToInvoice } from "../../redux/reducerSlices/subscription";

const SubscriptionRow: React.FC<{
  subscription: Subscription;
  handleOpenInvoiceModal: (subscription: any, invoice?: Invoice) => void;
  draftInvoices: [any];
}> = (props) => {
  const { subscription, handleOpenInvoiceModal, draftInvoices } = props;
  const dispatch = useDispatch();

  const sentInvoices =
    subscription.invoice_items?.filter(
      (ii: any) => ii.invoice?.status !== "draft"
    ) || [];

  const [anchorElInvoice, setAnchorElInvoice] = useState<null | HTMLElement>(
    null
  );
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);

  const openInvoice = Boolean(anchorElInvoice);
  const handleClickInvoice = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElInvoice(event.currentTarget);
  };

  const handleCloseInvoice = () => {
    setAnchorElInvoice(null);
  };

  const handleAddToInvoice = async (invoice: any) => {
    setLoadingInvoice(true);

    for (const subscrItem of subscription?.subscription_items) {
      await dispatch(
        createInvoiceItem({
          data: {
            subscription_item: subscrItem,
            invoice: invoice,
            price: subscrItem.price,
          },
        })
      );
    }

    await dispatch(getSubscriptionsToInvoice());

    setLoadingInvoice(false);
    handleCloseInvoice();
  };

  const handleOpenExtInvoice = (invoice: any) => {
    handleCloseInvoice();
    handleOpenInvoiceModal(subscription, invoice);
  };

  const handleAddNewInvoice = () => {
    handleCloseInvoice();
    handleOpenInvoiceModal(subscription);
  };

  const renderInvoiceButton = () => {
    if (loadingInvoice) {
      return (
        <div
          style={{
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="22px" />
        </div>
      );
    }

    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton onClick={handleClickInvoice}>
          <ReceiptIcon />
        </IconButton>
        <QuoteMenu
          anchorEl={anchorElInvoice}
          open={openInvoice}
          onClose={handleCloseInvoice}
        >
          {draftInvoices.map((dInvoice) => {
            return (
              <MenuItem
                key={dInvoice.id}
                onClick={() => {
                  handleAddToInvoice(dInvoice);
                }}
                disableRipple
              >
                <EditIcon />
                <FormattedMessage
                  id="subscription-row.Add-to-invoice"
                  defaultMessage="Add to invoice #{invoice_number}"
                  values={{
                    invoice_number: dInvoice.number,
                  }}
                />
              </MenuItem>
            );
          })}
          {sentInvoices.map((ii) => {
            return (
              <MenuItem
                key={ii.id}
                onClick={() => {
                  handleOpenExtInvoice(ii.invoice);
                }}
                disableRipple
              >
                <MarkEmailReadIcon />
                <FormattedMessage
                  id="subscription-row.Show-invoice"
                  defaultMessage="Show invoice #{invoice_number}"
                  values={{
                    invoice_number: ii.invoice.number,
                  }}
                />
              </MenuItem>
            );
          })}
          {Boolean(draftInvoices.length) && <Divider sx={{ my: 0.5 }} />}
          <MenuItem onClick={handleAddNewInvoice} disableRipple>
            <AddBoxIcon />
            <FormattedMessage
              id="subscription-row.Add-to-new-invoice"
              defaultMessage="Add to new invoice"
            />
          </MenuItem>
        </QuoteMenu>
      </span>
    );
  };

  const localizeRenewFrequency = () => {
    switch (subscription.renewal_frequency) {
      case "monthly":
        return (
          <FormattedMessage id="general.monthly" defaultMessage="monthly" />
        );
      case "yearly":
        return <FormattedMessage id="general.yearly" defaultMessage="yearly" />;
      default:
        console.error(
          "unexpected subscription frequency: ",
          subscription.renewal_frequency
        );
        return (
          <FormattedMessage
            id="general.unexpected-subscription-frequency"
            defaultMessage="unexpected subscription frequency"
          />
        );
    }
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row">
        {subscription.title}
      </TableCell>
      <TableCell align="left">{subscription.company?.name}</TableCell>
      <TableCell align="left">{subscription.start_date}</TableCell>
      <TableCell align="left">{localizeRenewFrequency()}</TableCell>
      <TableCell align="right">
        {parseFloat(String(subscription.price)).toFixed(2)}
      </TableCell>
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {renderInvoiceButton()}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default SubscriptionRow;
