import axios from "axios";
import qs from "qs";
import { Currency } from "../../components/task/types";
import { getIntl } from "../../GlobalIntlProvider";
import toast from "react-hot-toast";
import { CsvExpenseEntity } from "../../pages/Expenses/ImportExpensesModal";

export type Expense = {
  id: number;
  date: string;
  talent: string | null;
  memo: string;
  hours: number;
  price: number;
  currency: Currency;
  publishedAt: string | null;
  user: any;
  task: any;
  organization: any;
};

export type ExpenseState = {
  expenses: Expense[];
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
  loading: boolean;
};

const initialState: ExpenseState = {
  expenses: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
  loading: false,
};

export enum ExpenseActionType {
  GET_ALL = "EXPENSES/GETALL",
  SET_LOADING = "EXPENSES/LOADING",
  UPDATE_STATUS = "EXPENSES/UPDATE_STATUS",
  DELETE = "EXPENSES/DELETE",
}

type ActionPayload = {
  type: ExpenseActionType;
  payload: Record<string, any>;
};

const expenseReducer = (
  state = initialState,
  action: ActionPayload
): ExpenseState => {
  const returnState = { ...initialState, ...state };
  switch (action.type) {
    case ExpenseActionType.SET_LOADING:
      const { loading } = action.payload;
      returnState.loading = loading;
      break;
    case ExpenseActionType.GET_ALL:
      returnState.expenses = action.payload.expenses.map((e: any) => {
        let user: any;
        let task: any;
        const expense: Expense = {
          id: e.id,
          ...e.attributes,
          user: null,
          task: null,
        };

        if (e.attributes.user.data) {
          user = {
            id: e.attributes.user.data.id,
            ...e.attributes.user.data.attributes,
          };
        }

        if (e.attributes.task.data) {
          task = {
            id: e.attributes.task.data.id,
            ...e.attributes.task.data.attributes,
          };

          if (task.project.data) {
            task.project = {
              id: task.project.data.id,
              ...task.project.data.attributes,
            };
          }
        }

        if (user) {
          expense.user = user;
        }

        if (task) {
          expense.task = task;
        }

        return expense;
      });
      returnState.pagination = action.payload.pagination;

      break;
    case ExpenseActionType.UPDATE_STATUS:
      const {
        payload: { id, publishedAt },
      } = action;
      returnState.expenses = state.expenses.map((e) =>
        e.id === id ? { ...e, publishedAt } : e
      );
      break;
    case ExpenseActionType.DELETE:
      const { payload } = action;
      returnState.expenses = state.expenses.filter((e) => e.id !== payload.id);
      break;
  }
  return returnState;
};

const setLoading = (dispatch: any, loading: boolean) => {
  const actionPayload: ActionPayload = {
    type: ExpenseActionType.SET_LOADING,
    payload: { loading },
  };
  dispatch(actionPayload);
};

const handleError = (e: unknown, id: string, defaultMessage: string) => {
  console.error(e);
  toast.error(
    getIntl().formatMessage(
      {
        id,
        defaultMessage,
      },
      {
        error: String(e),
      }
    ),
    {
      duration: 5000,
    }
  );
};

export const getExpenses =
  (page?: number, pageSize?: number) =>
  async (dispatch: any, getState: any) => {
    const {
      expenses: { pagination: paginationState },
    } = getState();

    const pagination = {
      page: page || paginationState.page,
      pageSize: pageSize || paginationState.pageSize,
    };

    setLoading(dispatch, true);

    const q = qs.stringify(
      {
        populate: {
          user: {
            fields: ["id", "email"],
          },
          task: {
            fields: ["id", "title", "trello_id_short"],
            populate: {
              project: {
                fields: ["id", "name", "code"],
              },
            },
          },
        },
        publicationState: "preview",
        sort: ["date:desc"],
        pagination,
      },
      {
        encodeValuesOnly: true,
      }
    );

    try {
      const res = await axios.get(`/expenses?${q}`);
      const {
        data: {
          data: expenses,
          meta: { pagination },
        },
      } = res;

      const actionPayload: ActionPayload = {
        type: ExpenseActionType.GET_ALL,
        payload: { expenses, pagination },
      };

      dispatch(actionPayload);
    } catch (e) {
      handleError(
        e,
        "toast.expenses.fetch-error",
        "Could not fetch expenses. Reason: {error}"
      );
    } finally {
      setLoading(dispatch, false);
    }
  };

export const toggleExpensePublishedStatus =
  (id: number, previouslyPublished: boolean) => async (dispatch: any) => {
    try {
      const {
        data: { data: updated },
      } = await axios.put(`/expenses/${id}`, {
        data: {
          publishedAt: previouslyPublished ? null : new Date().toISOString(),
        },
      });

      const actionPayload: ActionPayload = {
        type: ExpenseActionType.UPDATE_STATUS,
        payload: { id, publishedAt: updated.attributes.publishedAt },
      };

      dispatch(actionPayload);
    } catch (e) {
      handleError(
        e,
        "toast.expenses.status-change-error",
        "Expense status change failed. Reason: {error}"
      );
    }
  };

export const deleteExpense = (id: number) => async (dispatch: any) => {
  try {
    await axios.delete(`/expenses/${id}`);

    const actionPayload: ActionPayload = {
      type: ExpenseActionType.DELETE,
      payload: { id },
    };

    dispatch(actionPayload);

    toast.success(
      getIntl().formatMessage({
        id: "success.deleted",
        defaultMessage: "Deleted successfully",
      })
    );
  } catch (e) {
    handleError(
      e,
      "toast.expenses.delete-error",
      "Could not delete expense. Reason: {error}"
    );
  }
};

export const createExpense = (data: unknown) => async (dispatch: any) => {
  try {
    await axios.post("/expenses", { data });

    dispatch(getExpenses());

    toast.success(
      getIntl().formatMessage({
        id: "success.created",
        defaultMessage: "Created successfully",
      })
    );
  } catch (e) {
    handleError(
      e,
      "toast.expenses.create-error",
      "Could not create expense. Reason: {error}"
    );
  }
};

export const updateExpense =
  (id: number, data: unknown) => async (dispatch: any) => {
    try {
      await axios.put(`/expenses/${id}`, { data });
      dispatch(getExpenses());

      toast.success(
        getIntl().formatMessage({
          id: "success.changes-saved",
          defaultMessage: "Changes saved",
        })
      );
    } catch (e) {
      handleError(
        e,
        "toast.expenses.update-error",
        "Could not update expense. Reason: {error}"
      );
    }
  };

export const uploadCsvExpenses =
  (expenses: CsvExpenseEntity[]) => async (dispatch: any) => {
    try {
      await axios.post("/expenses/csv-upload", {
        data: expenses.filter((e) => Object.values(e).every((v) => !!v)),
      });
      dispatch(getExpenses());

      toast.success(
        getIntl().formatMessage({
          id: "success.imported",
          defaultMessage: "Imported successfully",
        })
      );
    } catch (e) {
      handleError(
        e,
        "toast.expenses.import-error",
        "Could not import expenses. Reason: {error}"
      );
    }
  };

export default expenseReducer;
