import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PaymentModal from "../components/organization/paymentModal";
import TablePaginationActions from "../components/TablePaginationActions";
import UserModal from "../components/user/modal";
import UserRow from "../components/user/row";
import { getInvites } from "../redux/reducerSlices/invite";
import { refreshMe } from "../redux/reducerSlices/me";
import { getRoles } from "../redux/reducerSlices/role";

const UsersPage = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { user: userMe, inviteState } = useSelector((state: any) => state);
  const { pagination } = inviteState;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalInvite, setModalInvite] = useState<any>(null);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getInvites());
  }, [dispatch]);

  const handleOpenEdinModal = (user: any) => {
    if (
      ["initial", "canceled", "unpaid"].includes(userMe.subscription_status)
    ) {
      setOpenPaymentModal(true);
    } else {
      setModalInvite(user);
      setOpenModal(true);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getInvites(newPage + 1, pagination.pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getInvites(1, parseInt(event.target.value, 10)));
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Users" defaultMessage="Users" />
          </Typography>
          <Button onClick={() => handleOpenEdinModal(null)}>
            <FormattedMessage
              id="general.Invite-User"
              defaultMessage="Invite User"
            />
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Name" defaultMessage="Name" />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage id="general.Email" defaultMessage="Email" />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage id="general.Role" defaultMessage="Role" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inviteState.all?.map((invite: any) => {
                const user = invite.invitee || {};
                return (
                  <UserRow
                    key={invite.id}
                    invite={invite}
                    user={{
                      ...user,
                      handleOpenEdinModal,
                    }}
                  />
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pagination.total}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.page - 1}
                  SelectProps={{
                    inputProps: {
                      "aria-label": intl.formatMessage({
                        id: "general.Users-per-page",
                        defaultMessage: "Users per page",
                      }),
                    },
                    native: true,
                  }}
                  labelRowsPerPage={intl.formatMessage({
                    id: "general.Users-per-page",
                    defaultMessage: "Users per page",
                  })}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} ${intl.formatMessage({
                      id: "pagination.of",
                      defaultMessage: "of",
                    })} ${count}`;
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <UserModal
        openModal={openModal}
        invite={modalInvite}
        setOpenModal={setOpenModal}
      />
      <PaymentModal
        title={intl.formatMessage({
          id: "general.You-must-be-subscribed-to-create-organization",
          defaultMessage: "You must be subscribed to create an organization",
        })}
        openModal={openPaymentModal}
        setOpenModal={setOpenPaymentModal}
        onSubscribeCB={() => {
          dispatch(refreshMe());
          setOpenModal(true);
        }}
      />
    </Container>
  );
};

export default UsersPage;
