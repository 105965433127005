import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { ExpenseState, getExpenses } from "../../redux/reducerSlices/expense";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ExpensesActionCell } from "./ExpensesActionCell";
import { formatTaskTitle } from "../../components/task/functions";
import { useIntl } from "react-intl";
import TablePaginationActions from "../../components/TablePaginationActions";

export const ExpensesTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { expenses, loading, pagination }: ExpenseState = useSelector(
    (s: RootStateOrAny) => s.expenses
  );

  const expensesTableData = React.useMemo(() => {
    const loc = (id: string, defaultMessage?: string) =>
      intl.formatMessage({ id, defaultMessage });

    return expenses?.map((e) => ({
      [loc("general.Date")]: moment(e.date).format("DD MMM YYYY"),
      [loc("general.Memo")]: e.memo,
      [loc("general.Talent")]: e.talent ?? "-",
      [loc("general.Hours")]: e.hours,
      [loc("general.Price")]: `${e.price} ${e.currency}`,
      [loc("general.User")]: e.user?.email ?? "-",
      [loc("general.Task")]: e.task?.title ? formatTaskTitle(e.task) : "-",
      [loc("general.Published")]: e.publishedAt
        ? moment(e.publishedAt).format("DD MMM YYYY HH:mm")
        : "-",
      "": <ExpensesActionCell expense={e} />,
    }));
  }, [expenses, intl]);

  React.useEffect(() => {
    dispatch(getExpenses());
  }, [dispatch]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getExpenses(newPage + 1, pagination.pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getExpenses(1, parseInt(event.target.value, 10)));
  };

  if (loading) return null;

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 100,
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            {Object.keys(expensesTableData?.[0] ?? {}).map((k, idx) => (
              <TableCell key={k + idx}>{k}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(expensesTableData).map((e, rowIdx) => (
            <TableRow key={rowIdx}>
              {Object.values(e).map((item, idx) => (
                <TableCell key={`${item}-${idx}-${rowIdx}`}>{item}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={pagination.total}
              rowsPerPage={pagination.pageSize}
              page={pagination.page - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": intl.formatMessage({
                    id: "general.Expenses-per-page",
                    defaultMessage: "Expenses per page",
                  }),
                },
                native: true,
              }}
              labelRowsPerPage={intl.formatMessage({
                id: "general.Expenses-per-page",
                defaultMessage: "Expenses per page",
              })}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${intl.formatMessage({
                  id: "pagination.of",
                  defaultMessage: "of",
                })} ${count}`;
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
