import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { ExpensesTable } from "./ExpensesTable";
import React from "react";
import { ExpenseModal } from "./ExpenseModal";
import { FormattedMessage } from "react-intl";
import { ImportExpensesModal } from "./ImportExpensesModal";

const ExpensesPage = () => {
  const [openExpenseModal, setOpenExpenseModal] = React.useState(false);
  const [openImportModal, setOpenImportModal] = React.useState(false);

  return (
    <>
      <Container>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4" component="h6">
              <FormattedMessage
                id="general.Expenses"
                defaultMessage="Expenses"
              />
            </Typography>
            <Box display="flex" gap={1}>
              <Button onClick={() => setOpenImportModal(true)}>
                <FormattedMessage
                  id="general.ImportExpenses"
                  defaultMessage="Import Expenses"
                />
              </Button>
              <Button onClick={() => setOpenExpenseModal(true)}>
                <FormattedMessage
                  id="general.NewExpense"
                  defaultMessage="New Expense"
                />
              </Button>
            </Box>
          </Box>
          <ExpensesTable />
        </Box>
      </Container>

      <ImportExpensesModal
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
      <ExpenseModal
        open={openExpenseModal}
        onClose={() => setOpenExpenseModal(false)}
      />
    </>
  );
};

export default ExpensesPage;
