import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';
import { getProjectName } from '../../utils/helpers';

interface ContextSectionProps {
  quote: Quote | null;
}

export const ContextSection: React.FC<ContextSectionProps> = ({ quote }) => {
  const intl = useIntl();

  return (
    <View style={styles.section}>
      <View wrap={false}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.context' })}
        </Text>
        <Text style={styles.partyText}>
          {intl.formatMessage(
            { id: 'quote.pdf.contextText' },
            { projectName: getProjectName(quote) }
          )}
        </Text>
      </View>
    </View>
  );
};
