import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import { Delete, Edit, Check } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import { InvoiceItem, Task } from "../task/types";
import { useDispatch } from "react-redux";
import { internalInvoiceTaskSeenBy } from "../../redux/reducerSlices/invoice";
import { sawTask } from "../../redux/reducerSlices/task";

const InvoiceItemRow: React.FC<{
  ii: InvoiceItem;
  openConfirmItemModal: (ii: InvoiceItem) => void;
  openTaskModal: (task: Task) => void;
  updateItem: (
    ii: InvoiceItem,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  deleteDisabled: boolean;
  forwardRef: any;
  isDragging: boolean;
  dragHandleProps: any;
}> = ({
  ii,
  openConfirmItemModal,
  updateItem,
  deleteDisabled,
  openTaskModal,
  forwardRef,
  isDragging,
  dragHandleProps,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [edititngTitle, setEditingTitle] = useState<boolean>(false);
  const priceFieldRef = useRef<HTMLInputElement>(null);

  const handlePriceWheel = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    const priceElm = priceFieldRef.current;

    priceElm.addEventListener("wheel", handlePriceWheel, { passive: false });

    return () => {
      priceElm.removeEventListener("wheel", handlePriceWheel);
    };
  }, []);

  const handleOpenTaskModal = (task: Task) => {
    dispatch(internalInvoiceTaskSeenBy(task.id));
    dispatch(sawTask(task.id));
    openTaskModal(task);
  };

  const renderTitle = () => {
    if (edititngTitle) {
      return (
        <div className="invoice-item-title item-title">
          <span style={{ overflow: "visible" }}>
            <TextField
              size="small"
              id="title"
              label={
                <FormattedMessage id="general.Title" defaultMessage="Title" />
              }
              type="text"
              name="title"
              variant="outlined"
              fullWidth
              value={ii.title}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setEditingTitle(false);
                }
              }}
              onChange={(event) => updateItem(ii, event)}
              style={{
                width: 338,
                marginRight: 3,
              }}
            />
          </span>
          <div className="invoice-item-edit-title-btn">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setEditingTitle(!edititngTitle);
              }}
            >
              <Check />
            </IconButton>
          </div>
        </div>
      );
    }

    return (
      <div
        className="invoice-item-title item-title"
        onClick={() => {
          if (ii.task?.id) {
            handleOpenTaskModal(ii.task);
          }
        }}
        style={{
          cursor: ii.task?.id ? "pointer" : "default",
        }}
      >
        <span style={{ position: "relative", padding: "5px 5px 5px 0" }}>
          {ii.title}
          {Boolean(ii.task?.id && !ii.task?.seen_by?.length) && (
            <span
              style={{
                position: "absolute",
                width: 7,
                height: 7,
                borderRadius: "50%",
                background: "#DE350B",
                marginTop: -2,
                right: 0,
              }}
            >
              {/* empty */}
            </span>
          )}
        </span>
        <div className="invoice-item-edit-title-btn">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setEditingTitle(!edititngTitle);
            }}
          >
            <Edit />
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <div ref={forwardRef} {...rest}>
      <span
        {...dragHandleProps}
        style={{
          paddingTop: 16,
          paddingRight: 5,
          paddingLeft: 5,
        }}
      >
        <DragHandleIcon />
      </span>
      <div
        className="quote-item-row"
        style={{ flexGrow: 1, maxWidth: "calc(100% - 35px)" }}
      >
        {renderTitle()}
        <div className="invoice-item-actions">
          <TextField
            size="small"
            id="price"
            ref={priceFieldRef}
            label={
              <FormattedMessage id="general.Price" defaultMessage="Price" />
            }
            disabled={ii.is_markup_discount}
            type="number"
            name="price"
            className="quote-item-price"
            variant="outlined"
            value={ii.price}
            onChange={(event) => updateItem(ii, event)}
          />
          <IconButton
            aria-label="delete"
            disabled={deleteDisabled || ii.is_markup_discount}
            onClick={() => {
              openConfirmItemModal(ii);
            }}
            style={{
              padding: 6,
            }}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default InvoiceItemRow;
