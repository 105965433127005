import axios from "axios";
import QueryString from "qs";
import { toast } from "react-hot-toast";

const initialState = {
  tasksToReview: [],
  tasksToQuote: [],
  quotesReadyToSent: [],
  quotesToAddPrice: [],
  projectsToRelease: [],
  projectsReleasePending: [],
  tasksToRelease: [],
  tasksToInvoice: [],
  invoicesToSend: [],
  subscriptionsToInvoice: [],
  tasksToEstimate: [],
  tasksInProgress: [],
  tasksToDo: [],
  subscriptionRenewalNotice: [],
  expiringQuotes: [],
};

const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DASHBOARD":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getDashboardData = (
  cardsToFetch: {
    tasksToReview?: boolean;
    tasksToQuote?: boolean;
    quotesReadyToSent?: boolean;
    quotesToAddPrice?: boolean;
    projectsToRelease?: boolean;
    projectsReleasePending?: boolean;
    tasksToRelease?: boolean;
    tasksToInvoice?: boolean;
    invoicesToSend?: boolean;
    subscriptionsToInvoice?: boolean;
    tasksToEstimate?: boolean;
    tasksInProgress?: boolean;
    tasksToDo?: boolean;
    subscriptionRenewalNotice?: boolean;
    expiringQuotes?: boolean;
  } = {}
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const { user } = getState();
      const query = QueryString.stringify(cardsToFetch);
      let endpoint = "/cs/get-dashboard-data";

      if (user.selected_organization_role !== "Admin") {
        endpoint = "/cs/get-dashboard-data-dev";
      }

      const res = await axios.get(`${endpoint}?${query}`);

      dispatch({
        type: "SET_DASHBOARD",
        payload: {
          ...res.data,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not get tasks to review. Reason: ${String(err)}`);
    }
  };
};

export default dashboardReducer;
