import { toast } from "react-hot-toast";
import { getOwnedOrganizations } from "./owned-organization";
import { connectTrelloUser } from "./user";

declare global {
  interface Window {
    Trello: any;
  }
}

const initialState = {
  loading: false,
  boards: [],
};

const trelloReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "TRELLO/LOADING/TRUE":
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case "TRELLO/LOADING/FALSE":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "TRELLO/SET/BOARDS":
      return {
        ...initialState,
        ...state,
        boards: action.payload.boards,
      };
    case "TRELLO/SET/STATUS/CONNECTED":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "TRELLO/SET/STATUS/DISCONNECTED":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const getTrelloBoards = () => {
  return async (dispatch: any, getState: any) => {
    const trelloBoardsRes = await window.Trello.get("members/me/boards");

    dispatch({
      type: "TRELLO/SET/BOARDS",
      payload: {
        boards: trelloBoardsRes,
      },
    });
  };
};

export const disconnectTrello = () => {
  return async (dispatch: any) => {
    try {
      dispatch(connectTrelloUser({}));
      window.Trello.deauthorize();
      dispatch({ type: "TRELLO/SET/STATUS/DISCONNECTED" });
    } catch (err) {
      console.error(err);
      toast.error(`Could not disconnect Trello. Reason: ${String(err)}`);
    }
  };
};

export const connectTrello = () => {
  return async (dispatch: any) => {
    try {
      window.Trello.authorize({
        type: "popup",
        name: "Gotologic",
        scope: {
          read: "true",
          write: "true",
          account: "true",
        },
        expiration: "never",
        success: async () => {
          const trelloToken = window.Trello.token();

          if (trelloToken === "Token request rejected") {
            dispatch(disconnectTrello());
            return null;
          }

          const trelloUser = await window.Trello.get("members/me");

          dispatch(
            connectTrelloUser({
              trelloToken,
              trelloId: trelloUser.id,
              trelloMemberId: trelloUser.id,
            })
          );
          dispatch({
            type: "TRELLO/SET/STATUS/CONNECTED",
          });
          dispatch(getOwnedOrganizations());
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not connect to trello. Reason: ${String(err)}`);
    }
  };
};

export default trelloReducer;
