import { Dialog, DialogTitle } from "@mui/material";
import { FC } from "react";
import { ExpenseForm } from "./ExpenseForm";
import { FormattedMessage } from "react-intl";

type Props = {
  open: boolean;
  onClose: () => void;
  expense?: any;
};

export const ExpenseModal: FC<Props> = ({ onClose, open, expense }) => {
  const isEdit = !!expense;
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        {isEdit ? (
          <FormattedMessage
            id="general.EditExpense"
            defaultMessage="Edit Expense"
          />
        ) : (
          <FormattedMessage
            id="general.NewExpense"
            defaultMessage="New Expense"
          />
        )}
      </DialogTitle>
      <ExpenseForm submitEffect={onClose} expense={expense} />
    </Dialog>
  );
};
