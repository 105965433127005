import React, { useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";

import ConfirmDialog from "../ConfirmDialog";
import { User } from "./types";
import { updateInvite } from "../../redux/reducerSlices/invite";
import { createInvite, deleteInvite } from "../../redux/reducerSlices/invite";
import axios from "axios";

const emptyUser = {
  id: null,
  email: "",
  firstName: "",
  lastName: "",
  language: "en",
} as User;

const UserModal: React.FC<{
  extUser?: User | null;
  setOpenModal: any;
  openModal: boolean;
  onCreateCB?: () => void;
  invite: any;
}> = ({ setOpenModal, openModal, onCreateCB, invite }) => {
  const { user: authUser } = useSelector((state: any) => state);
  const isEditingUser = Boolean(invite?.id);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [user, setUser] = useState<User>(emptyUser);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uptInvite, setUptInvite] = useState<any>(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({});
  const [trelloMembers, setTrelloMembers] = useState<any>([]);
  const [lastMembersRefresh, setLastMembersRefresh] = useState<number>(null);
  const [selectedMember, setSelectedMember] = useState<any>(null);

  const getTrelloMembers = useCallback(
    async (forceRefresh?: boolean) => {
      let url = "/cs/get-trello-members";
      const timeFromLastRefresh = Date.now() - lastMembersRefresh;
      const oneMinute = 1000 * 60;

      if (forceRefresh && timeFromLastRefresh > oneMinute) {
        url += "?forceRefresh=true";
      }

      const res = await axios.get(url);

      if (uptInvite?.invitee?.trello_member_id) {
        const selectedMember = res.data.members.find(
          (member: any) => member.id === uptInvite.invitee.trello_member_id
        );

        setSelectedMember(selectedMember);
      }

      setTrelloMembers(res.data.members);
      setLastMembersRefresh(res.data.lastRefresh);
    },
    [lastMembersRefresh, uptInvite?.invitee?.trello_member_id]
  );

  useEffect(() => {
    getTrelloMembers();
  }, [getTrelloMembers]);

  const getSubscriptionPrice = async () => {
    const res = await axios.get("/cs/get-subscription-price");

    setSubscriptionDetails(res.data);
  };

  useEffect(() => {
    getSubscriptionPrice();
  }, [authUser.subscription_status]);

  useEffect(() => {
    if (invite) {
      setUptInvite({
        ...invite,
      });
    }
  }, [invite]);

  useEffect(() => {
    if (!openModal) return null;

    if (invite?.invitee) {
      setUser({
        ...invite.invitee,
      });
    }
  }, [invite?.invitee, setUser, openModal]);

  const closeModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setUser(emptyUser);
      setSelectedMember(null);
    }, 200);
  };

  const handleDelete = (user: User) => {
    dispatch(deleteInvite(user.id));
    setOpenConfirmModal(false);
    setOpenModal(false);
    setTimeout(() => {
      setUser(emptyUser);
    }, 200);
  };

  const validateUser = () => {
    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateUser()) {
      return null;
    }

    setLoading(true);

    if (isEditingUser) {
      await dispatch(
        updateInvite({
          ...uptInvite,
        })
      );
    } else {
      const newUser = {
        ...user,
      };

      delete newUser.id;

      await dispatch(
        createInvite({
          email: newUser.email,
        })
      );

      if (onCreateCB) {
        onCreateCB();
      }
    }

    setLoading(false);
    closeModal();
  };

  const getModalTitle = () => {
    return isEditingUser ? (
      <FormattedMessage id="user-modal.Edit-User" defaultMessage="Edit User" />
    ) : (
      <FormattedMessage id="general.Invite-User" defaultMessage="Invite User" />
    );
  };

  const invalidEmail = () => {
    return (
      user.email.length > 0 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(user.email)
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={1}
              >
                {isEditingUser && (
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      id="firstName"
                      name="firstName"
                      value={user.firstName || ""}
                      label={intl.formatMessage({
                        id: "general.First-Name",
                        defaultMessage: "First Name",
                      })}
                      type="text"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                )}
                {isEditingUser && (
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      id="lastName"
                      name="lastName"
                      value={user.lastName || ""}
                      label={intl.formatMessage({
                        id: "general.Last-Name",
                        defaultMessage: "Last Name",
                      })}
                      type="text"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  {!isEditingUser && Boolean(subscriptionDetails.amount) && (
                    <FormattedMessage
                      id="general.You-will-be-charged-price-per-month-per-user"
                      defaultMessage="You will be charged {price} per month per user. You can cancel at any time."
                      values={{
                        price:
                          "$" + (subscriptionDetails.amount / 100).toFixed(2),
                      }}
                    />
                  )}
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    value={user.email || invite?.external_email || ""}
                    label={intl.formatMessage({
                      id: "general.Email",
                      defaultMessage: "Email",
                    })}
                    type="email"
                    variant="outlined"
                    disabled={isEditingUser}
                    onChange={(event) => {
                      setUser({
                        ...user,
                        email: event.target.value,
                      });
                    }}
                    error={invalidEmail()}
                    helperText={
                      invalidEmail() && (
                        <FormattedMessage
                          id="general.Invalid-email-address"
                          defaultMessage="Invalid email address"
                        />
                      )
                    }
                  />
                </Grid>
                {isEditingUser && (
                  <Grid item sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">
                        <FormattedMessage
                          id="general.Role"
                          defaultMessage="Role"
                        />
                      </InputLabel>
                      <Select
                        fullWidth
                        id="role"
                        name="role"
                        value={uptInvite?.role || "Developer"}
                        label={intl.formatMessage({
                          id: "general.Role",
                          defaultMessage: "Role",
                        })}
                        type="text"
                        variant="outlined"
                        onChange={(event) => {
                          setUptInvite({
                            ...uptInvite,
                            role: event.target.value,
                          });
                        }}
                      >
                        <MenuItem value="Developer">Developer</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {isEditingUser && (
                  <Grid item sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="language-label">
                        <FormattedMessage
                          id="general.Language"
                          defaultMessage="Language"
                        />
                      </InputLabel>
                      <Select
                        fullWidth
                        id="language"
                        name="language"
                        value={user.language || ""}
                        label={intl.formatMessage({
                          id: "general.Language",
                          defaultMessage: "Language",
                        })}
                        type="text"
                        variant="outlined"
                        disabled
                      >
                        <MenuItem value="en">
                          <FormattedMessage
                            id="general.English"
                            defaultMessage="English"
                          />
                        </MenuItem>
                        <MenuItem value="fr">
                          <FormattedMessage
                            id="general.French"
                            defaultMessage="French"
                          />
                        </MenuItem>
                        <MenuItem value="sv">
                          <FormattedMessage
                            id="general.Swedish"
                            defaultMessage="Swedish"
                          />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {isEditingUser && (
                  <Grid item sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        id="trello"
                        options={trelloMembers}
                        getOptionLabel={(option: any) =>
                          option.email ||
                          option.fullName ||
                          option.username ||
                          option
                        }
                        value={selectedMember || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={intl.formatMessage({
                              id: "general.Trello-User",
                              defaultMessage: "Trello User",
                            })}
                            variant="outlined"
                          />
                        )}
                        isOptionEqualToValue={(option: any, value: any) => {
                          return option?.id === value;
                        }}
                        onChange={(event, newValue) => {
                          setUptInvite({
                            ...uptInvite,
                            invitee: {
                              ...uptInvite.invitee,
                              trello_member_id: newValue?.id || null,
                            },
                          });

                          setSelectedMember(newValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditingUser && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    {invite.status === "accepted" ? (
                      <FormattedMessage
                        id="general.Remove-User"
                        defaultMessage="Remove User"
                      />
                    ) : (
                      <FormattedMessage
                        id="general.Delete-Invite"
                        defaultMessage="Delete Invite"
                      />
                    )}
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit" disabled={invalidEmail()}>
                  {isEditingUser ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Invite"
                      defaultMessage="Invite"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditingUser) {
            handleDelete(invite);
          }
        }}
        text={intl.formatMessage({
          id: "user-modal.Do-you-really-want-to-delete-user",
          defaultMessage: "Do you really want to delete the user?",
        })}
      />
    </div>
  );
};

export default UserModal;
