import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { PurchaseOrder } from "./types";
import { truncate } from "../task/functions";

const Row: React.FC<{
  purchaseOrder: PurchaseOrder;
  handleOpenEdinModal: (purchaseOrder: any) => void;
}> = ({ purchaseOrder, handleOpenEdinModal }, props) => {
  const handleEditPO = () => {
    handleOpenEdinModal(purchaseOrder);
  };
  let resourceURL = "";
  const firstAttachemntSrc = purchaseOrder.attachments[0]?.url || "";

  if (
    firstAttachemntSrc.startsWith("http") ||
    firstAttachemntSrc.startsWith("blob")
  ) {
    resourceURL = firstAttachemntSrc;
  } else {
    resourceURL = `${process.env.REACT_APP_HOST_URL}${firstAttachemntSrc}`;
  }

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditPO();
      }}
    >
      <TableCell component="th" scope="row">
        {purchaseOrder.company?.name}
      </TableCell>
      <TableCell align="left">{purchaseOrder.number}</TableCell>
      <TableCell align="left">{truncate(purchaseOrder.summary, 70)}</TableCell>
      <TableCell align="left">
        {parseFloat(String(purchaseOrder.amount)).toFixed(2)}
      </TableCell>
      <TableCell align="left">
        {Boolean(firstAttachemntSrc) && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              event.nativeEvent.stopImmediatePropagation();
              event.nativeEvent.stopPropagation();
              window.open(resourceURL, "_blank");
            }}
          >
            <AttachmentIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;
