import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import {
  createEstimate,
  deleteEstimate,
  updateEstimate,
} from "../../redux/reducerSlices/task";
import ConfirmDialog from "../ConfirmDialog";

const emptyEstimate = {
  hours: "",
  comments: "",
};

const EstimateModal: React.FC<{
  setOpenModal: any;
  openModal: boolean;
  updateCallback?: (estimate: any) => void;
  deleteCallback?: (estimate: any) => void;
  task: any;
}> = ({ setOpenModal, openModal, updateCallback, deleteCallback, task }) => {
  const { user } = useSelector((state: RootStateOrAny) => state);
  let isEdit: any = false;

  if (Array.isArray(task?.estimates)) {
    isEdit = task.estimates.find((e) => e.user.id === user.id);
  }

  const dispatch = useDispatch();
  const intl = useIntl();

  const [estimate, setEstimate] = useState(emptyEstimate);
  const [extEstimate, setExtEstimate] = useState({ id: 0 });
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setEstimate(isEdit);
      setExtEstimate(isEdit);
    } else {
      setEstimate(emptyEstimate);
    }
  }, [task?.estimates, isEdit]);

  const handleDelete = (estimateId: any) => {
    dispatch(deleteEstimate(estimateId));
    setOpenConfirmModal(false);
    setOpenModal(false);

    if (deleteCallback) {
      deleteCallback(estimate);
    }
  };

  const handleOnChangeFormField = (event, name = null) => {
    if (name === null) {
      setEstimate({ ...estimate, [event.target.name]: event.target.value });
    } else {
      setEstimate({ ...estimate, [name]: event.toString() });
    }
  };

  const validateTask = () => {
    if (!estimate.hours) {
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateTask()) {
      setShowFormErrors(true);
      return null;
    }

    setEstimate({ ...estimate });

    const estimateToSave = {
      data: {
        ...estimate,
        task: {
          id: task.id,
          status: task.status,
          pos: task.pos,
        },
      },
    };

    if (isEdit) {
      dispatch(updateEstimate(estimateToSave));

      if (updateCallback) {
        updateCallback(estimate);
      }
    } else {
      dispatch(createEstimate(estimateToSave));
      setEstimate(emptyEstimate);
    }

    setShowFormErrors(false);
    setOpenModal(false);
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          setShowFormErrors(false);
          setOpenModal(false);
        }}
      >
        <DialogTitle>
          {isEdit ? (
            <FormattedMessage
              id="estimate-modal.Edit-Estimate"
              defaultMessage="Edit Estimate"
            />
          ) : (
            <FormattedMessage
              id="estimate-modal.New-Estimate"
              defaultMessage="New Estimate"
            />
          )}
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              value={estimate.hours}
              margin="dense"
              id="hours"
              label={intl.formatMessage({
                id: "general.Hours",
                defaultMessage: "Hours",
              })}
              type="number"
              name="hours"
              fullWidth
              required
              variant="outlined"
              error={showFormErrors && estimate.hours === ""}
              helperText={
                showFormErrors && estimate.hours === ""
                  ? intl.formatMessage({
                      id: "general.Hours-are-required",
                      defaultMessage: "Hours are required",
                    })
                  : ""
              }
              onChange={(event) => handleOnChangeFormField(event)}
            />
            <TextField
              multiline
              rows={4}
              style={{ width: "100%" }}
              margin="dense"
              id="comments"
              name="comments"
              value={estimate.comments}
              label={intl.formatMessage({
                id: "general.Description",
                defaultMessage: "Description",
              })}
              type="text"
              fullWidth
              variant="outlined"
              onChange={(event) => handleOnChangeFormField(event)}
            />
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 24px 20px",
            }}
          >
            <div>
              {isEdit && (
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenConfirmModal(true);
                  }}
                  color="error"
                >
                  <FormattedMessage
                    id="estimate-modal.Delete-Estimate"
                    defaultMessage="Delete Estimate"
                  />
                </Button>
              )}
            </div>
            <div>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  setEstimate(emptyEstimate);
                  setOpenModal(false);
                }}
              >
                <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
              </Button>
              <Button type="submit">
                {isEdit ? (
                  <FormattedMessage
                    id="general.Update"
                    defaultMessage="Update"
                  />
                ) : (
                  <FormattedMessage
                    id="general.Create"
                    defaultMessage="Create"
                  />
                )}
              </Button>
            </div>
          </DialogActions>
        </Box>
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleDelete(extEstimate.id);
        }}
        text={intl.formatMessage({
          id: "estimate-modal.Do-you-really-want-to-delete-estimate",
          defaultMessage: "Do you really want to delete this estimate?",
        })}
      />
    </div>
  );
};

export default EstimateModal;
