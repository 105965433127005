import { TableCell, TableRow } from "@mui/material";

const CompanyRow: React.FC<{
  company: {
    handleOpenEdinModal: any;
    id?: number;
    email?: String;
    language?: String;
    name?: String;
    country?: String;
  };
}> = ({ company }, props) => {
  const { name, email, country, handleOpenEdinModal } = company;

  const handleEditCompany = () => {
    handleOpenEdinModal(company);
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditCompany();
      }}
    >
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="left">{country}</TableCell>
    </TableRow>
  );
};

export default CompanyRow;
