import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FormattedMessage, useIntl } from "react-intl";

import ConfirmDialog from "../../../components/ConfirmDialog";
import { toast } from "react-hot-toast";
import PaymentModal from "../../../components/organization/paymentModal";
import { useDispatch, useSelector } from "react-redux";
import { refreshMe } from "../../../redux/reducerSlices/me";

const PlanRow: React.FC<{
  plan: any;
  cancelPlan: any;
  deletePlan: any;
  handleResumePlan: any;
  loadingResume: boolean;
}> = ({ plan, cancelPlan, deletePlan, handleResumePlan, loadingResume }) => {
  const intl = useIntl();

  const nextDate = moment(plan.current_period_end * 1000).format("LL");
  const price = plan.items.data[0].price.unit_amount / 100;
  const quantity = plan.items.data[0].quantity;
  const subCanceled = plan.cancel_at_period_end;

  const status = subCanceled ? (
    <Box>
      <Tooltip
        title={intl.formatMessage(
          {
            id: "general.The-plan-was-canceled",
            defaultMessage:
              "The plan was canceled. Access to the service will be available until the end of the current billing period {end_date}. You can reactivate this plan at any time.",
          },
          {
            end_date: nextDate,
          }
        )}
      >
        <Box display="flex">
          <FormattedMessage id="general.Canceled" defaultMessage="Canceled" />
          <HelpOutlineIcon fontSize="small" />
        </Box>
      </Tooltip>
    </Box>
  ) : (
    intl.formatMessage({
      id: "general.Active",
      defaultMessage: "Active",
    })
  );

  const renderPlanActions = () => {
    if (subCanceled) {
      if (loadingResume) {
        return <CircularProgress color="primary" size={25} />;
      }

      return (
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={() => {
              deletePlan(plan.id);
            }}
            color="error"
            style={{
              marginRight: 10,
            }}
          >
            <FormattedMessage id="general.Delete" defaultMessage="Delete" />
          </Button>
          <Button
            onClick={() => {
              handleResumePlan(plan.id);
            }}
            color="success"
            style={{
              marginRight: 10,
            }}
          >
            <FormattedMessage id="general.Resume" defaultMessage="Resume" />
          </Button>
        </Box>
      );
    }

    return (
      <Button
        onClick={() => {
          cancelPlan(plan.id);
        }}
        color="error"
        style={{
          marginRight: 10,
        }}
      >
        <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
      </Button>
    );
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: 2 }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Name" defaultMessage="Name" />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="general.Default-plan-name"
                defaultMessage="Default plan name"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Status" defaultMessage="Status" />
            </TableCell>
            <TableCell>{status}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Users" defaultMessage="Users" />
            </TableCell>
            <TableCell>{quantity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Price" defaultMessage="Price" />
            </TableCell>
            <TableCell>${price}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="general.Total" defaultMessage="Total" />
            </TableCell>
            <TableCell>${price * quantity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="general.Next-Payment"
                defaultMessage="Next Payment"
              />
            </TableCell>
            <TableCell>{subCanceled ? "--" : nextDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              component="th"
              scope="row"
              style={{ textAlign: "right" }}
            >
              {renderPlanActions()}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </TableContainer>
  );
};

const PlansTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state);

  const [stripePlans, setStripePlans] = React.useState<any>([]);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [planId, setPlanId] = React.useState<string>("");
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] =
    React.useState(false);
  const [deletePlanId, setDeletePlanId] = React.useState<string>("");
  const [pageLoading, setPageLoading] = React.useState(true);
  const [loadingCancel, setLoadingCancel] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [loadingResume, setLoadingResume] = React.useState(false);
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);

  const getStripePlans = async () => {
    const res = await axios("/cs/get-stripe-subscriptions");
    const subs = res.data.data || [];

    setStripePlans(subs);
    setPageLoading(false);

    return subs;
  };

  React.useEffect(() => {
    getStripePlans();
  }, []);

  const handleCancelPlan = async () => {
    try {
      setLoadingCancel(true);

      await axios.post("/cs/cancel-stripe-subscription", {
        subscription_id: planId,
      });

      await getStripePlans();

      toast.success(
        intl.formatMessage({
          id: "general.Plan-canceled",
          defaultMessage: "Plan canceled",
        })
      );
    } catch (e: any) {
      console.error(e);

      toast.error(e.response.data.message);
    } finally {
      setPlanId("");
      setOpenConfirmModal(false);
      setLoadingCancel(false);
    }
  };

  const handleResumePlan = async (resumePlanId: string) => {
    try {
      setLoadingResume(true);

      await axios.post("/cs/resume-stripe-subscription", {
        subscription_id: resumePlanId,
      });

      await getStripePlans();

      toast.success(
        intl.formatMessage({
          id: "general.Plan-resumed",
          defaultMessage: "Plan resumed",
        })
      );
    } catch (e: any) {
      console.error(e);

      toast.error(e.response.data.message);
    } finally {
      setPlanId("");
      setOpenConfirmModal(false);
      setLoadingResume(false);
    }
  };

  const handleDeletePlan = async () => {
    try {
      setLoadingDelete(true);

      await axios.post("/cs/delete-stripe-subscription", {
        subscription_id: deletePlanId,
      });

      const subs = await getStripePlans();

      if (subs.length === 0) {
        dispatch(refreshMe());
      }

      toast.success(
        intl.formatMessage({
          id: "general.Plan-deleted",
          defaultMessage: "Plan deleted",
        })
      );
    } catch (e: any) {
      console.error(e);

      toast.error(e.response.data.message);
    } finally {
      setDeletePlanId("");
      setOpenConfirmDeleteModal(false);
      setLoadingDelete(false);
    }
  };

  if (pageLoading) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 650,
          height: 140,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box style={{ marginTop: 0 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          <FormattedMessage id="general.Plans" defaultMessage="Plans" />
        </Typography>
        {Boolean(user.subscription_status !== "active") && (
          <Button onClick={() => setOpenPaymentModal(true)}>
            <FormattedMessage id="general.New-Plan" defaultMessage="New Plan" />
          </Button>
        )}
      </Box>
      {Boolean(stripePlans.length) ? (
        stripePlans.map((sub: any) => {
          return (
            <PlanRow
              key={sub.id}
              plan={{
                ...sub,
              }}
              cancelPlan={(planId: string) => {
                setPlanId(planId);
                setOpenConfirmModal(true);
              }}
              deletePlan={(planId: string) => {
                setDeletePlanId(planId);
                setOpenConfirmDeleteModal(true);
              }}
              loadingResume={loadingResume}
              handleResumePlan={(planId: string) => {
                handleResumePlan(planId);
              }}
            />
          );
        })
      ) : (
        <div style={{ padding: "5px 10px" }}>
          <FormattedMessage
            id="general.Currently-you-havent-subscribed-to-a-plan"
            defaultMessage="Currently you haven't subscribed to a plan"
          />
        </div>
      )}

      <ConfirmDialog
        loading={loadingCancel}
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleCancelPlan();
        }}
        text={intl.formatMessage({
          id: "general.Are-you-sure-you-want-to-cancel-this-plan",
          defaultMessage: "Are you sure you want to cancel this plan?",
        })}
      />
      <ConfirmDialog
        loading={loadingDelete}
        openModal={openConfirmDeleteModal}
        setOpenModal={setOpenConfirmDeleteModal}
        confirmHandler={() => {
          handleDeletePlan();
        }}
        text={intl.formatMessage({
          id: "general.Are-you-sure-you-want-to-delete-this-plan",
          defaultMessage: "Are you sure you want to delete this plan?",
        })}
      />
      <PaymentModal
        title={intl.formatMessage({
          id: "general.Subscribe",
          defaultMessage: "Subscribe",
        })}
        openModal={openPaymentModal}
        setOpenModal={setOpenPaymentModal}
        onSubscribeCB={() => {
          dispatch(refreshMe(getStripePlans));
        }}
      />
    </Box>
  );
};

export default PlansTab;
